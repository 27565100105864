import { createSlice, current } from "@reduxjs/toolkit";
import { fetchAllCoursesNameThunk, fetchAllCoursesThunk, fetchCourseDetailsForImportThunk, fetchCourseDetailsThunk } from "../actions/courseAction";
import { checkStatusThunk } from "../actions/videoAction";
import { getTopicNodeIdsFromCourseData } from "../../helpers/node";

const initialState = {
	allCourses: [],
	courseData: null,
	courseImportData: null,
	loading: false,
	error: {},
	courseStatus: null,
	statusLoader: false,
	statusError: null,
	topicNodeIds: [],
	allCoursesNames: [],
	allCourseNameLoader: false,
	filters: {},
};

const courseDetailsSlice = createSlice({
	name: "courseDetails",
	initialState,
	reducers: {
		clearAll: (state) => {
			state.allCourses = [];
			state.courseData = null;
			state.courseImportData = null;
			state.loading = false;
			state.error= {};
			state.courseStatus= null;
			state.statusLoader= false;
			state.statusError= null;
			state.topicNodeIds= [];
			state.allCoursesNames= [];
			state.allCourseNameLoader= false;
		},
		addCourseData: (state, action) => {
			state.courseData = action.payload;
		},
		addModuleData: (state, action) => {
			let currentCourseData = current(state?.courseData);
			const { moduleData } = action.payload;
			if (currentCourseData?.data?.children?.length === 0) {
				state.courseData = {
					...currentCourseData,
					data: {
						...currentCourseData.data,
						children: [moduleData.data],
					},
				};
				return;
			}
			const updatedChildren = [...currentCourseData.data.children, moduleData.data];

			state.courseData = {
				...currentCourseData,
				data: {
					...currentCourseData.data,
					children: updatedChildren,
				},
			};
		},

		addTopicNode: (state, action) => {
			let currentCourseData = current(state?.courseData);
			const { topicData, moduleNodeId } = action.payload;

			const updatedChildren = currentCourseData.data.children.map((child) => {
				if (child.node_id === moduleNodeId) {
					return {
						...child,
						children: [...(child.children || []), topicData.data],
					};
				}
				return child;
			});

			state.courseData = {
				...currentCourseData,
				data: {
					...currentCourseData.data,
					children: updatedChildren,
				},
			};
		},

		updateCourseData: (state, action) => {
			// Get the current course data
			let currentCourseData = current(state?.courseData);

			// Spread the existing course data
			state.courseData = {
				...currentCourseData,
				data: {
					// Spread existing data
					...currentCourseData.data,
					// Conditionally update name if present
					...(action.payload.name && { name: action.payload.name }),
					// Conditionally update description if present
					...(action.payload.description && { description: action.payload.description }),
				},
			};
		},
		updateModuleNodeData: (state, action) => {
			let currentCourseData = current(state?.courseData);

			const updatedChildren = currentCourseData.data.children.map((child) => {
				if (child.node_id === action.payload.node_id) {
					return {
						...child,
						node_name: action.payload.node_name,
						// Add any other top-level properties you want to update
						// Keep the existing children
						children: child.children,
					};
				}
				return child;
			});

			state.courseData = {
				...currentCourseData,
				data: {
					...currentCourseData.data,
					children: updatedChildren,
				},
			};
		},

		updateNodeData: (state, action) => {
			let currentCourseData = current(state?.courseData);

			const updatedChildren = currentCourseData.data.children.map((child) => {
				if (child.node_id === action.payload.node_id) {
					return action.payload;
				}
				return child;
			});

			state.courseData = {
				...currentCourseData,
				data: {
					...currentCourseData.data,
					children: updatedChildren,
				},
			};
		},
		setFilters(state, action) {
			state.filters = { ...state.filters, ...action.payload };
		},
		clearFilters(state) {
			state.filters = {};
		},
	},
	extraReducers: (builder) => {
		// Fetch all courses thunk
		builder
			.addCase(fetchAllCoursesThunk.pending, (state) => {
				state.loading = true;
				state.courseData = null;
				state.error["fetchAllCourses"] = null;
			})
			.addCase(fetchAllCoursesThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.allCourses = action.payload;
				state.error["fetchAllCourses"] = null;
			})
			.addCase(fetchAllCoursesThunk.rejected, (state, action) => {
				state.loading = false;
				state.courseData = null;
				state.error["fetchAllCourses"] = action.error.message;
			});

		builder
			.addCase(checkStatusThunk.pending, (state) => {
				state.statusLoader = true;
				state.statusError = null;
			})
			.addCase(checkStatusThunk.fulfilled, (state, action) => {
				state.statusLoader = false;
				state.courseStatus = action.payload;
				state.statusError = null;
			})
			.addCase(checkStatusThunk.rejected, (state, action) => {
				state.statusLoader = false;
				state.statusError = action.error.message;
				state.courseStatus = null;
			});

		builder
			.addCase(fetchCourseDetailsForImportThunk.pending, (state) => {
				state.loading = true;
				state.courseImportData = null;
				state.error["fetchCourseDetailsForImport"] = null;
			})
			.addCase(fetchCourseDetailsForImportThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.courseImportData = action.payload;
				state.error["fetchCourseDetailsForImport"] = null;
			})
			.addCase(fetchCourseDetailsForImportThunk.rejected, (state, action) => {
				state.loading = false;
				state.error["fetchCourseDetailsForImport"] = action.error.message;
				state.courseImportData = null;
			});
		// Fetch course details thunk
		builder
			.addCase(fetchCourseDetailsThunk.pending, (state) => {
				state.loading = true;
				state.courseData = null;
				state.error["fetchCourseDetails"] = null;
			})
			.addCase(fetchCourseDetailsThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.courseData = action.payload;
				state.error["fetchCourseDetails"] = null;

				const nodeIds = getTopicNodeIdsFromCourseData(action.payload?.data?.children || []);
				state.topicNodeIds = nodeIds;
			})
			.addCase(fetchCourseDetailsThunk.rejected, (state, action) => {
				state.loading = false;
				state.error["fetchCourseDetails"] = action.error.message;
				state.courseData = null;
			});

		builder
			.addCase(fetchAllCoursesNameThunk.pending, (state) => {
				state.allCourseNameLoader = true;
				state.error["fetchAllCoursesNames"] = null;
			})
			.addCase(fetchAllCoursesNameThunk.fulfilled, (state, action) => {
				state.allCourseNameLoader = false;
				state.allCoursesNames = action.payload;
				state.error["fetchAllCoursesNames"] = null;
			})
			.addCase(fetchAllCoursesNameThunk.rejected, (state, action) => {
				state.allCourseNameLoader = false;
				state.error["fetchAllCoursesNames"] = action.error.message;
			});
	},
});

export const { addCourseData, addModuleData, updateNodeData, addTopicNode, updateModuleNodeData, updateCourseData, setFilters,
	clearFilters,clearAll } = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
