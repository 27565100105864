import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import CoursesList from "./Components/CoursesList/CoursesList";
import Home from "./Components/Home/Home";
import Course from "./Components/Course/Course";
import Topic from "./Components/Topic/Topic";
import AddCourse from "./Components/AddCourse/AddCourse";
import Batches from "./Components/batch/batch-list/batch-list";
import AddNewBatch from "./Components/batch/add-new-batch/add-new-batch";
import BatchInfo from "./Components/batch/batch-info/batch-info";
import AddStudent from "./Components/AddStudent/AddStudent";
import TopicCreator from "./Components/Topic/TopicCreator";
import Login from "./Components/login/login";
import ViewBatch from "./Components/batch/batch-info/batch-info-components/view-batch";
import AddTopic from "./Components/Topic/add-topic/add-topic";
import Submissions from "./Components/submissions/submissions";

const AllRoutes = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={<Navigate to='/course' />}
			/>
			<Route
				path='/course'
				element={<CoursesList />}
			/>
			{/* <Route
				path='/course/addcourse/:step?'
				element={<AddCourse />}
			/> */}
			{/* <Route
				path='/course/addcourse/addTopic/:isFromAI?'
				element={<TopicCreator />}
			/> */}
			<Route
				path='/course/:courseId/addTopic'
				element={<AddTopic />}
			/>
			{/* <Route
				path='/course/addstudent'
				element={<AddStudent />}
			/> */}
			<Route
				path='/course/:courseId'
				element={<Course />}
			/>
			<Route
				path='/course/:courseId/topic/:topicId'
				element={<Topic />}
			/>

			<Route
				path='/batches'
				element={<Batches />}
			/>
			<Route
				path='/batches/addNewBatch'
				element={<AddNewBatch />}
			/>

			<Route
				path='/batches/:batchId'
				element={<BatchInfo />}
			/>
			<Route
				path='/batches/:batchId/student/:studentId'
				element={<Submissions />}
			/>
			<Route
				path='/batches/:batchId/viewBatch'
				element={<ViewBatch />}
			/>
			<Route
				path='/login'
				element={<Login />}
			/>
		</Routes>
	);
};

export default AllRoutes;
