import { Button, Input, Option, Select, Textarea } from "@mui/joy";
import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";
import { addManualTaskThunk } from "../../../../Store/actions/taskAction";
import { addTask } from "../../../../Store/slices/taskDetailsSlice";
import { languages } from "../../../../Constants/languages";
import { ReactComponent as MagicWand } from "../../../../Assets/magic-wand.svg";
import { AI_API_KEY, AI_API_URL } from "../../../../Config";
import axios from "axios";

const CodeForm = ({ setOpen }) => {
	const [selectedPoint, setSelectedPoint] = useState(20);
	const [question, setQuestion] = useState("");
	const [constraints, setConstraints] = useState("");
	const [testCases, setTestCases] = useState("");
	const [lang, setLang] = useState("");
	const [taskTitle, setTaskTitle] = useState("");
	const [loading, setLoading] = useState(false);
	const [constraintLoader, setConstraintLoader] = useState(false);
	const [testCasesLoader, setTestCasesLoader] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();
	const node_id = location.pathname.split("/")[4];

	const handleSubmit = async () => {
		if (!question || !constraints || !testCases) {
			dispatch(showToast({ message: "Please fill all the fields", type: ToastType.ERROR }));
			return;
		}
		const task = {
			node_id,
			question,
			constraints,
			language: lang,
			test_cases: testCases,
			points: selectedPoint,
			task_title: taskTitle,
			// video_id,
			type: "CODE",
		};

		setLoading(true);
		const response = await dispatch(addManualTaskThunk(task));
		if (response?.payload?.data) {
			setQuestion("");
			setConstraints("");
			setTaskTitle("");
			setTestCases("");
			setSelectedPoint(20);
			dispatch(addTask(response.payload.data));
			setLoading(false);
			setOpen(false);
		}
	};

	const handleCreateConstraintWithAI = async () => {
		try {
			const data = {
				data: {
					question: question + " " + "in" + " " + lang,
				},
			};
			setConstraintLoader(true);
			const getConstraints = await axios.post(`${AI_API_URL}/generate_constraint`, data, {
				headers: {
					"X-INTERLEAP-API-KEY": `${AI_API_KEY}`,
					"Content-Type": "application/json",
				},
			});

			setConstraints(getConstraints.data.constraints.join("\n"));
			setConstraintLoader(false);
		} catch (error) {
			console.error(error);
			dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
			setConstraints("");
			setConstraintLoader(false);
		}
	};

	const handleCreateTestCasestWithAI = async () => {
		try {
			const data = {
				data: {
					question: question + " " + "in" + " " + lang,
					constraints: constraints.split("\n"),
				},
			};
			setTestCasesLoader(true);
			const getTestCases = await axios.post(`${AI_API_URL}/generate_testcase`, data, {
				headers: {
					"X-INTERLEAP-API-KEY": `${AI_API_KEY}`,
					"Content-Type": "application/json",
				},
			});
			setTestCases(getTestCases.data.testcases.map((testcase) => JSON.stringify(testcase)).join("\n"));
			setTestCasesLoader(false);
		} catch (error) {
			console.log(error);
			dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
			setTestCases("");
			setTestCasesLoader(false);
		}
	};
	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
				<Box
					sx={{
						maxHeight: "35vh",
						overflowY: "auto",
						px: 2,
					}}
				>
					<Box
						sx={{
							my: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Choose a language
						</Typography>

						<Select placeholder='Choose a language'>
							{languages.map((language) => (
								<Option
									key={language}
									value={language?.toLowerCase()}
									onClick={() => setLang(language?.toLowerCase())}
								>
									{language}
								</Option>
							))}
						</Select>
					</Box>
					<Box
						sx={{
							mb: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Task Title
						</Typography>
						<Input
							label='Task title'
							type='text'
							slotProps={{
								input: {
									maxLength: 64,
								},
							}}
							sx={{ width: "100%" }}
							value={taskTitle}
							onChange={(e) => setTaskTitle(e.target.value)}
							placeholder='e.g. Java collections?'
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Question
						</Typography>
						<Textarea
							label='Question'
							minRows={5}
							maxRows={10}
							type='text'
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							sx={{ width: "100%" }}
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
							placeholder='e.g. What are java collections?'
						/>
					</Box>
					<Box
						sx={{
							my: 1,
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								my: 1,
							}}
						>
							<Typography
								level='h6'
								sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Constraints (for AI)
							</Typography>
							<Button
								onClick={handleCreateConstraintWithAI}
								loading={constraintLoader}
								variant='soft'
								sx={{
									gap: 1,
								}}
								disabled={!question}
							>
								<MagicWand />
								Create with AI
							</Button>
						</Box>
						<Textarea
							minRows={5}
							maxRows={10}
							value={constraints}
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							onChange={(e) => setConstraints(e.target.value)}
							placeholder='Enter the constraints'
							label='Constraints'
							sx={{ width: "100%" }}
						/>
					</Box>
					<Box sx={{ my: 1 }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								my: 1,
							}}
						>
							<Typography
								level='h6'
								sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Test Cases (for AI)
							</Typography>
							<Button
								onClick={handleCreateTestCasestWithAI}
								loading={testCasesLoader}
								variant='soft'
								sx={{
									gap: 1,
								}}
								disabled={!question || !constraints}
							>
								<MagicWand />
								Create with AI
							</Button>
						</Box>
						<Textarea
							value={testCases}
							onChange={(e) => setTestCases(e.target.value)}
							minRows={5}
							maxRows={10}
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							placeholder='Enter test cases'
							label='Test cases'
							sx={{ width: "100%" }}
						/>
					</Box>

					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Points
						</Typography>

						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
									sx={{
										bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
										color: `${selectedPoint === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${selectedPoint === point ? "white" : "black"}`,
										},
									}}
									onClick={() => setSelectedPoint(point)}
								>
									{point}
								</Button>
							))}
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
								width: "100%",
								my: 1,
							}}
						>
							<Button
								sx={{}}
								disabled={!question || !constraints || !testCases || !lang || !taskTitle || loading}
								loading={loading}
								onClick={handleSubmit}
							>
								Add Task
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default CodeForm;
