import { Box, Button, Textarea, Typography, Input, IconButton, Card, Chip } from "@mui/joy";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";
import { updateTaskThunk } from "../../../../Store/actions/taskAction";
import { updateTaskData } from "../../../../Store/slices/taskDetailsSlice";
import { ReadingTaskTypes } from "../../../../Constants/task-types";


const DocEditForm = ({ setOpen, task }) => {
	const [selectedPoint, setSelectedPoint] = useState(task?.points);
	const [taskTitle, setTaskTitle] = useState(task?.task_title);
	const [docUrls, setDocUrls] = useState(task?.doc_urls || []);
	const [isEditing, setIsEditing] = useState(Array(docUrls.length).fill(false));
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [objective, setObjective] = useState(task?.question);
	const [step, setStep] = useState(1);
	const [readingTaskType, setReadingTaskType] = useState(ReadingTaskTypes.DOC_UPLOAD);
	const [webUrl, setWebUrl] = useState("");
	const [urlError, setUrlError] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();
	const node_id = location.pathname.split("/")[4];

	// Handle file upload logic here
	// const handleFileChange = (e, index) => {
	// 	const newDocUrls = [...docUrls];
	// 	newDocUrls[index] = e.target.files[0].name; // Or store the file itself if needed
	// 	setFiles([...files, e.target.files[0]]);
	// 	setDocUrls(newDocUrls);
	// 	toggleEdit(index); // Close the file input after selection
	// };

	// Toggle between edit mode and view mode for a specific document
	// const toggleEdit = (index) => {toggleEdit
	// 	const newIsEditing = [...isEditing];
	// 	newIsEditing[index] = !newIsEditing[index];
	// 	setIsEditing(newIsEditing);
	// };

	// Remove document from the list and show file input
	const handleFileRemove = (index) => {
		// const newIsEditing = [...isEditing];
		// newIsEditing[index] = true; // Open file input on remove
		// setIsEditing(newIsEditing);
		const updatedDocUrls = docUrls.filter((_, i) => i !== index);
		setDocUrls(updatedDocUrls); // Update the state
	};

	// Function to check if there are valid document URLs
	const hasValidDocs = () => {
		// Check if at least one document is present in files, docUrls, or webUrl
		return !(files.length > 0 && docUrls.some(url => url.trim() !== "") && webUrl.trim() !== "");
	};
	

	const handleEdit = async () => {
		if (!taskTitle || taskTitle.trim() === "") {
			dispatch(showToast({ toastMessage: "Task title is required", toastType: ToastType.ERROR }));
			return;
		}
		if (docUrls.length === 0 && webUrl.trim() === "") {
			dispatch(showToast({ toastMessage: "At least one document is required", toastType: ToastType.ERROR }));
			return;
		}
		if (files?.length > 0) {
			const formData = new FormData();
			formData.append("task_title", taskTitle);
			formData.append("question", objective);
			formData.append("points", selectedPoint);
			files.forEach((file) => {
				formData.append("docs", file);
			});
			formData.append("node_id", node_id);
			formData.append("task_id", task.task_id);

			// Include webUrl if it's present
			if (webUrl) {
				formData.append("web_url", webUrl);
			}

			// Append the new document URLs as arrays
			docUrls.forEach((docUrl) => {
				formData.append("new_docs[]", docUrl); // Append each URL individually
			});

			// Append the existing document URLs from the task (if needed)
			task.doc_urls.forEach((docUrl) => {
				formData.append("doc_urls[]", docUrl); // Append each existing URL individually
			});
			setLoading(true);
			const response = await dispatch(updateTaskThunk(formData));
			if (response?.payload?.data) {
				dispatch(updateTaskData(response.payload.data));
				setLoading(false);
				setOpen(false);
			}
		} else if ((docUrls != task.doc_urls && files.length === 0) || selectedPoint != task.points || taskTitle != task.question || webUrl) {
			// Update the task with new details
			const updatedTask = {
				task_id: task.task_id,
				node_id: node_id,
				question: objective,
				task_title: taskTitle,
				points: selectedPoint,
				new_docs: docUrls,
				doc_urls: task.doc_urls,
			};

			// Include webUrl if it's present
			if (webUrl) {
				updatedTask.web_url = webUrl; // Add webUrl to the updatedTask
			}

			// Dispatch an action to update the task
			setLoading(true);
			const response = await dispatch(updateTaskThunk(updatedTask));
			if (response?.payload?.data) {
				dispatch(updateTaskData(response.payload.data));
				setLoading(false);
				setOpen(false);
			}
		}
	};

	const renderReadingTaskOptions = () => {
		return (
			<>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Type
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 2,
						}}
					>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setReadingTaskType(ReadingTaskTypes.DOC_UPLOAD)}
						>
							<Typography
								sx={{
									color: `${readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								File Upload
							</Typography>
						</Card>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${readingTaskType === ReadingTaskTypes.DOC_LINK ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${readingTaskType === ReadingTaskTypes.DOC_LINK ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setReadingTaskType(ReadingTaskTypes.DOC_LINK)}
						>
							<Typography
								sx={{
									color: `${readingTaskType === ReadingTaskTypes.DOC_LINK ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								Web URL
							</Typography>
						</Card>
					</Box>
				</Box>
				{readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? (
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 0.5, mt: 2, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Documents
						</Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
							}}
						>
							{docUrls.length < 4 && (

								<Box
									sx={{
										alignItems: "center",
										backgroundColor: "white",
										borderRadius: "md",
										p: 0.5,
										boxShadow: 1,
										width: "100%",
										border: "1px solid #f4f4f4",
									}}
								>
									<IconButton
										component='label'
										color='primary'
										sx={{
											borderRadius: "md",
											width: "100%",
										}}
									>
										<Input
											type='file'
											slotProps={{
												input: {
													accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
												},
											}}
											sx={{ display: "none" }}
											onChange={(e) => {
												setFiles([...files, e.target.files[0]]);
												setDocUrls([...docUrls, e.target.files[0].name]);
											}}
										/>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 1,
												justifyContent: "space-between",
												width: "100%",
												padding: " 0 1rem",
											}}
										>
											<UploadIcon />
											<Typography>Upload Document</Typography>
										</Box>
									</IconButton>
								</Box>
							)}
						</Box>
					</Box>
				) : readingTaskType === ReadingTaskTypes.DOC_LINK && (
					<Box
						sx={{
							my: 2,
						}}
					>

						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Web URL
						</Typography>
						<Input
							label='Web URL'
							type='url'
							value={webUrl}
							onChange={(e) => setWebUrl(e.target.value)}
							onBlur={() => {
								// Validate URL on blur
								if (!/^https?:\/\/.+\..+/.test(webUrl)) {
									setWebUrl(""); // Set webUrl to null on error
									setUrlError(true); // Set error state
								} else {
									setUrlError(false); // Clear error state if valid
								}
							}}
							sx={{ width: "100%" }}
							placeholder='e.g. https://example.com'
							disabled={docUrls.length >= 4} // Disable input if max URLs reached
							required
						/>
						{/* Optional: Add validation message */}
						{urlError && (
							<Typography sx={{ color: "red", fontSize: "12px" }}>
								{docUrls.length >= 4
									? "You can only add up to 4 URLs."
									: "Please enter a valid URL."}
							</Typography>
						)}
					</Box>
				)}


				<Box
					sx={{
						mt: 2,
					}}>
					{docUrls.map((file, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								padding: "0.5rem 1rem",
								bgcolor: "#f4f4f4",
								borderRadius: "sm",
								gap: 1,
								mb: 1
							}}
						>
								<Link
									to={file}
									target='_blank'
									style={{ textDecoration: "none" }}
								>
									<Typography sx={{ color: "#3838f1", fontWeight: "600" }}>Reading Task {index + 1}</Typography>
									<Typography
										sx={{
											width: "400px",
										}}
										noWrap
									>
										{file}
									</Typography>
								</Link>
							<Box sx={{ display: "flex", gap: 1 }}>
								<Button
									variant='outlined'
									color='neutral'
									onClick={(e) => {
										e.stopPropagation();
										handleFileRemove(index);
									}}
								>
									Remove
								</Button>
							</Box>
						</Box>
					))}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Button
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						variant='outlined'
						onClick={() => {
							setStep(1);
						}}
					>
						Previous: Task Details
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							disabled={files.length === 0 && docUrls.every((url) => url.trim() === "") && webUrl.trim() === ""}
							onClick={() => {
								handleEdit();
							}}
							loading={loading}
						>
							Save
						</Button>
					</Box>
				</Box >
			</>
		);
	};

	const renderReadingEditTask = () => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							maxHeight: "40vh",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							gap: 2,
							p: 2,
						}}
					>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Title
							</Typography>
							<Input
								label='Task title'
								type='text'
								slotProps={{
									input: {
										maxLength: 64,
									},
								}}
								value={taskTitle}
								onChange={(e) => setTaskTitle(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. Binary Search'
								required
								error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
							/>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Description
							</Typography>
							<Textarea
								label='Objective'
								onChange={(e) => setObjective(e.target.value)}
								minRows={5}
								maxRows={10}
								value={objective}
								type='text'
								slotProps={{
									textarea: {
										maxLength: 1024,
									},
								}}
								sx={{ width: "100%" }}
								placeholder='e.g. Compiler - Assembler Differences'
							/>
						</Box>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Points
						</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
									sx={{
										bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
										color: `${selectedPoint === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${selectedPoint === point ? "white" : "black"}`,
										},
									}}
									onClick={() => setSelectedPoint(point)}
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						gap: 2,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => {
							setOpen(false);
						}}
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
					>
						Cancel
					</Button>
					<Button
						sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
						color='primary'
						disabled={taskTitle === "" || objective === ""}
						onClick={() => {
							setStep(2);
						}}
					>
						Next:Additional Info
					</Button>
				</Box>
			</Box>
		);
	};

	const renderSteps = () => {
		switch (step) {
			case 1:
				return renderReadingEditTask();
			case 2:
				return renderReadingTaskOptions();

			default:
				return renderReadingEditTask();
		}
	};

	return renderSteps();
};

export default DocEditForm;
