import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import MCQOptions from "./mcq-option";
import { Button, IconButton, Input, Textarea, FormControl, Option, Select } from "@mui/joy";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastType } from "../../../../Constants/toast-type";
import { addManualTaskThunk } from "../../../../Store/actions/taskAction";
import UploadIcon from "@mui/icons-material/Upload";
import { showToast } from "../../../../Store/slices/commonSlice";
import { addTask } from "../../../../Store/slices/taskDetailsSlice";
import CodeHighlighter from "../../../utils/code-highlighter";
import { optionKey } from "../../../../Constants/option";
import { ReactComponent as MagicWand } from "../../../../Assets/magic-wand.svg";
import { languages } from "../../../../Constants/languages";
import axios from "axios";
import { AI_API_KEY, AI_API_URL } from "../../../../Config";

const MCQForm = ({ setOpen, setShowTaskType }) => {
	const [options, setOptions] = useState([
		{ text: "", correct: false },
		{ text: "", correct: false },
		{ text: "", correct: false },
		{ text: "", correct: false },
	]);
	const [currentOptionText, setCurrentOptionText] = useState("");
	const [taskTitle, setTaskTitle] = useState("");
	const [question, setQuestion] = useState("");
	const [selectedPoint, setSelectedPoint] = useState(20);
	const [loading, setLoading] = useState(false);
	const [steps, setSteps] = useState(1);
	const [fileName, setFileName] = useState("");
	const [fileData, setFileData] = useState(null);
	const [explanation, setExplanation] = useState("");
	const [explanationLoader, setExplanationLoader] = useState(false);
	const [code, setCode] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("java");
	const [codeCopied, setCodeCopied] = useState(false);
	const [filePreview, setFilePreview] = useState(null);

	const location = useLocation();
	const dispatch = useDispatch();
	const node_id = location.pathname.split("/")[4];

	const handleOptionTextChange = (e) => {
		setCurrentOptionText(e.target.value);
	};

	const handleLanguageChange = (event, newValue) => {
		setSelectedLanguage(newValue);
	};

	const handleCopyCode = () => {
		// Copy the code to the clipboard
		navigator.clipboard
			.writeText(code)
			.then(() => {
				setCodeCopied(true);
			})
			.catch((error) => {
				console.error("Failed to copy code: ", error);
			});
	};

	const handleCorrectChange = (index, value) => {
		if (value === false) {
			setOptions((prevOptions) =>
				prevOptions.map((option, i) => {
					if (i === index) {
						return { ...option, correct: value };
					}
					return option;
				})
			);
			return;
		}
		const correctOptionPresent = options.find((option) => option.correct === true);
		if (correctOptionPresent) {
			dispatch(showToast({ toastMessage: "Only one correct option is allowed", toastType: ToastType.ERROR }));
		} else {
			setOptions((prevOptions) =>
				prevOptions.map((option, i) => {
					if (i === index) {
						return { ...option, correct: value };
					}
					return option;
				})
			);
		}
	};

	const addCurrentOption = (index, value) => {
		setOptions((prev) =>
			prev.map((option, i) => {
				if (i === index) {
					return { ...option, text: value };
				}
				return option;
			})
		);
	};

	const handleDeleteOption = (index) => {
		setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
	};

	const handleAddOption = () => {
		if (currentOptionText.trim() === "") {
			return;
		}

		setOptions((prevOptions) => [...prevOptions, { text: currentOptionText, correct: false }]);

		setCurrentOptionText(""); // Clear the input after adding
	};

	const handleSubmit = async () => {
		if (!question || options.length === 0) {
			dispatch(showToast({ message: "Please fill all the fields", type: ToastType.ERROR }));
			return;
		}
		//check if any two or option value is same then show error
		const optionTexts = options.map((option) => option.text.trim());
		const uniqueOptions = new Set(optionTexts);
		if (optionTexts.length !== uniqueOptions.size) {
			dispatch(showToast({ toastMessage: "Options should be unique", toastType: ToastType.ERROR }));
			return;
		}
		let task;
		if (fileName) {
			task = new FormData();
			task.append("image", fileData);
			task.append("node_id", node_id);
			task.append("task_title", question);
			task.append("question", question);
			task.append("options", JSON.stringify(options)); // Convert options array to JSON string
			task.append("points", selectedPoint);
			task.append("explanation", explanation);
			task.append("type", "MCQ");
			if (code) {
				task.append("code_snippet", code); // Optional code snippet
			}
		} else if (fileName === "") {
			task = {
				node_id,
				question,
				options: JSON.stringify(options),
				task_title: question,
				explanation,
				points: selectedPoint,
				type: "MCQ",
				code_snippet: code || null,
			};
		}

		setLoading(true);
		const response = await dispatch(addManualTaskThunk(task));

		if (response?.payload?.data) {
			setOptions([]);
			setQuestion("");
			setExplanation("");
			setSelectedPoint(20);
			dispatch(addTask(response.payload.data));
			setOpen(false);
			setLoading(false);
		}
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setFilePreview(reader.result);
			};
			reader.readAsDataURL(file);
			setFileName(file.name);
			setFileData(file);
			// Create a URL for the uploaded file to preview it
		}
	};

	const handleCreateExplanationWithAI = async () => {
		try {
			setExplanationLoader(true);
			const data = {
				data: {
					question,
					options,
				},
			};
			if (code) {
				data.data.code = code;
			}
			const getExplanation = await axios.post(`${AI_API_URL}/Mcq_explanation`, data, {
				headers: {
					"X-INTERLEAP-API-KEY": `${AI_API_KEY}`,
					"Content-Type": "application/json",
				},
			});
			setExplanation(getExplanation.data.justification);
			setExplanationLoader(false);
		} catch (error) {
			console.error(error);
			dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
			setExplanation("");
			setExplanationLoader(false);
		}
	};

	const renderMCQForm = () => {
		const handleNext = () => {
			const optionTexts = options.map((option) => option.text.trim());
			const uniqueOptions = new Set(optionTexts);
			if (optionTexts.length !== uniqueOptions.size) {
				dispatch(showToast({ toastMessage: "Options should be unique", toastType: ToastType.ERROR }));
				return;
			}
			setSteps(2);
			setShowTaskType(false);
		};
		return (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, mt: 1, maxHeight: "70vh", overflowY: "auto" }}>
					<Box
						sx={{
							maxHeight: "35vh",
							overflowY: "auto",
							paddingX: 2,
						}}
					>
						{/* <Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Enter the task title :
							</Typography>
							<Input
								label='Task title'
								type='text'
								slotProps={{
									input: {
										maxLength: 64,
									},
								}}
								value={question}
								onChange={(e) => setTaskTitle(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. Java Collections'
								required
								error={question?.trim() === "" ? "Please enter the task title" : ""}
							/>
						</Box> */}
						<Box>
							<Typography
								level='h6'
								sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Question
							</Typography>
							<Textarea
								label='Question'
								type='text'
								minRows={5}
								maxRows={5}
								slotProps={{
									textarea: {
										maxLength: 1024,
									},
								}}
								value={question}
								onChange={(e) => setQuestion(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. What are java collections?'
								required
								// error={question.trim() === ""}
							/>
						</Box>
						<Box
							sx={{
								my: 1,
							}}
						>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Options
							</Typography>
							{options.map((option, index) => {
								return (
									<Box
										key={index}
										sx={{
											display: "flex",
											alignItems: "center",
											gap: 2,
											my: 2,
										}}
									>
										<TextField
											label={`Option ${index + 1}`}
											type='text'
											value={option.text}
											onChange={(e) => addCurrentOption(index, e.target.value)}
											sx={{ flexGrow: 1 }}
											maxRows={1}
											inputProps={{
												maxLength: 64,
											}}
											size='small'
											required
										/>
										<ToggleButtonGroup
											sx={{
												borderRadius: "12px",
												width: "40%",
											}}
											size='small'
										>
											<ToggleButton
												sx={{
													borderRight: option.correct ? "1px rgba(0, 0, 0, 0.10);" : "none",
													bgcolor: option.correct ? "#42B25B" : "#F5F5FF",
													color: option.correct ? "#F5F5FF" : "#000",
													fontWeight: "600",
													width: "50%",
													":hover": {
														bgcolor: option.correct ? "#42B25B" : "white",
														color: option.correct ? "#F5F5FF" : "black",
													},
												}}
												onClick={() => handleCorrectChange(index, true)}
											>
												Right
											</ToggleButton>
											<ToggleButton
												sx={{
													bgcolor: option.correct ? "#F5F5FF" : "#D55442",
													color: option.correct ? "#000" : "#F5F5FF",
													fontWeight: "600",
													width: "50%",
													":hover": {
														bgcolor: option.correct ? "#white" : "#D55442",
														color: option.correct ? "black" : "#F5F5FF",
													},
												}}
												onClick={() => handleCorrectChange(index, false)}
											>
												Wrong
											</ToggleButton>
										</ToggleButtonGroup>
									</Box>
								);
							})}
						</Box>
						<Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									my: 1,
								}}
							>
								<Typography
									level='h6'
									sx={{ color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Explanation
								</Typography>
								<Button
									variant='soft'
									sx={{
										gap: 1,
									}}
									onClick={handleCreateExplanationWithAI}
									disabled={!question || !options}
									loading={explanationLoader}
								>
									<MagicWand />
									Create with AI
								</Button>
							</Box>
							<Textarea
								label='Question'
								type='text'
								minRows={5}
								maxRows={5}
								value={explanation}
								slotProps={{
									textarea: {
										maxLength: 1024,
									},
								}}
								onChange={(e) => setExplanation(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. Explanation of the question'
								required
								// error={explanation?.trim() === ""}
							/>
						</Box>
						<Typography
							level='h6'
							sx={{ mt: 1 }}
						>
							Points
						</Typography>
						<Box sx={{ display: "flex", gap: 1, mt: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									onClick={() => setSelectedPoint(point)}
									variant='outlined'
									color='neutral'
									sx={{
										bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
										color: `${selectedPoint === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${selectedPoint === point ? "white" : "black"}`,
										},
									}}
								>
									{point}
								</Button>
							))}
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								width: "100%",
								my: 1,
								gap: 2,
							}}
						>
							<Button
								variant='outlined'
								onClick={() => setOpen(false)}
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
							>
								Cancel
							</Button>
							<Button
								sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
								color='primary'
								disabled={
									!question ||
									!explanation ||
									options.length === 0 ||
									options.some((option) => option.text.trim() === "") ||
									!options.some((option) => option.correct === true)
								}
								loading={loading}
								onClick={handleNext}
							>
								Next: Additional Info
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderAdditionalForm = () => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						maxHeight: "70vh",
						overflowY: "auto",
						p: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Image Reference (optional)
						</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								border: "1px solid rgba(0,0,0,0.25)",
								borderRadius: "10px",
								p: 0.5,
							}}
						>
							<IconButton
								component='label'
								color='primary'
								sx={{
									borderRadius: "md",

									width: "100%",
								}}
							>
								<Input
									type='file'
									slotProps={{
										input: {
											accept: "image/*",
										},
									}}
									sx={{ display: "none" }}
									onChange={handleFileChange}
								/>
								<UploadIcon />
								<Typography sx={{ marginX: 2 }}>{fileName ? fileName : "Upload JPG or PNG"}</Typography>
							</IconButton>
						</Box>
						{filePreview && (
							<Box sx={{ mt: 2, width: "100%", height: "150px" }}>
								<img
									src={filePreview}
									alt='Preview'
									style={{ width: "100%", borderRadius: "10px", height: "100%", zIndex: 1000, objectFit: "contain" }}
								/>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 0.5, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Code Snippet (optional)
						</Typography>
						<Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									mb: 1,
									gap: 1,
								}}
							>
								<FormControl
									fullWidth
									margin='normal'
								>
									<Select
										labelId='language-select-label'
										value={selectedLanguage}
										onChange={handleLanguageChange}
										label='Language'
									>
										<Option value='javascript'>JavaScript</Option>
										<Option value='python'>Python</Option>
										<Option value='java'>Java</Option>
										<Option value='mysql'>MySQL</Option>
										<Option value='bash'>Bash</Option>
										<Option value='kotlin'>Kotlin</Option>
										<Option value='typescript'>TypeScript</Option>
										<Option value='nodejs'>NodeJs</Option>
										<Option value='mongodb'>MongoDB</Option>
										<Option value='php'>PHP</Option>
										<Option value='html/css'>HTML/CSS</Option>
										<Option value='others'>Others</Option>
									</Select>
								</FormControl>

								<Button
									onClick={handleCopyCode}
									variant='outlined'
									disabled={!code}
									sx={{ mr: 2 }}
								>
									{codeCopied ? "Copied!" : "Copy"}
								</Button>
							</Box>
							<Box
								sx={{
									height: "250px",
								}}
							>
								<CodeHighlighter
									code={code}
									height={"250px"}
									language={selectedLanguage}
									onCodeChange={setCode}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
						mt: 2,
						gap: 2,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => {
							setSteps(1);
							setShowTaskType(true);
						}}
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
					>
						Previous: MCQ Task
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							onClick={() => {
								setSteps(3);
							}}
						>
							Next: Review
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderFinalForm = () => {
		return (
			<Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						padding: "1rem",
						maxHeight: "70vh",
						overflowY: "auto",
					}}
				>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Task Title
					</Typography>
					<Box sx={{ borderRadius: "8px", my: 1 }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700 }}>{question}</Typography>
					</Box>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Question
					</Typography>
					<Box sx={{ borderRadius: "8px", my: 1 }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700 }}>{question}</Typography>
					</Box>

					{filePreview && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
							<img
								src={filePreview}
								alt='Preview'
								style={{ width: "100%", borderRadius: "10px", height: "100%", zIndex: 1000, objectFit: "contain" }}
							/>
						</Box>
					)}
					{code && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
							<CodeHighlighter
								code={code}
								height={"200px"}
							/>
						</Box>
					)}

					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Options
					</Typography>
					<Box sx={{ borderRadius: "8px" }}>
						{options.map((option, index) => {
							return (
								<Box
									key={index}
									sx={{
										mb: 0.5,
									}}
								>
									<Typography
										sx={{
											borderRadius: "5px",
											fontWeight: 700,
											color: option.correct ? "#42B25B" : "#050519",

											width: "fit-content",
											px: 2,
											fontSize: "1rem",
										}}
									>
										{optionKey[index + 1]}. &nbsp; {option.text}
									</Typography>
								</Box>
							);
						})}
					</Box>
					<Typography>Explanation</Typography>
					<Box>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700 }}>{explanation}</Typography>
					</Box>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Points
					</Typography>
					<Box sx={{ borderRadius: "8px" }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700 }}>{selectedPoint}</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
						mt: 2,
						gap: 2,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => {
							setSteps(2);
						}}
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
					>
						Previous: Additional Info
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							onClick={handleSubmit}
							loading={loading}
						>
							Add Task
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderMCQStepForm = () => {
		switch (steps) {
			case 1:
				return renderMCQForm();
			case 2:
				return renderAdditionalForm();
			case 3:
				return renderFinalForm();
			default:
				return;
		}
	};

	return <Box>{renderMCQStepForm()}</Box>;
};

export default MCQForm;
