import { Box, Input, Select, Typography, Option, Textarea, Card, Button } from "@mui/joy";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import { updateCourseThunk } from "../../../Store/actions/courseAction";
import { updateCourseData } from "../../../Store/slices/courseSlice";

const CourseInfo = () => {
	const { courseData } = useSelector((state) => state.course);

	const [selectedStyleCard, setSelectedStyleCard] = useState("Cohort Based Course");
	const [courseName, setCourseName] = useState(courseData?.data?.name || "");
	const [courseDescription, setCourseDescription] = useState(courseData?.data?.description || "");

	const dispatch = useDispatch();

	const handleSaveCourseName = async () => {
		if (courseName !== courseData?.data?.name && courseName.trim()) {
			const courseId = courseData?.data?.course_id;
			const courseDetails = {
				name: courseName,
			};

			const response = await dispatch(updateCourseThunk({ courseId, courseDetails }));

			if (response?.payload?.data) {
				dispatch(updateCourseData({ name: courseName }));
				dispatch(showToast({ toastMessage: "Course name updated successfully", toastType: ToastType.SUCCESS }));
			}
		} else {
			setCourseName(courseData?.data?.name || "");
		}
	};

	const handleSaveCourseDescription = async () => {
		if (courseDescription !== courseData?.data?.description) {
			const courseId = courseData?.data?.course_id;
			const courseDetails = {
				description: courseDescription,
			};

			const response = await dispatch(updateCourseThunk({ courseId, courseDetails }));

			if (response?.payload?.data) {
				dispatch(updateCourseData({ description: courseDescription }));
				dispatch(showToast({ toastMessage: "Course description updated successfully", toastType: ToastType.SUCCESS }));
			}
		} else {
			setCourseDescription(courseData?.data?.description || "");
		}
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					gap: 4,
					p: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						gap: 4,
					}}
				>
					<Box sx={{ width: "100%" }}>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600", textAlign: "left" }}
						>
							Name of the course
						</Typography>

						<Input
							placeholder='e.g. Python'
							size='sm'
							type='text'
							value={courseName}
							onChange={(e) => setCourseName(e.target.value)}
							onBlur={handleSaveCourseName}
							autoFocus
							slotProps={{
								input: {
									maxLength: 64,
								},
							}}
							sx={{ width: "100%", "--Input-focusedThickness": "0rem" }}
						/>
					</Box>
					<Box sx={{ width: "100%" }}>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600", textAlign: "left" }}
						>
							Difficutly
						</Typography>
						<Select
							defaultValue={courseData?.data?.difficulty_level}
							variant='plane'
							// onChange={(e, newValue) => {
							// 	setDifficulty(newValue);
							// }}
							style={{ backgroundColor: "#fff", border: "1px solid #d7d7d7" }}
						>
							<Option value='BEGINNER'>Beginner</Option>
							{/* <Option value='INTERMEDIATE'>Intermediate</Option>
										<Option value='ADVANCED'>Advanced</Option> */}
						</Select>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						gap: 4,
					}}
				>
					<Box sx={{ width: "100%" }}>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600", textAlign: "left" }}
						>
							Description
						</Typography>

						<Textarea
							label='Description'
							type='text'
							minRows={5}
							maxRows={5}
							value={courseDescription}
							onChange={(e) => setCourseDescription(e.target.value)}
							onBlur={handleSaveCourseDescription}
							autoFocus
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							sx={{ width: "100%" }}
							placeholder='e.g. Description of the course'
						/>
					</Box>
					<Box sx={{ width: "100%" }}>
						<Typography
							level='h6'
							sx={{ mb: 1, mt: -1, color: "#050519", fontSize: "14px", fontWeight: "600", textAlign: "left" }}
						>
							Course Type
						</Typography>
						<Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 2 }}>
							<Card
								variant='outlined'
								color='neutral'
								sx={{
									cursor: "not-allowed", // Disabled cursor style
									maxHeight: "80px",
									bgcolor: "#f4f4f4", // Disabled background color
									border: "1px solid #bdbdbd", // Disabled border style
								}}
							>
								<Typography
									sx={{
										color: "#9e9e9e", // Disabled text color
										fontWeight: "600",
									}}
								>
									Self Paced Course
								</Typography>
								<Typography
									sx={{
										fontSize: "12px",
										color: "#9e9e9e", // Disabled description color
									}}
								>
									No specific schedule, students access at their own pace
								</Typography>
							</Card>

							<Card
								variant='outlined'
								color='neutral'
								sx={{
									cursor: "pointer",
									maxHeight: "80px",
									bgcolor: `${selectedStyleCard === "Cohort Based Course" ? "#F5f5ff" : "#f4f4f4"}`,
									border: `${selectedStyleCard === "Cohort Based Course" ? "2px solid #3838F1" : "none"}`,
								}}
								onClick={() => setSelectedStyleCard("Cohort Based Course")}
							>
								<Typography
									sx={{
										color: `${selectedStyleCard === "Cohort Based Course" ? "#3838F1" : "black"}`,
										fontWeight: "600",
									}}
								>
									Cohort Based Course
								</Typography>
								<Typography
									sx={{
										fontSize: "12px",
									}}
								>
									Specific schedule, drip-wise student content distribution
								</Typography>
							</Card>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default CourseInfo;
