import { Box, Button, FormControl, IconButton, Input, Option, Select, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import MCQOptions from "../create-task/mcq-option";
import { useDispatch } from "react-redux";
import { updateTask } from "../../../../Store/services/taskApiServices";
import { updateTaskData } from "../../../../Store/slices/taskDetailsSlice";
import { updateTaskThunk } from "../../../../Store/actions/taskAction";
import { useLocation } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CodeHighlighter from "../../../utils/code-highlighter";
import { optionKey } from "../../../../Constants/option";
import { ToastType } from "../../../../Constants/toast-type";
import { showToast } from "../../../../Store/slices/commonSlice";
import CloseIcon from "@mui/icons-material/Close";
// import { updateTask } from "../../../../Store/slices/taskDetailsSlice";

const MCQEditForm = ({ setOpen, task }) => {
	const [question, setQuestion] = useState(task?.question);
	const [options, setOptions] = useState(task?.mcq_options);
	const [loading, setLoading] = useState(false);
	const [taskTitle, setTaskTitle] = useState(task?.task_title);
	const [explanation, setExplanation] = useState(task?.explanation);
	const [selectedPoint, setSelectedPoint] = useState(task?.points);
	const [code, setCode] = useState(task?.code_snippet);
	const [selectedLanguage, setSelectedLanguage] = useState("javascript");
	const [fileName, setFileName] = useState("");
	const [fileData, setFileData] = useState(null);
	const [filePreview, setFilePreview] = useState(null);
	const [imageUrl, setImageUrl] = useState(task?.image_url);
	const [codeCopied, setCodeCopied] = useState(false);
	const [steps, setSteps] = useState(1);

	const dispatch = useDispatch();
	const location = useLocation();
	const node_id = location.pathname.split("/")[4];

	const addCurrentOption = (index, value) => {
		setOptions((prev) =>
			prev.map((option, i) => {
				if (i === index) {
					return { ...option, text: value };
				}
				return option;
			})
		);
	};

	const handleCorrectChange = (index) => {
		//add the check for to add only one correct option

		setOptions((prevOptions) =>
			prevOptions.map((option, i) => {
				if (i === index) {
					const checkCorrectOption = prevOptions.some((option) => option.correct === true);
					if (checkCorrectOption) {
						if (option.correct) {
							return { ...option, correct: !option.correct };
						} else {
							dispatch(showToast({ toastMessage: "Only one correct option is allowed", toastType: ToastType.ERROR }));
							return option;
						}
					} else {
						return { ...option, correct: !option.correct };
					}
				}
				return option;
			})
		);
	};

	const handleDeleteOption = (index) => {
		setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setFilePreview(reader.result);
			};
			reader.readAsDataURL(file);
			setFileName(file.name);
			setFileData(file);
			// Create a URL for the uploaded file to preview it
		}
	};

	const handleSubmit = async () => {
		if (
			question.trim() === "" ||
			options.length < 2 ||
			options.some((option) => option.text.trim() === "") ||
			(question?.trim() === task?.question?.trim() &&
				explanation === task.explanation &&
				selectedPoint === task?.points &&
				JSON.stringify(options) === JSON.stringify(task?.options) &&
				code === task?.code_snippet)
		) {
			return;
		}

		const optionTexts = options.map((option) => option.text.trim());
		const uniqueOptions = new Set(optionTexts);
		if (optionTexts.length !== uniqueOptions.size) {
			dispatch(showToast({ toastMessage: "Options should be unique", toastType: ToastType.ERROR }));
			return;
		}

		let taskData;
		if (fileName) {
			taskData = new FormData();
			taskData.append("image", fileData);
			taskData.append("node_id", node_id);
			taskData.append("task_title", taskTitle);
			taskData.append("question", question);
			taskData.append("options", JSON.stringify(options));
			taskData.append("points", selectedPoint);
			taskData.append("explanation", explanation);
			taskData.append("task_id", task?.task_id);
			if (code) {
				taskData.append("code_snippet", code); // Optional code snippet
			}
		} else if (fileName === "") {
			taskData = {
				question,
				mcq_options: options,
				points: selectedPoint,
				task_id: task?.task_id,
				task_title: taskTitle,
				node_id: node_id,
				image: imageUrl,
				explanation,
				code_snippet: code || null,
			};
		}

		setLoading(true);
		const response = await dispatch(updateTaskThunk(taskData));
		if (response?.payload?.data) {
			dispatch(updateTaskData(response.payload.data));
			setLoading(false);
			setOpen(false);
		}
	};

	const handleCopyCode = () => {
		// Copy the code to the clipboard
		navigator.clipboard
			.writeText(code)
			.then(() => {
				setCodeCopied(true);
			})
			.catch((error) => {
				console.error("Failed to copy code: ", error);
			});
	};

	const renderEditForm = () => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
						}}
					>
						<Box
							sx={{
								maxHeight: "40vh",
								overflowY: "auto",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							{/* <Box>
								<Typography
									level='h6'
									sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Question
								</Typography>
								<Input
									value={taskTitle}
									label='Task Title'
									type='text'
									required
									slotProps={{
										input: {
											maxLength: 64,
										},
									}}
									sx={{ width: "100%" }}
									onChange={(e) => setTaskTitle(e.target.value)}
									placeholder='e.g. Java Collections'
									error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
								/>
							</Box> */}
							<Box>
								<Typography
									level='h6'
									sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Question
								</Typography>
								<Textarea
									value={question}
									label='Question'
									type='text'
									minRows={5}
									maxRows={10}
									required
									slotProps={{
										textarea: {
											maxLength: 1024,
										},
									}}
									sx={{ width: "100%" }}
									onChange={(e) => setQuestion(e.target.value)}
									placeholder='e.g. What are java collections?'
									error={question.trim() === "" ? "Please enter the question" : ""}
								/>
							</Box>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Options
								</Typography>
								{options.map((option, index) => {
									return (
										<Box
											key={index}
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 2,
												my: 2,
											}}
										>
											<TextField
												label={`Option ${index + 1}`}
												type='text'
												value={option.text}
												onChange={(e) => addCurrentOption(index, e.target.value)}
												sx={{ flexGrow: 1 }}
												maxRows={1}
												inputProps={{
													maxLength: 64,
												}}
												size='small'
												required
											/>
											<ToggleButtonGroup
												sx={{
													borderRadius: "12px",
													width: "40%",
												}}
												size='small'
											>
												<ToggleButton
													sx={{
														borderRight: option.correct ? "1px rgba(0, 0, 0, 0.10);" : "none",
														bgcolor: option.correct ? "#42B25B" : "#F5F5FF",
														color: option.correct ? "#F5F5FF" : "#000",
														fontWeight: "600",
														width: "50%",
														":hover": {
															bgcolor: option.correct ? "#42B25B" : "white",
															color: option.correct ? "#F5F5FF" : "black",
														},
													}}
													onClick={() => handleCorrectChange(index, true)}
												>
													Right
												</ToggleButton>
												<ToggleButton
													sx={{
														bgcolor: option.correct ? "#F5F5FF" : "#D55442",
														color: option.correct ? "#000" : "#F5F5FF",
														fontWeight: "600",
														width: "50%",
														":hover": {
															bgcolor: option.correct ? "#white" : "#D55442",
															color: option.correct ? "black" : "#F5F5FF",
														},
													}}
													onClick={() => handleCorrectChange(index, false)}
												>
													Wrong
												</ToggleButton>
											</ToggleButtonGroup>
										</Box>
									);
								})}
							</Box>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Explanation
								</Typography>
								<Textarea
									value={explanation}
									label='Question'
									type='text'
									minRows={5}
									maxRows={10}
									slotProps={{
										textarea: {
											maxLength: 1024,
										},
									}}
									required
									sx={{ width: "100%" }}
									onChange={(e) => setExplanation(e.target.value)}
									placeholder='e.g. Explanation for the question'
									error={explanation?.trim() === "" ? "Please enter the explanation" : ""}
								/>
							</Box>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Points
							</Typography>
							<Box sx={{ display: "flex", gap: 1 }}>
								{[1, 5, 10, 15, 20, 25].map((point) => (
									<Button
										key={point}
										variant='outlined'
										color='neutral'
										sx={{
											bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
											color: `${selectedPoint === point ? "white" : "black"}`,
											"&:hover": {
												bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
												color: `${selectedPoint === point ? "white" : "black"}`,
											},
										}}
										onClick={() => setSelectedPoint(point)}
									>
										{point}
									</Button>
								))}
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							mt: 2,
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setSteps(1);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Previous: View Task
						</Button>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Button
								variant='outlined'
								onClick={() => {
									setOpen(false);
								}}
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
							>
								Cancel
							</Button>
							<Button
								sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
								color='primary'
								onClick={() => setSteps(3)}
								loading={loading}
							>
								Next: Additional Info
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderEditMCQImage = () => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						maxHeight: "70vh",
						overflowY: "auto",
						p: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Image Reference (optional)
						</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								border: "1px solid rgba(0,0,0,0.25)",
								borderRadius: "10px",
								p: 0.5,
							}}
						>
							<IconButton
								component='label'
								color='primary'
								sx={{
									borderRadius: "md",
									cursor: imageUrl ? "not-allowed" : "pointer",
									width: "100%",
									":hover": {
										bgcolor: imageUrl ? "transparent" : "#f4f4f4",
									},
								}}
								disabled={imageUrl}
							>
								<Input
									type='file'
									slotProps={{
										input: {
											accept: "image/*",
										},
									}}
									disabled={imageUrl}
									sx={{ display: "none", cursor: imageUrl ? "not-allowed" : "pointer" }}
									onChange={handleFileChange}
								/>
								<UploadIcon />
								<Typography sx={{ marginX: 2 }}>{fileName ? fileName : "Upload JPG or PNG"}</Typography>
							</IconButton>
						</Box>
						{imageUrl && (
							<>
								<Box
									sx={{
										mt: 2,
										width: "100%",
										height: "150px",
										position: "relative",
										cursor: "pointer",
										"&:hover .close-icon": {
											display: "block", // Show close icon on hover
										},
									}}
								>
									<img
										src={imageUrl}
										alt='Preview'
										style={{ width: "100%", borderRadius: "10px", height: "100%", objectFit: "contain", zIndex: 1 }}
									/>
									<Box
										className='close-icon'
										sx={{
											position: "absolute",
											top: -5,
											right: 220,
											zIndex: 1000,
											display: "none",
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												bgcolor: "#f4f4f4",
												borderRadius: "8px",
												padding: "0.5rem",
											}}
											onClick={() => {
												setImageUrl(null);
												setFileName("");
												setFileData(null);
												setFilePreview(null);
											}}
										>
											<CloseIcon />
										</Box>
									</Box>
								</Box>
							</>
						)}
						{filePreview && (
							<Box sx={{ mt: 2, width: "100%", height: "150px" }}>
								<img
									src={filePreview}
									alt='Preview'
									style={{ width: "100%", borderRadius: "10px", height: "100%", zIndex: 1000, objectFit: "contain" }}
								/>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 0.5, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Code Snippet (optional)
						</Typography>
						<Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									mb: 1,
									gap: 1,
								}}
							>
								<FormControl
									fullWidth
									margin='normal'
								>
									<Select
										labelId='language-select-label'
										value={selectedLanguage}
										onChange={(e, newValue) => setSelectedLanguage(newValue)}
										label='Language'
									>
										<Option value='javascript'>JavaScript</Option>
										<Option value='python'>Python</Option>
										<Option value='java'>Java</Option>
										<Option value='mysql'>MySQL</Option>
										<Option value='bash'>Bash</Option>
										<Option value='kotlin'>Kotlin</Option>
										<Option value='typescript'>TypeScript</Option>
										<Option value='nodejs'>NodeJs</Option>
										<Option value='mongodb'>MongoDB</Option>
										<Option value='php'>PHP</Option>
										<Option value='html/css'>HTML/CSS</Option>
										<Option value='others'>Others</Option>
									</Select>
								</FormControl>

								<Button
									onClick={handleCopyCode}
									variant='outlined'
									disabled={!code}
									sx={{ mr: 2 }}
								>
									{codeCopied ? "Copied!" : "Copy"}
								</Button>
							</Box>
							<Box
								sx={{
									height: "250px",
								}}
							>
								<CodeHighlighter
									code={code}
									height={"250px"}
									language={selectedLanguage}
									onCodeChange={setCode}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
						mt: 2,
						gap: 2,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => {
							setSteps(2);
						}}
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
					>
						Previous: MCQ Task
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							disabled={
								!question ||
								!explanation ||
								!taskTitle ||
								options.length === 0 ||
								loading ||
								!options.some((option) => option.correct === true) ||
								(question?.trim() === task?.question?.trim() && // Check if the question hasn't changed
									selectedPoint === task?.points &&
									code === task?.code_snippet &&
									taskTitle?.trim() === task?.task_title?.trim() && // Check if the task title hasn't changed
									explanation === task?.explanation && // Check if the explanation hasn't changed
									// Check if the image has changed
									(imageUrl === task?.image_url || (!imageUrl && !task?.image_url)) &&
									// Check if a new file is not uploaded
									(!fileData || (imageUrl === task?.image_url && !fileData)) && // Disable if no new file and image is unchanged
									JSON.stringify(options) === JSON.stringify(task?.mcq_options)) // Check if the options haven't changed
							}
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							onClick={handleSubmit}
							loading={loading}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderInfoView = () => {
		return (
			<Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						padding: "1rem",
						maxHeight: "70vh",
						overflowY: "auto",
					}}
				>
					{/* <Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Task Title
					</Typography>
					<Box sx={{ borderRadius: "8px", mb: 1 }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700, px: 2 }}>{task?.task_title}</Typography>
					</Box> */}
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Question
					</Typography>
					<Box sx={{ borderRadius: "8px", mb: 1 }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700, px: 2 }}>{task?.question}</Typography>
					</Box>

					{task?.image_url && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
							<img
								src={task.image_url}
								alt='Preview'
								style={{ width: "100%", borderRadius: "10px", height: "100%", zIndex: 1000, objectFit: "contain" }}
							/>
						</Box>
					)}
					{task?.code_snippet && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
							<CodeHighlighter
								code={task?.code_snippet}
								height={"200px"}
							/>
						</Box>
					)}

					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Options
					</Typography>
					<Box sx={{ borderRadius: "8px" }}>
						{options.map((option, index) => {
							return (
								<Box
									key={index}
									sx={{
										mb: 0.5,
									}}
								>
									<Typography
										sx={{
											borderRadius: "5px",
											fontWeight: 700,
											color: option.correct ? "#42B25B" : "#050519",

											width: "fit-content",
											px: 2,
											fontSize: "1rem",
										}}
									>
										{optionKey[index + 1]}. &nbsp; {option.text}
									</Typography>
								</Box>
							);
						})}
					</Box>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Explanation
					</Typography>
					<Box>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700, px: 2 }}>{task?.explanation}</Typography>
					</Box>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Points
					</Typography>
					<Box sx={{ borderRadius: "8px" }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700, px: 2 }}>{task?.points}</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						width: "100%",
						mt: 2,
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							onClick={() => setSteps(2)}
						>
							Next: Edit Task
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderEditFormStep = () => {
		switch (steps) {
			case 1:
				return renderInfoView();
			case 2:
				return renderEditForm();
			case 3:
				return renderEditMCQImage();
			default:
				return;
		}
	};

	return <Box>{renderEditFormStep()}</Box>;
};

export default MCQEditForm;
