import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateStudent, addStudent, deleteStudent } from "../services/studentApiService";

export const updateStudentThunk = createAsyncThunk("student/updateStudent", async ({ studentId, studentData }, { rejectWithValue }) => {
	try {
		const response = await updateStudent(studentId, studentData);
		return response;
	} catch (error) {
		return rejectWithValue(error?.response?.data?.message || "An error occurred while updating student.");
	}
});

export const addStudentThunk = createAsyncThunk("student/addStudent", async ({ studentInfo, batch_id }, { rejectWithValue }) => {
	try {
		const response = await addStudent(studentInfo, batch_id);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while adding student.");
	}
});


export const deleteStudentThunk = createAsyncThunk("student/deleteStudent", async ({ student_id, batchId }, { rejectWithValue }) => {
	try {
		const response = await deleteStudent(student_id, batchId);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while adding student.");
	}
});