import { Snackbar, Alert } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../Store/slices/commonSlice";
import { ToastType } from "../../Constants/toast-type";

const Toast = () => {
	const dispatch = useDispatch();
	const { toastMessage, toastType, toastOpen, toastDuration } = useSelector((state) => state.common);

	const handleClose = () => {
		dispatch(hideToast());
	};
	return (
		<Snackbar
			open={toastOpen}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			autoHideDuration={toastDuration || 2000}
			onClose={handleClose}
		>
			<Alert
				onClose={handleClose}
				severity={toastType}
				variant='filled'
				sx={{ width: "100%" }}
			>
				{toastType === ToastType.ERROR ? toastMessage || "Something went wrong" : toastMessage}
			</Alert>
		</Snackbar>
	);
};

export default Toast;
