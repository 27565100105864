import React, { useState } from "react";
import { IconButton, Box, Input, RadioGroup, FormControlLabel, Radio, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { EditEventForBatchThunk } from "../../../Store/actions/batchEventAction";
import { DateTime } from "luxon";
import { updateEvent } from "../../../Store/slices/batchEventSlice";
import CloseIcon from '@mui/icons-material/Close';


const EventEditForm = ({ event, setShowEventEditModal }) => {

	const startDateTime = DateTime.fromISO(event.start_time);
	const endDateTime = DateTime.fromISO(event.end_time);
	const [eventType, setEventType] = useState(event.batch_event_type);
	const [eventLink, setEventLink] = useState(event.meeting_url);
	const [eventName, setEventName] = useState(event.name);
	const [instructorName, setInstructorName] = useState(event.instructor_name);
	const [startDate, setStartDate] = useState(startDateTime.toFormat('yyyy-MM-dd'));
	const [endDate, setEndDate] = useState(endDateTime.toFormat('yyyy-MM-dd'));
	const [startTime, setStartTime] = useState(startDateTime.toFormat('HH:mm'));
	const [endTime, setEndTime] = useState(endDateTime.toFormat('HH:mm'));
	const [imageFile, setImageFile] = useState(event.event_image);
	const [batch_id, setbatch_id] = useState(event.batch_id);
    const [eventId, setEventId] = useState(event.event_id);
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	const getMeetingNameFromLink = (link) => {
		return link.includes("zoom") ? "ZOOM" : link.includes("meet.google") ? "GOOGLE_MEET" : link.includes("teams.microsoft") ? "MICROSOFT_TEAMS" : "OTHER";
	};

	const toBase64 = async (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	// Helper function to check if a value is empty
	const isEmpty = (value) => !value || value.trim() === "";

	const handleStartDate = (e) => {
		const inputDate = e.target.value;
		const currentDate = DateTime.now().toFormat("yyyy-MM-dd");

		// Check if the entered date is less than today's date
		if (inputDate && inputDate < currentDate) {
			alert("You cannot select a past date."); // Notify the user

			e.target.value = currentDate;
			setStartDate(currentDate); // Reset state to today's date
		} else {
			setStartDate(inputDate); // Update the start date if valid
		}
	};

	const handleStartTime = (e) => {
		const inputTime = e.target.value;
		const currentDateTime = DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm");
	
		// Check if the entered time is less than the current time on the selected start date
		if (startDate && inputTime && `${startDate}T${inputTime}` < currentDateTime) {
			alert("You cannot select a past time."); // Notify the user
			e.target.value = ""; // Reset the input
			setStartTime(startDateTime.toFormat('HH:mm')); // Reset state
		} else {
			setStartTime(inputTime); // Update the start time if valid
		}
	};
	
	const handleEndTime = (e) => {
		const inputTime = e.target.value;
	
		// Check if the entered end time is less than the start time on the same date
		if (startDate && startTime && inputTime) {
			if (endDate === startDate) {
				if (`${endDate}T${inputTime}` < `${startDate}T${startTime}`) {
					alert("End time cannot be before start time on the same date."); // Notify the user
					e.target.value = ""; // Reset the input
					setEndTime(endDateTime.toFormat('HH:mm')); // Reset state
				} else {
					setEndTime(inputTime); // Update the end time if valid
				}
			} else {
				// Check if the entered end date and time is less than the start date and time
				if (`${endDate}T${inputTime}` < `${startDate}T${startTime}`) {
					alert("End date and time cannot be before start date and time."); // Notify the user
					e.target.value = ""; // Reset the input
					setEndTime(""); // Reset state
				} else {
					setEndTime(inputTime); // Update the end time if valid
				}
			}
		} else {
			setEndTime(inputTime); // Update the end time if valid
		}
	};

	const handleEndDate = (e) => {
		const inputDate = e.target.value;

		// Check if the entered date is less than today's date
		if (inputDate && inputDate < startDate) {
			alert("You cannot select a past date."); // Notify the user

			e.target.value = startDate;
			setEndDate(startDate); // Reset state to today's date
		} else if (inputDate) {
			// If endTime is present, check the combined date and time
			if (endTime) {
				const endDateTimeInput = `${inputDate}T${endTime}`;
				const startDateTimeInput = `${startDate}T${startTime}`;
	
				// Ensure end date and time is not less than start date and time
				if (endDateTimeInput < startDateTimeInput) {
					alert("End date and time cannot be before start date and time."); // Notify the user
					e.target.value = ""; // Reset the input
					setEndDate(endDateTime.toFormat('yyyy-MM-dd')); // Reset state
					setEndTime(endDateTime.toFormat('HH:mm')); // Reset state
				} else {
					setEndDate(inputDate); // Update the end date if valid
				}
			} else {
				setEndDate(inputDate); // Update the end date if valid
			}
		}
	};

	const handleImageDelete = () => {
		setImageFile(null); // Reset the image file state
	};

	const handleSubmit = async () => {
		if (!eventId || !eventName || !eventLink || !instructorName || !startDate || !endDate) return;
		setIsLoading(true);
		let image;
		const start_date = DateTime.fromISO(`${startDate}T${startTime}`).toUTC().toISO();
		const end_date = DateTime.fromISO(`${endDate}T${endTime}`).toUTC().toISO();
		const meetingType = getMeetingNameFromLink(eventLink);
		let eventData;
		if (imageFile) {
			eventData = new FormData();
			eventData.append("event-image", imageFile);
            eventData.append("event_id", eventId);
			eventData.append("name", eventName);
			eventData.append("meeting_url", eventLink);
			eventData.append("instructor_name", instructorName);
			eventData.append("start_time", start_date);
			eventData.append("end_time", end_date);
			eventData.append("meeting_type", meetingType);
			eventData.append("batch_event_type", eventType);
			eventData.append("batch_id", batch_id);
		} else {
			eventData = {
                event_id:eventId,
				name: eventName,
				meeting_url: eventLink,
				instructor_name: instructorName,
				start_time: start_date,
				end_time: end_date,
				meeting_type: meetingType,
				batch_event_type: eventType,
				batch_id,
			};
		}
		const response = await dispatch(EditEventForBatchThunk(eventData));
		setIsLoading(false); 

		if (response?.payload?.success) {
			dispatch(
				updateEvent({
                    event_id:eventId,
					name: eventName,
					meeting_url: eventLink,
					instructor_name: instructorName,
					start_time: start_date,
					end_time: end_date,
					meeting_type: meetingType,
					batch_event_type: eventType,
					batch_id,
					event_image: response?.payload?.data[0]?.event_image,
					recording_url: response?.payload?.data[0]?.recording_url,
				})
			);
			setShowEventEditModal("");
		}
	};

	return (
		<Box>
			<Box sx={{ px: 2, maxHeight: "70vh", overflowY: "auto" }}>
				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Add Event Name
				</Typography>

				<Input
					fullWidth
					label='Event Name'
					placeholder='Clarification Call'
					onChange={(e) => setEventName(e.target.value)}
					variant='outlined'
					value={eventName}
					sx={{ mb: 2 }}
					required
					// error={eventName?.trim() === "" ? "Please enter the event name" : ""}
				/>

				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Event Type
				</Typography>
				<RadioGroup row>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1, mb: 2 }}>
						<Box sx={{ width: "100%" }}>
							<FormControlLabel
								value={eventType}
								control={<Radio />}
								disabled={true}
								label={
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											padding: 2,
											border: "1px solid",
											borderColor: "divider",
											borderRadius: 1, // Adjust width as needed
											textAlign: "center",
											"&:hover": {
												backgroundColor: "action.hover",
											},
										}}
									>
										<Typography variant='body1'>Offline</Typography>
										<Typography
											variant='caption'
											sx={{ width: "fit-content" }}
										>
											For events that are typically conducted at a location and in-person.
										</Typography>
									</Box>
								}
							/>
						</Box>
						<Box sx={{ width: "100%" }}>
							<FormControlLabel
								value={eventType}
								control={<Radio />}
								checked={true}
								label={
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											padding: 2,
											border: "1px solid",
											borderColor: "divider",
											borderRadius: 1,
											"&.Mui-checked": {
												backgroundColor: "action.selected",
											},
											textAlign: "center",
											"&:hover": {
												backgroundColor: "action.hover",
											},
										}}
									>
										<Typography variant='body1'>Virtual</Typography>
										<Typography
											variant='caption'
											sx={{ width: "fit-content" }}
										>
											For events or sessions that are conducted online and require meet/call link.
										</Typography>
									</Box>
								}
							/>
						</Box>
					</Box>
				</RadioGroup>
				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Meeting link
				</Typography>
				<Input
					fullWidth
					label='Meet/Call Link'
					placeholder='https://zoom.us/234q3562w6'
					variant='outlined'
					onChange={(e) => setEventLink(e.target.value)}
					value={eventLink}
					sx={{ mb: 2 }}
					required
					// error={eventLink?.trim() === "" ? "Please enter the meeting link" : ""}
				/>
				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Instructor Name
				</Typography>

				<Input
					fullWidth
					label='Instructor Name'
					placeholder='Prof. Sushant Sinha'
					variant='outlined'
					onChange={(e) => setInstructorName(e.target.value)}
					value={instructorName}
					sx={{ mb: 2 }}
					required
					// error={instructorName?.trim() === "" ? "Please enter the instructor name" : ""}
				/>

				<Box sx={{ display: "flex", gap: 2, mb: 2 }}>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							Start Date
						</Typography>
						<Input
							fullWidth
							type='date'
							label='Start Date'
							placeholder='10 Jun 2024'
							variant='outlined'
							onChange={(e) => setStartDate(e.target.value)}
							value={startDate}
							slotProps={{
								input: {
									min: DateTime.now().toFormat("yyyy-MM-dd"),
								},
							}}
							onBlur={handleStartDate}
							required
							// error={startDate?.trim() === "" ? "Please enter the start date" : ""}
						/>
					</Box>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							Time
						</Typography>
						<Input
							type='time'
							fullWidth
							label='Time'
							placeholder='Choose'
							variant='outlined'
							onChange={(e) => setStartTime(e.target.value)}
							value={startTime}
							onBlur={handleStartTime}
							required
							// error={startTime?.trim() === "" ? "Please enter the start time" : ""}
						/>
					</Box>
				</Box>

				<Box sx={{ display: "flex", gap: 2 }}>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							End Date
						</Typography>
						<Input
							fullWidth
							type='date'
							label='Start Date'
							placeholder='10 Jun 2024'
							variant='outlined'
							slotProps={{
								input: {
									min: startDate,
								},
							}}
							onChange={(e) => setEndDate(e.target.value)}
							value={endDate}
							onBlur={handleEndDate}
							required
							// error={endDate?.trim() === "" ? "Please enter the end date" : ""}
						/>
					</Box>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							Time
						</Typography>
						<Input
							type='time'
							fullWidth
							label='Time'
							placeholder='Choose'
							variant='outlined'
							onChange={(e) => setEndTime(e.target.value)}
							value={endTime}
							onBlur={handleEndTime}
							required
							// error={endTime?.trim() === "" ? "Please enter the end time" : ""}
						/>
					</Box>
				</Box>
				<Box sx={{ my: 1 }}>
					{imageFile && (
						<Box sx={{ mb: 2 }}>
							<Typography variant="body1">Current Image:</Typography>

							{/* Image container with relative positioning for the close icon */}
							<Box sx={{ position: 'relative', display: 'inline-block' }}>
								<img
									src={typeof imageFile === 'string' ? imageFile : URL.createObjectURL(imageFile)}
									alt="Event"
									style={{ width: '100%', maxWidth: '360px', height: 'auto', display: 'block' }} // Set a max width if needed
								/>

								{/* "X" button positioned on top-right of the image */}
								<IconButton
									onClick={() => handleImageDelete()}
									sx={{
										position: 'absolute',
										top: '4px',
										right: '4px',
										backgroundColor: 'rgba(255, 255, 255, 0.7)',
										'&:hover': {
											backgroundColor: 'rgba(255, 255, 255, 1)',
										},
										padding: '2px', // Smaller padding for a compact icon
									}}
									size="small"
								>
									<CloseIcon fontSize="small" />
								</IconButton>
							</Box>
						</Box>
					)}

					{!imageFile && (
						<Input
							type='file'
							slotProps={{
								input: {
									accept: "image/*",
								},
							}}
							onChange={(e) => setImageFile(e.target.files[0])}
						/>
					)}
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
				<Button
					variant='outlined'
					sx={{ mr: 2 }}
					onClick={() => setShowEventEditModal("")}
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='primary'
					disabled={isLoading || !eventName || !eventLink || !instructorName || !startDate || !endDate || !startTime || !endTime}
					onClick={handleSubmit}
				>
					{isLoading ? "Updating..." : "Update Event"}
				</Button>
			</Box>
		</Box>
	);
};

export default EventEditForm;
