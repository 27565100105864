import React, { useEffect, useState } from "react";
// import coursesList from '../../CoursesList'
import { useLocation, useNavigate } from "react-router-dom";
import "./Course.css";
import "../../Common/Common.css";
import { Box, Button, Input, Modal, ModalClose, Sheet, Tab, TabList, Tabs, Typography } from "@mui/joy";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AccordionUtils from "../utils/Accordion";
import { addModuleThunk, fetchCourseDetailsThunk } from "../../Store/actions/courseAction";
import { showToast } from "../../Store/slices/commonSlice";
import { ToastType } from "../../Constants/toast-type";
import { checkStatusThunk, fetchVideoSummaryThunk } from "../../Store/actions/videoAction";
import WithAuth from "../utils/WithAuth";
import { addModuleData } from "../../Store/slices/courseSlice";
import Loader from "../utils/Loader";
import CourseInfo from "./course-components/course-details";
import CourseContent from "./course-components/course-content";

const Course = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState("course_details");

	const courseId = location?.pathname?.split("/")[2];

	const { courseData, error, loading, statusLoader } = useSelector((state) => state.course);

	useEffect(() => {
		if (courseId !== undefined) {
			dispatch(fetchCourseDetailsThunk({ courseId }));
		}
	}, [courseId]);

	if (loading) {
		return (
			<div style={{}}>
				<Loader />
			</div>
		);
	}

	if (error["fetchCourseDetails"]) {
		dispatch(showToast({ toastMessage: error["fetchCourseDetails"], toastType: ToastType.ERROR }));
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const renderTabsInfo = (activeTab) => {
		switch (activeTab) {
			case "course_details":
				return (
					<Box>
						<CourseInfo />
					</Box>
				);
			case "main_content":
				return (
					<Box>
						<CourseContent />
					</Box>
				);
			default:
				return (
					<Box>
						<CourseInfo />
					</Box>
				);
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 1200, margin: "auto", p: 2 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
				<Typography level='h4'>{courseData?.data?.name}</Typography>
			</Box>
			<Tabs
				value={activeTab}
				onChange={handleTabChange}
				sx={{
					borderRadius: "8px",
					width: "fit-content",
				}}
			>
				<TabList>
					<Tab value='course_details'>Course Details</Tab>
					<Tab value='main_content'>Main Content</Tab>
				</TabList>
			</Tabs>
			<Box
				sx={{
					p: 2,
					border: "1px solid",
					borderColor: "divider",
					maxHeight: "55vh",
					borderRadius: "sm",
					background: "white",
				}}
			>
				{renderTabsInfo(activeTab)}
			</Box>
		</Box>
	);
};

export default WithAuth(Course);
