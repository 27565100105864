import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Button, Checkbox, Typography } from "@mui/joy";
import ReactSelect from "../utils/search-select";
import { clearFilters, setFilters } from "../../Store/slices/courseSlice";
import { UserRole } from "../../Constants/user-role";

const FilterOptions = ({ handleFilter }) => {
	const dispatch = useDispatch();
	const { tenants } = useSelector((state) => state.batch);
	const { filters } = useSelector((state) => state.course);
	const { user } = useSelector((state) => state.user);

	const [selectedTenantId, setSelectedTenantId] = useState(filters?.tenant_id || null);
	const [triggerFilter, setTriggerFilter] = useState(false);

	// Synchronize local state with Redux
	useEffect(() => {
		setSelectedTenantId(filters?.tenant_id || null);
	}, [filters]);

	useEffect(() => {
		if (triggerFilter) {
			handleFilter();
			setTriggerFilter(false);
		}
	}, [triggerFilter]);

	// Generate dropdown options
	const generateOptions = (data, value, label) => data?.map((item) => ({ value: item[value], label: item[label] })) || [];


	// Update filters and state when tenant changes
	const handleTenantChange = (tenantId) => {
		setSelectedTenantId(tenantId);
		if (tenantId !== null) {
			dispatch(setFilters({ tenant_id: tenantId }));
		} else {
			dispatch(setFilters({ tenant_id: null }));
		}
	};

	// Reset all filters
	const handleResetFilters = () => {
		setSelectedTenantId(null);
		dispatch(clearFilters());
		setTimeout(() => {
			setTriggerFilter(true); // Trigger the filter call immediately after reset
		}, 0);
	};

	// Add filters
	const handleAddFilter = () => {
		handleFilter(); // Trigger the filter call
	};

	return (
		<Box sx={{ height: "450px", width: "300px", display: "flex", flexDirection: "column" }}>
			<Box sx={{ flex: 1, overflowY: "auto", gap: 2, p: ".5rem 1rem" }}>
				{/* Tenants Filter */}
				{user?.role === UserRole.ADMIN && (
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
						>
							TENANTS
						</Typography>
						<ReactSelect
							options={generateOptions(tenants?.data, "tenant_id", "name")}
							isSearchable
							onChange={handleTenantChange}
							placeholder='All Tenants'
							selectedOption={selectedTenantId ? generateOptions(tenants?.data, "tenant_id", "name").find((option) => option.value === selectedTenantId) : null}
						/>
					</Box>
				)}				
			</Box>

			{/* Actions */}
			<Box sx={{ p: 1, borderTop: "1px solid rgba(0, 0, 0, 0.30)" }}>
				<Button
					sx={{ width: "100%", mb: 2, bgcolor: "#fff", color: "#d55442", ":hover": { bgcolor: "#f4f4f4" } }}
					onClick={handleResetFilters}
					disabled={!selectedTenantId}
				>
					Reset All Filters
				</Button>
				<Button
					sx={{ width: "100%", bgcolor: "#3838f1", color: "#fff" }}
					onClick={handleAddFilter}
				>
					Filter
				</Button>
			</Box>
		</Box>
	);
};

export default FilterOptions;
