import { Box, Button, Card, IconButton, Input, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import { ReactComponent as Github } from "../../../../Assets/github.svg";
import { ExternalTaskTypes, URLTaskTypes } from "../../../../Constants/task-types";
import { updateTaskThunk } from "../../../../Store/actions/taskAction";
import { updateTaskData } from "../../../../Store/slices/taskDetailsSlice";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";

const ExternalEditForm = ({ setOpen, task }) => {
	const [selectedPoint, setSelectedPoint] = useState(task?.points);
	const [taskTitle, setTaskTitle] = useState(task?.task_title);
	const [docUrls, setDocUrls] = useState(task?.doc_urls || []);
	const [isEditing, setIsEditing] = useState(Array(docUrls.length).fill(false));
	const [files, setFiles] = useState([]);
	const [webUrl, setWebUrl] = useState(task?.allowed_urls[0] || "");
	const [externalTaskType, setExternalTaskType] = useState(task?.task_type);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [objective, setObjective] = useState(task?.question);

	const location = useLocation();
	const dispatch = useDispatch();
	const node_id = location.pathname.split("/")[4];

	const handleFileChange = (e, index) => {
		const newDocUrls = [...docUrls];
		newDocUrls[index] = e.target.files[0].name; // Or store the file itself if needed
		setFiles([...files, e.target.files[0]]);
		setDocUrls(newDocUrls);
		toggleEdit(index); // Close the file input after selection
	};

	const toggleEdit = (index) => {
		const newIsEditing = [...isEditing];
		newIsEditing[index] = !newIsEditing[index];
		setIsEditing(newIsEditing);
	};

	// Remove document from the list and show file input
	const handleFileRemove = (index) => {
		const newIsEditing = [...isEditing];
		newIsEditing[index] = true; // Open file input on remove
		setIsEditing(newIsEditing);
		setDocUrls((prev) => prev.map((file, i) => (i === index ? "" : file)));
	};

	const handleEdit = async () => {
		if (!taskTitle || taskTitle.trim() === "") {
			dispatch(showToast({ toastMessage: "Task title is required", toastType: ToastType.ERROR }));
			return;
		}
		if (files.length > 0) {
			const formData = new FormData();
			formData.append("task_title", taskTitle);
			formData.append("question", objective);
			formData.append("points", selectedPoint);
			files.forEach((file) => {
				formData.append("docs", file);
			});
			formData.append("node_id", node_id);
			formData.append("task_id", task.task_id);
			docUrls.forEach((docUrl) => {
				formData.append("new_docs[]", docUrl); // Append each URL individually
			});
			task?.doc_urls?.length > 0
				? task?.doc_urls.forEach((docUrl) => {
						formData.append("doc_urls[]", docUrl); // Append each existing URL individually
				  })
				: formData.append("doc_urls", []);
			formData.append("allowed_urls", externalTaskType === ExternalTaskTypes.DOC_LINK ? [webUrl] : [""]);
			formData.append("task_type", externalTaskType);
			setLoading(true);

			const response = await dispatch(updateTaskThunk(formData));
			if (response?.payload?.data) {
				dispatch(updateTaskData(response.payload.data));
				setLoading(false);
				setOpen(false);
			}
		} else if (docUrls != task.doc_urls || selectedPoint != task.points || taskTitle != task.question || externalTaskType !== task.task_type || webUrl !== task.allowed_urls[0]) {
			const updatedTask = {
				task_id: task.task_id,
				node_id: node_id,
				question: objective,
				task_title: taskTitle,
				points: selectedPoint,
				new_docs: docUrls,
				doc_urls: task.doc_urls,
				task_type: externalTaskType,
				allowed_urls: externalTaskType === ExternalTaskTypes.DOC_LINK ? [webUrl] : [],
			};
			setLoading(true);

			const response = await dispatch(updateTaskThunk(updatedTask));

			if (response?.payload?.data) {
				dispatch(updateTaskData(response.payload.data));
				setLoading(false);
				setOpen(false);
			}
		}
	};

	const renderSubmissionType = () => {
		return (
			<>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Submission Type
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 2,
						}}
					>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${externalTaskType === ExternalTaskTypes.DOC_UPLOAD ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${externalTaskType === ExternalTaskTypes.DOC_UPLOAD ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setExternalTaskType(ExternalTaskTypes.DOC_UPLOAD)}
						>
							<Typography
								sx={{
									color: `${externalTaskType === ExternalTaskTypes.DOC_UPLOAD ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								File Upload
							</Typography>

							<Typography
								sx={{
									fontSize: "12px",
								}}
							>
								Choose if Students must upload PDFs or JPGs or PNGs (max 10MB total).
							</Typography>
						</Card>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${externalTaskType === ExternalTaskTypes.DOC_LINK ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${externalTaskType === ExternalTaskTypes.DOC_LINK ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setExternalTaskType(ExternalTaskTypes.DOC_LINK)}
						>
							<Typography
								sx={{
									color: `${externalTaskType === ExternalTaskTypes.DOC_LINK ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								Web URL
							</Typography>

							<Typography
								sx={{
									fontSize: "12px",
								}}
							>
								Choose if Students must submit a Web URL of their completed Project.
							</Typography>
						</Card>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${externalTaskType === ExternalTaskTypes.TROVEN ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${externalTaskType === ExternalTaskTypes.TROVEN ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setExternalTaskType(ExternalTaskTypes.TROVEN)}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
									height: "100%",
								}}
							>
								<img
									src='/troven.png'
									style={{
										width: "100%",
										height: "100%",
										objectFit: "contain",
									}}
								/>
							</Box>
						</Card>
					</Box>
				</Box>
				{externalTaskType === ExternalTaskTypes.DOC_LINK && (
					<Box
						sx={{
							my: 2,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Type of Web URL
						</Typography>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								alignItems: "center",
							}}
						>
							<Card
								sx={{
									cursor: "pointer",
									border: `${webUrl === URLTaskTypes.GITHUB ? "2px solid #3838F1" : "none"}`,
									bgcolor: `${webUrl === URLTaskTypes.GITHUB ? "#F5f5ff" : "#f4f4f4"}`,
								}}
								onClick={() => setWebUrl(URLTaskTypes.GITHUB)}
							>
								<Github />
							</Card>
							<Card
								sx={{
									cursor: "pointer",
									border: `${webUrl === URLTaskTypes.JUPYTER ? "2px solid #3838F1" : "none"}`,
									bgcolor: `${webUrl === URLTaskTypes.JUPYTER ? "#F5f5ff" : "#f4f4f4"}`,
								}}
								onClick={() => setWebUrl(URLTaskTypes.JUPYTER)}
							>
								<Typography level='h4'>Jupyter Notebook</Typography>
							</Card>
							<Card
								sx={{
									cursor: "pointer",
									border: `${webUrl === "" ? "2px solid #3838F1" : "none"}`,
									bgcolor: `${webUrl === "" ? "#F5f5ff" : "#f4f4f4"}`,
								}}
								onClick={() => setWebUrl("")}
							>
								<Typography level='h4'>Any URL</Typography>
							</Card>
						</Box>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Button
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						variant='outlined'
						onClick={() => {
							setStep(1);
						}}
					>
						Previous: Task Details
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							disabled={taskTitle === "" || objective === ""}
							onClick={handleEdit}
							loading={loading}
						>
							Save
						</Button>
					</Box>
				</Box>
			</>
		);
	};

	const renderEditForm = () => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							maxHeight: "40vh",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							gap: 2,
							p: 2,
						}}
					>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Title
							</Typography>
							<Input
								label='Task title'
								type='text'
								slotProps={{
									input: {
										maxLength: 64,
									},
								}}
								value={taskTitle}
								onChange={(e) => setTaskTitle(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. Binary Search'
								required
								error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
							/>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Description
							</Typography>
							<Textarea
								label='Objective'
								onChange={(e) => setObjective(e.target.value)}
								minRows={5}
								maxRows={10}
								value={objective}
								type='text'
								slotProps={{
									textarea: {
										maxLength: 1024,
									},
								}}
								sx={{ width: "100%" }}
								placeholder='e.g. Compiler - Assembler Differences'
							/>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Documents
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: 1,
								}}
							>
								{docUrls.map((file, index) => (
									<Box
										key={index}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											padding: "0.5rem 1rem",
											bgcolor: "#f4f4f4",
											borderRadius: "sm",
											gap: 1,
										}}
									>
										{isEditing[index] ? (
											// File input when editing
											<Box
												sx={{
													alignItems: "center",
													backgroundColor: "white",
													borderRadius: "md",
													p: 0.5,
													boxShadow: 1,
													width: "100%",
													border: "1px solid #f4f4f4",
												}}
											>
												<IconButton
													component='label'
													color='primary'
													sx={{
														borderRadius: "md",
														width: "100%",
													}}
												>
													<Input
														type='file'
														slotProps={{
															input: {
																accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
															},
														}}
														sx={{ display: "none" }}
														onChange={(e) => handleFileChange(e, index)}
													/>
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															gap: 1,
															justifyContent: "space-between",
															width: "100%",
															padding: " 0 1rem",
														}}
													>
														<UploadIcon />
														<Typography>Upload</Typography>
													</Box>
												</IconButton>
											</Box>
										) : (
											<Link
												to={file}
												target='_blank'
												style={{ textDecoration: "none" }}
											>
												<Typography sx={{ color: "#3838f1", fontWeight: "600" }}>Reading Task {index + 1}</Typography>
												<Typography
													sx={{
														width: "400px",
													}}
													noWrap
												>
													{file}
												</Typography>
											</Link>
										)}
										<Box sx={{ display: "flex", gap: 1 }}>
											<Button
												variant='outlined'
												color='neutral'
												onClick={(e) => {
													e.stopPropagation();
													handleFileRemove(index);
												}}
											>
												Remove
											</Button>
										</Box>
									</Box>
								))}
								{docUrls.length < 4 && (
									<Box
										sx={{
											alignItems: "center",
											backgroundColor: "white",
											borderRadius: "md",
											p: 0.5,
											boxShadow: 1,
											width: "100%",
											border: "1px solid #f4f4f4",
										}}
									>
										<IconButton
											component='label'
											color='primary'
											sx={{
												borderRadius: "md",
												width: "100%",
											}}
										>
											<Input
												type='file'
												slotProps={{
													input: {
														accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
													},
												}}
												sx={{ display: "none" }}
												onChange={(e) => {
													setFiles([...files, e.target.files[0]]);
													setDocUrls([...docUrls, e.target.files[0].name]);
												}}
											/>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													gap: 1,
													justifyContent: "space-between",
													width: "100%",
													padding: " 0 1rem",
												}}
											>
												<UploadIcon />
												<Typography>Upload Document</Typography>
											</Box>
										</IconButton>
									</Box>
								)}
							</Box>
						</Box>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Points
						</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
									sx={{
										bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
										color: `${selectedPoint === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${selectedPoint === point ? "white" : "black"}`,
										},
									}}
									onClick={() => setSelectedPoint(point)}
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						alignSelf: "flex-end",
						gap: 2,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => {
							setOpen(false);
						}}
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
					>
						Cancel
					</Button>
					<Button
						sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
						color='primary'
						disabled={taskTitle === "" || objective === ""}
						onClick={() => {
							setStep(2);
						}}
					>
						Next: Submission Details
					</Button>
				</Box>
			</Box>
		);
	};
	const renderSteps = () => {
		switch (step) {
			case 1:
				return renderEditForm();
			case 2:
				return renderSubmissionType();

			default:
				return renderEditForm();
		}
	};

	return renderSteps();
};

export default ExternalEditForm;
