import React, { useEffect, useState } from "react";
import { fetchCourseDetailsForImportThunk, fetchCourseDetailsThunk } from "../../../../Store/actions/courseAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCoursesByTenantIdThunk } from "../../../../Store/actions/tenantAction";
import { Box, Button, Checkbox, Typography } from "@mui/joy";
import ReactSelect from "../../../utils/search-select";
import { getAllTasksByNodeIdThunk, getTasksByNodeIdThunk, importTasksThunk } from "../../../../Store/actions/taskAction";
import { Coding_Task_Icon, Event_Task_Icon, MCQ_Task_Icon, Reading_Task_Icon, Videos_Task_Icon } from "../../IconsPath";
import { ExternalTaskTypes } from "../../../../Constants/task-types";
import { clearImportTasks } from "../../../../Store/slices/taskDetailsSlice";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";

const taskTypeIcons = {
	VID: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 14 14'
			fill='none'
		>
			<path
				d={Videos_Task_Icon}
				fill='#6E6E6F'
			/>
		</svg>
	),
	CODE: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 13 13'
			fill='none'
		>
			<path
				d={Coding_Task_Icon}
				fill='#6E6E6F'
			/>
		</svg>
	),
	DOC: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 12 12'
			fill='none'
		>
			<path
				d={Reading_Task_Icon}
				fill='#6E6E6F'
			/>
		</svg>
	),
	MCQ: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 14 15'
			fill='none'
			className='mb-[2px]'
		>
			<path
				d={MCQ_Task_Icon}
				fill='#6E6E6F'
			/>
		</svg>
	),
	DEFAULT: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 14 12'
			fill='none'
		>
			<path
				d={Event_Task_Icon}
				fill='#6E6E6F'
			/>
		</svg>
	),
};

const ImportTask = ({ node_id, currentCourseData, setDropdownOpen }) => {
	const dispatch = useDispatch();

	const { courses } = useSelector((state) => state.batch);
	const { courseImportData } = useSelector((state) => state.course);
	const { tasksByNodeId } = useSelector((state) => state.task);

	const [selectedCourseId, setSelectedCourseId] = useState(null);
	const [selectedModuleId, setSelectedModuleId] = useState(null);
	const [selectedTopicId, setSelectedTopicId] = useState(null);
	const [selectedTaskIds, setSelectedTaskIds] = useState([]);

	useEffect(() => {
		if (currentCourseData?.data?.tenant_id) {
			dispatch(getCoursesByTenantIdThunk(currentCourseData?.data?.tenant_id));
		}
	}, []);

	const handleCourseChange = (courseId) => {
		setSelectedCourseId(courseId);
		dispatch(fetchCourseDetailsForImportThunk({ courseId }));
	};

	const handleModuleChange = (moduleId) => {
		setSelectedModuleId(moduleId);
	};

	const handleTopicChange = (topicId) => {
		setSelectedTopicId(topicId);
		if (topicId) dispatch(getAllTasksByNodeIdThunk(topicId));
	};

	const generateOptions = (data, value, label) => data?.map((item) => ({ value: item[value], label: item[label] })) || [];

	const generateModuleOptions = (data, value, label) => {
		const moduleNodes = data?.data?.children?.map((item) => ({ value: item[value], label: item[label] })) || [];
		return moduleNodes;
	};

	const generateTopicOptions = (data, value, label) => {
		const topicNode =
			data?.data?.children
				?.find((item) => item.node_id === selectedModuleId)
				?.children?.map((item) => ({ value: item[value], label: item[label] }))
				.filter((item) => item.value !== parseInt(node_id)) || [];
		return topicNode;
	};

	const handleCheckboxChange = (taskId) => {
		setSelectedTaskIds((prevSelected) => {
			if (prevSelected.includes(taskId)) {
				// Remove the task ID from the selected list
				return prevSelected.filter((id) => id !== taskId);
			} else {
				// Add the task ID to the selected list
				return [...prevSelected, taskId];
			}
		});
	};

	const handleSelectAllChange = (isChecked) => {
		if (isChecked) {
			const allTaskIds = tasksByNodeId?.data?.map((task) => task.task_id) || [];
			setSelectedTaskIds(allTaskIds); // Select all tasks
		} else {
			setSelectedTaskIds([]); // Deselect all tasks
		}
	};

	const handleImportTasks = async () => {
		if (selectedTaskIds?.length === 0) {
			dispatch(showToast({ toastMessage: "Please select atleast one task to import", toastType: ToastType.ERROR }));
		}

		const response = await dispatch(importTasksThunk({ tasks: selectedTaskIds, nodeId: node_id }));
		if (response?.payload?.success) {
			dispatch(getTasksByNodeIdThunk(node_id));
			dispatch(showToast({ toastMessage: "Tasks imported successfully", toastType: ToastType.SUCCESS }));
			setDropdownOpen(false);
			dispatch(clearImportTasks());
		}
	};

	const renderTasks = () => {
		const allSelected = tasksByNodeId?.data?.length > 0 && selectedTaskIds.length === tasksByNodeId.data.length;

		return (
			tasksByNodeId?.data?.length > 0 && (
				<Box
					sx={{
						height: "30vh",
						overflowY: "auto",
						bgcolor: "#f4f4f4",
						p: 2,
						borderRadius: "8px",
					}}
				>
					{/* Select All Checkbox */}
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
							px: 2,
							mb: 2,
						}}
					>
						<Checkbox
							checked={allSelected}
							indeterminate={selectedTaskIds.length > 0 && selectedTaskIds.length < tasksByNodeId.data.length} // Show indeterminate state when some but not all are selected
							onChange={(e) => handleSelectAllChange(e.target.checked)}
						/>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: 600,
								color: "#050519",
							}}
						>
							Select All
						</Typography>
					</Box>

					{/* Task List */}
					{tasksByNodeId?.data?.map((task, index) => {
						const isFirst = index === 0;
						const isLast = index === tasksByNodeId.data.length - 1;
						return (
							<Box
								key={task?.id || index} // Use a unique key
								sx={{
									bgcolor: "white",
									p: 2,
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									borderRadius: isFirst
										? "8px 8px 0 0" // Top-left and top-right corners for the first element
										: isLast
										? "0 0 8px 8px" // Bottom-right and bottom-left corners for the last element
										: "0", // No rounded corners for other elements
									borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 3,
									}}
								>
									<Checkbox
										checked={selectedTaskIds.includes(task.task_id)}
										onChange={() => handleCheckboxChange(task.task_id)}
									/>
									<Box
										sx={{
											display: "flex",
											gap: 2,
											alignItems: "center",
										}}
									>
										{taskTypeIcons[task.task_type] || taskTypeIcons["DEFAULT"]}

										<Typography
											sx={{
												fontSize: "14px",
												fontWeight: 600,
												color: "#050519",
												maxWidth: 200,
											}}
											noWrap
										>
											{task?.task_title || task?.question}
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										display: "flex",
										gap: 2,
										alignItems: "center",
									}}
								>
									<Typography
										sx={{
											fontSize: "14px",
											color: "#6e6e6f",
										}}
									>
										{task.task_type ? (task.task_type === ExternalTaskTypes.DOC_UPLOAD || task.task_type === ExternalTaskTypes.DOC_LINK ? "EXTERNAL" : task.task_type) : "CODE"}
									</Typography>
									<Typography
										sx={{
											fontSize: "14px",
											fontWeight: 400,
											color: "#050519",
										}}
									>
										{task?.points}pts
									</Typography>
								</Box>
							</Box>
						);
					})}
				</Box>
			)
		);
	};

	return (
		<Box>
			<Box sx={{ display: "flex", flexDirection: "column", maxHeight: "77vh", overflowY: "auto" }}>
				<Box sx={{ flex: 1, overflowY: "auto", gap: 2, p: ".5rem", width: "full", display: "flex", flexDirection: "column" }}>
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
						>
							Course
						</Typography>
						<ReactSelect
							options={generateOptions(courses?.data, "course_id", "name")}
							isSearchable={true}
							onChange={handleCourseChange}
							placeholder='Choose an option'
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
						>
							Module
						</Typography>
						<ReactSelect
							isSearchable={true}
							options={generateModuleOptions(courseImportData, "node_id", "node_name")}
							onChange={handleModuleChange}
							placeholder='Choose an option'
							isDisabled={!selectedCourseId}
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
						>
							Topic
						</Typography>
						<ReactSelect
							options={generateTopicOptions(courseImportData, "node_id", "node_name")}
							isSearchable={true}
							onChange={handleTopicChange}
							placeholder='Choose an option'
							isDisabled={!selectedModuleId}
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
						>
							Tasks
						</Typography>
						{(tasksByNodeId?.length === 0 || tasksByNodeId?.data?.length === 0 || tasksByNodeId?.data === null) && (
							<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
								<Typography
									level='h6'
									sx={{ color: "#050519", opacity: 0.5, fontWeight: 500, textAlign: "center", width: "85%" }}
								>
									Currently there are no Tasks to display. Select a course, module and topic first.
								</Typography>
							</Box>
						)}
						{renderTasks()}
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					p: 2,
					display: "flex",
					alignItems: "center",
					gap: 2,
					justifyContent: "flex-end",
				}}
			>
				<Button
					variant='outlined'
					sx={{ bgcolor: "#f5f5ff", color: "#3838f1", border: "none", fontSize: "14px", fontWeight: 600 }}
					onClick={() => {
						setDropdownOpen(false);
						dispatch(clearImportTasks());
					}}
				>
					Cancel
				</Button>
				<Button
					variant='primary'
					sx={{ backgroundColor: "#3838F1", fontWeight: "600", fontSize: "14px", borderRadius: "4px", color: "white" }}
					onClick={handleImportTasks}
				>
					Import
				</Button>
			</Box>
		</Box>
	);
};

export default ImportTask;
