import { Box, Typography } from "@mui/joy";
import React from "react";
import { Link } from "react-router-dom";

const ExternalTask = ({ task }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
			}}
		>
			{task?.actual_task?.metadata?.doc_urls?.length > 0 && (
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#000", fontSize: "14px", fontWeight: "700" }}
					>
						Documents
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						{task?.actual_task?.metadata?.doc_urls.map((doc, index) => (
							<Box
								key={index}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									padding: "0.5rem 1rem",
									bgcolor: "#f4f4f4",
									borderRadius: "sm",
									gap: 1,
								}}
							>
								<Link
									to={doc}
									target='_blank'
									style={{ textDecoration: "none" }}
								>
									<Typography sx={{ color: "#3838f1", fontWeight: "600" }}>Reading Task {index + 1}</Typography>
								</Link>
							</Box>
						))}
					</Box>
				</Box>
			)}
			<Box>
				{task?.task_progress?.task ? (
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#000", fontSize: "14px", fontWeight: "700" }}
						>
							Submission
						</Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									padding: "0.5rem 1rem",
									bgcolor: "#f4f4f4",
									borderRadius: "sm",
									gap: 1,
								}}
							>
								{task?.actual_task?.task_type === "DOC_UPLOAD" && (
									<Link
										to={task?.task_progress?.task?.task_answer?.url[0]}
										target='_blank'
										style={{ textDecoration: "none" }}
									>
										<Typography
											sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 400 }}
											noWrap
										>
											{task?.task_progress?.task?.task_answer?.file_name}
										</Typography>
									</Link>
								)}
								{task?.actual_task?.task_type === "DOC_LINK" && (
									<Link
										to={task?.task_progress?.task?.task_answer?.url[0]}
										target='_blank'
										style={{ textDecoration: "none" }}
									>
										<Typography
											sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 400 }}
											noWrap
										>
											Link Submitted
										</Typography>
									</Link>
								)}
							</Box>
						</Box>
					</Box>
				) : (
					<Box>No Submission</Box>
				)}
			</Box>
		</Box>
	);
};

export default ExternalTask;
