export const ExternalTaskTypes = {
	DOC_UPLOAD: "DOC_UPLOAD",
	DOC_LINK: "DOC_LINK",
	TROVEN: "TROVEN",
};

export const URLTaskTypes = {
	GITHUB: "github.com",
	JUPYTER: "jupyter.org",
};

export const ReadingTaskTypes = {
	DOC_UPLOAD: "DOC_UPLOAD",
	DOC_LINK: "DOC_LINK",
};
