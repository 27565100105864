import { createSlice, current } from "@reduxjs/toolkit";
import { AddTaskThunk, deleteTaskThunk, getAllTasksByNodeIdThunk, getTasksByNodeIdThunk } from "../actions/taskAction";
import { checkStatusThunk, generateTaskForVideoThunk } from "../actions/videoAction";

const initialState = {
	status: "",
	statusCheckLoading: false,
	error: null,
	taskData: [],
	taskByNodeId: [],
	tasksByNodeId: [],
	taskError: null,
	taskNodeLoader: false,
	activeStep: 0,
	taskAddLoader: false,
	tasksLoader: false,
	tasksError: null,
};

const taskSlice = createSlice({
	name: "task",
	initialState,
	reducers: {
		incrementStep: (state) => {
			state.activeStep += 1;
		},
		decrementStep: (state) => {
			state.activeStep -= 1;
		},
		setActiveStep: (state) => {
			state.activeStep = 0;
		},

		addTask: (state, action) => {
			let currentTask = current(state);

			state.taskByNodeId = {
				...currentTask?.taskByNodeId,
				data: [...(currentTask?.taskByNodeId?.data || []), action.payload], // Fallback to empty array if data is undefined
			};
		},

		updateTaskData: (state, action) => {
			let currentTask = current(state);
			state.taskByNodeId = {
				...currentTask.taskByNodeId,
				data: currentTask.taskByNodeId.data.map((task) => (task.task_id === action.payload.task_id ? action.payload : task)),
			};
		},
		reorderTasks: (state, action) => {
			let currentState = current(state);
			const currentTask = currentState.taskByNodeId.data;

			state.taskByNodeId = {
				...currentState.taskByNodeId,
				data: action.payload,
			};
		},
		clearImportTasks: (state) => {
			state.tasksByNodeId = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(AddTaskThunk.pending, (state, action) => {
				state.taskAddLoader = true;
				state.error = null;
			})
			.addCase(AddTaskThunk.fulfilled, (state, action) => {
				state.taskAddLoader = false;
				state.error = null;
			})
			.addCase(AddTaskThunk.rejected, (state, action) => {
				state.taskAddLoader = false;
				state.error = action.payload;
			});
		builder
			.addCase(generateTaskForVideoThunk.pending, (state) => {
				state.statusCheckLoading = true;
				state.error = null;
			})
			.addCase(generateTaskForVideoThunk.fulfilled, (state, action) => {
				state.statusCheckLoading = false;
				state.taskData = action.payload;
				if (action.payload.data.length > 0) {
					state.activeStep += 1;
				}
			})
			.addCase(generateTaskForVideoThunk.rejected, (state, action) => {
				state.statusCheckLoading = false;
				state.error = action.payload;
			});

		builder
			.addCase(getTasksByNodeIdThunk.pending, (state) => {
				state.taskNodeLoader = true;
				state.taskError = null;
			})
			.addCase(getTasksByNodeIdThunk.fulfilled, (state, action) => {
				state.taskNodeLoader = false;
				state.taskByNodeId = action.payload;
			})
			.addCase(getTasksByNodeIdThunk.rejected, (state, action) => {
				state.taskNodeLoader = false;
				state.taskError = action.payload;
			});

		builder
			.addCase(getAllTasksByNodeIdThunk.pending, (state) => {
				state.tasksLoader = true;
				state.tasksError = null;
			})
			.addCase(getAllTasksByNodeIdThunk.fulfilled, (state, action) => {
				state.tasksLoader = false;
				state.tasksByNodeId = action.payload;
			})
			.addCase(getAllTasksByNodeIdThunk.rejected, (state, action) => {
				state.tasksLoader = false;
				state.tasksError = action.payload;
			});

		builder
			.addCase(deleteTaskThunk.pending, (state) => {
				state.taskNodeLoader = true;
				state.taskError = null;
			})
			.addCase(deleteTaskThunk.fulfilled, (state, action) => {
				state.taskNodeLoader = false;
				const index = state.taskByNodeId.data.findIndex((task) => task.task_id === action.payload?.data[0]?.task_id);
				state.taskByNodeId.data.splice(index, 1);
			})
			.addCase(deleteTaskThunk.rejected, (state, action) => {
				state.taskNodeLoader = false;
				state.taskError = action.payload;
			});
	},
});

export const { incrementStep, decrementStep, setActiveStep, addTask, updateTaskData, reorderTasks, clearImportTasks } = taskSlice.actions;

export default taskSlice.reducer;
