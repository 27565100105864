import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const updateStudent = async (studentId, student) => {
	const response = await axios.patch(`${API_URL}/student?student_id=${studentId}`, student, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const addStudent = async (studentInfo, batch_id) => {
	const response = await axios.post(
		`${API_URL}/student/add/admin/assign`,
		{ studentInfo, batch_id },
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response.data;
};

export const fetchCollegeNames = async () => {
	try {
	  const response = await axios.get(`${API_URL}/student/colleges`, {
		headers: {
		  Authorization: `Bearer ${fetchToken()}`, // Include the authorization header
		},
	  });
	  return response.data;
	} catch (error) {
	  console.error("Error fetching college names:", error);
	  throw error; // Rethrow the error for further handling if needed
	}
  };

  export const deleteStudent = async (student_id, batchId) => {
	const response = await axios.delete(
		`${API_URL}/student/delete-id-batch?student_id=${student_id}&batch_id=${batchId}`,
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response;
};