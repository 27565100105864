import React, { useState, useEffect } from "react";
import { Box, Button, Input, Typography, Select, Option } from "@mui/joy";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../../Constants/toast-type";
import { addStudentThunk } from "../../../../../Store/actions/studentAction";
import { addStudentData, setFormFilled, setUnsavedChanges } from "../../../../../Store/slices/batchSlice";
import { useSelector } from "react-redux";
import { fetchStudentsByBatchIdThunk } from "../../../../../Store/actions/batchAction";
import { batchType } from "../../../../../Constants/batch-tabs";
import { fetchCollegeNames } from "../../../../../Store/services/studentApiService";
import ReactSelect from "../../../../utils/search-select";

const AddStudentDetails = ({ setIsAddModalOpen, batchId }) => {
	const [studentEmail, setStudentEmail] = useState();
	const [studentName, setStudentName] = useState();
	const [studentPhone, setStudentPhone] = useState();
	const [studentCollegeName, setStudentCollegeName] = useState("");
	const [studentLoader, setStudentLoader] = useState(false);

	const [collegeOptions, setCollegeOptions] = useState();
	const [newCollegeName, setNewCollegeName] = useState(""); // State for new college name

	const getColleges = async () => {
		try {
			const response = await fetchCollegeNames();
			if (response.success) {
				const collegeNames = response.data.map(college => college.college_name);
				setCollegeOptions(collegeNames);
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		getColleges();
	}, []);

	const handleAddCollege = () => {
		if (newCollegeName && !collegeOptions.includes(newCollegeName)) {
			setCollegeOptions([...collegeOptions, newCollegeName]);
			setStudentCollegeName(newCollegeName);
			setNewCollegeName(""); // Clear the input after adding
		}
	};

	const handleCollegeChange = (selectedOption) => {
		if (selectedOption === "add_new") {
			setStudentCollegeName("add_new");
		} else {
			setStudentCollegeName(selectedOption);
		}
	};

	// Generate dropdown options
	const generateOptions = (data) => [
		...data?.map((item) => ({ value: item, label: item })) || [],
		{ value: "add_new", label: "Add New College" }, // Add new college option
	];

	const dispatch = useDispatch();

	const { batchDetails } = useSelector((state) => state.batch);

	const handleAddStudent = async () => {
		if (!studentName || !studentEmail) {
			dispatch(showToast({ toastMessage: "Please fill all the fields", toastType: ToastType.ERROR }));
			return;
		}

		if (studentCollegeName === "add_new") {
			dispatch(showToast({ toastMessage: "Please select the valid college", toastType: ToastType.ERROR }));
			return;
		}

		// Create the student data object
		const studentInfo = {
			name: studentName,
			email: studentEmail.toLowerCase().trim(),
			mobile_number: studentPhone,
			college_name: studentCollegeName,
		};

		const batch_id = batchId || batchDetails?.data?.batch_id;
		setStudentLoader(true);
		// Dispatch the action to add the student
		const response = await dispatch(addStudentThunk({ studentInfo, batch_id }));

		if (response.payload.success) {
			if (batchId) {
				dispatch(
					addStudentData({
						student_id: response.payload.data.student_id,
						name: studentName,
						email: studentEmail.toLowerCase().trim(),
						mobile_number: studentPhone,
						college_details: { college_name: studentCollegeName },
					})
				);
				dispatch(showToast({ toastMessage: "Student added successfully", toastType: ToastType.SUCCESS }));
			} else {
				dispatch(showToast({ toastMessage: "Student added successfully", toastType: ToastType.SUCCESS }));
				dispatch(fetchStudentsByBatchIdThunk({ batchId: batchDetails?.data?.batch_id }));
				dispatch(setFormFilled({ tab: batchType.verifyStudents, filled: true }));
			}
			dispatch(setUnsavedChanges(batch_id));
		}
		setStudentLoader(false);
		setIsAddModalOpen(false);
	};

	const renderStudentDetails = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							justifyContent: "space-between",
						}}
					>
						<Box
							sx={{
								width: "100%",
							}}
						>
							<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Name</Typography>
							<Input
								value={studentName}
								placeholder='Enter Student Name'
								onChange={(e) => setStudentName(e.target.value)}
							/>
						</Box>

						<Box
							sx={{
								width: "100%",
							}}
						>
							<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>
								College Name
							</Typography>
							{collegeOptions ? (
								<ReactSelect
									options={generateOptions(collegeOptions, "name", "name")}
									isSearchable
									onChange={handleCollegeChange}
									placeholder='All colleges'
									value={
										studentCollegeName
											? generateOptions(collegeOptions).find(
												(option) => option.value === studentCollegeName
											)
											: null
									}
								/>
							) : (
								<p>Loading options...</p>
							)}
							{studentCollegeName === "add_new" && (
								<Box sx={{ display: "flex", mt: 1 }}>
									<Input
										value={newCollegeName}
										placeholder="Enter New College Name"
										onChange={(e) => setNewCollegeName(e.target.value)}
										sx={{
											flex: 1,
											marginRight: "8px",
										}}
									/>
									<Button
										onClick={handleAddCollege}
										variant="contained"
									>
										Add
									</Button>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Email</Typography>
					<Input
						value={studentEmail}
						placeholder='Enter Student Email'
						onChange={(e) => setStudentEmail(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Phone</Typography>
					<Input
						value={studentPhone}
						placeholder='Enter Student Phone'
						onChange={(e) => setStudentPhone(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<Button
						sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
						color='primary'
						onClick={handleAddStudent}
						disabled={studentLoader}
						loading={studentLoader}
					>
						Save
					</Button>
				</Box>
			</Box>
		);
	};

	return <Box>{renderStudentDetails()}</Box>;
};

export default AddStudentDetails;
