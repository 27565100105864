import { Box, Button, Card, Input, Option, Select, Textarea, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllTenantsThunk, getCoursesByTenantIdThunk } from "../../../Store/actions/tenantAction";
import { selectTenant } from "../../../Store/slices/batchSlice";
import { UserRole } from "../../../Constants/user-role";
import { useNavigate } from "react-router-dom";
import { addCourse } from "../../../Store/services/coursesApiService";
import { ToastType } from "../../../Constants/toast-type";
import { showToast } from "../../../Store/slices/commonSlice";

const CreateCourseForm = ({ setShowAddCourseModal }) => {
	const dispatch = useDispatch();

	const [courseName, setCourseName] = useState("");
	const [description, setDescription] = useState("");
	const [difficulty, setDifficulty] = useState("BEGINNER");
	const [selectedStyleCard, setSelectedStyleCard] = useState("Cohort Based Course");
	const [selectedCard, setSelectedCard] = useState("Create from Scratch");
	const [addCourseLoading, setAddCourseLoading] = useState(false);

	const { user } = useSelector((state) => state.user);
	const { tenants, selectedTenant } = useSelector((state) => state.batch);

	const navigate = useNavigate();

	useEffect(() => {
		if (user?.role === UserRole.ADMIN) dispatch(fetchAllTenantsThunk());
	}, []);

	const handlePartnerChange = (event, newValue) => {
		const tenantId = newValue;
		dispatch(selectTenant(tenantId));
		if (tenantId) dispatch(getCoursesByTenantIdThunk(tenantId));
	};

	const addCourseData = async () => {
		try {
			if (!courseName || !description || !difficulty) {
				dispatch(showToast({ toastMessage: "Please fill all the fields", toastType: ToastType.ERROR }));
				return;
			}
			const courseDetails = {
				courseName,
				tenant_id: user?.tenant_id || selectedTenant,
				courseDescription: description,
				difficulty,
				courseStyle: "COHORT_BASED",
			};
			if (selectedCard === "Create from Spreadsheet") {
			} else if (selectedCard === "Create from Scratch") {
				setAddCourseLoading(true);
				const response = await addCourse(courseDetails, []);
				setAddCourseLoading(false);
				navigate(`/course/${response?.data?.data?.course_id}`);
			}
		} catch (error) {
			alert(error?.response?.data?.message);
			setAddCourseLoading(false);
			console.error(error);
		}
	};

	return (
		<Box>
			<Box sx={{ display: "flex", flexDirection: "column", maxHeight: "76vh", overflowY: "auto" }}>
				<Box sx={{ flex: 1, overflowY: "auto", gap: 3, p: ".5rem", width: "full", display: "flex", flexDirection: "column" }}>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Name of the course
						</Typography>
						<Input
							placeholder='e.g. Python'
							size='sm'
							type='text'
							label='Course Name'
							slotProps={{
								input: {
									autoFocus: true,
									maxLength: 64,
								},
							}}
							value={courseName}
							onChange={(e) => {
								setCourseName(e.target.value);
							}}
							sx={{ width: "100%", "--Input-focusedThickness": "0rem", fontWeight: 600 }}
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Description
						</Typography>
						<Textarea
							label='Question'
							type='text'
							minRows={5}
							maxRows={5}
							value={description}
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							onChange={(e) => setDescription(e.target.value)}
							sx={{ width: "100%" }}
							placeholder='e.g. Description of the course'
							required
							// error={explanation?.trim() === ""}
						/>
					</Box>
					{user?.role === "ADMIN" && (
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Partner
							</Typography>
							<Select
								variant='plane'
								placeholder='Select Partner'
								value={selectedTenant || ""}
								onChange={handlePartnerChange}
								style={{ backgroundColor: "#fff", border: "1px solid #d7d7d7" }}
							>
								{tenants?.data?.length > 0 ? (
									tenants?.data?.map((partner) => (
										<Option
											key={partner.tenant_id}
											value={partner.tenant_id}
										>
											{partner.name}
										</Option>
									))
								) : (
									<Option value=''>No Partners found</Option>
								)}
							</Select>
						</Box>
					)}
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Course Type
						</Typography>
						<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
							<Card
								variant='outlined'
								color='neutral'
								sx={{
									cursor: "not-allowed", // Disabled cursor style
									maxHeight: "80px",
									bgcolor: "#f4f4f4", // Disabled background color
									border: "1px solid #bdbdbd", // Disabled border style
								}}
							>
								<Typography
									sx={{
										color: "#9e9e9e", // Disabled text color
										fontWeight: "600",
									}}
								>
									Self Paced Course
								</Typography>
								<Typography
									sx={{
										fontSize: "12px",
										color: "#9e9e9e", // Disabled description color
									}}
								>
									No specific schedule, students access at their own pace
								</Typography>
							</Card>

							<Card
								variant='outlined'
								color='neutral'
								sx={{
									cursor: "pointer",
									maxHeight: "80px",
									bgcolor: `${selectedStyleCard === "Cohort Based Course" ? "#F5f5ff" : "#f4f4f4"}`,
									border: `${selectedStyleCard === "Cohort Based Course" ? "2px solid #3838F1" : "none"}`,
								}}
								onClick={() => setSelectedStyleCard("Cohort Based Course")}
							>
								<Typography
									sx={{
										color: `${selectedStyleCard === "Cohort Based Course" ? "#3838F1" : "black"}`,
										fontWeight: "600",
									}}
								>
									Cohort Based Course
								</Typography>
								<Typography
									sx={{
										fontSize: "12px",
									}}
								>
									Specific schedule, drip-wise student content distribution
								</Typography>
							</Card>
						</Box>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Difficulty
						</Typography>
						<Select
							defaultValue={difficulty}
							variant='plane'
							// onChange={(e, newValue) => {
							// 	setDifficulty(newValue);
							// }}
							style={{ backgroundColor: "#fff", border: "1px solid #d7d7d7" }}
						>
							<Option value='BEGINNER'>Beginner</Option>
							{/* <Option value='INTERMEDIATE'>Intermediate</Option>
										<Option value='ADVANCED'>Advanced</Option> */}
						</Select>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					px: 2,
					mt: 2,
					display: "flex",
					alignItems: "center",
					gap: 2,
					justifyContent: "flex-end",
				}}
			>
				<Button
					variant='outlined'
					sx={{ bgcolor: "#f5f5ff", color: "#3838f1", border: "none", fontSize: "14px", fontWeight: 600 }}
					onClick={() => {
						setShowAddCourseModal(false);
						// dispatch(clearImportTasks());
					}}
				>
					Cancel
				</Button>
				<Button
					variant='primary'
					sx={{ backgroundColor: "#3838F1", fontWeight: "600", fontSize: "14px", borderRadius: "4px", color: "white" }}
					onClick={addCourseData}
					loading={addCourseLoading}
				>
					Create
				</Button>
			</Box>
		</Box>
	);
};

export default CreateCourseForm;
