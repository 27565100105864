import React, { useEffect, useState } from "react";
import CsvFileUpload from "../../../../utils/CsvFileUpload";
import { Box, Button, Input, Option, Typography, Select, Dropdown, MenuButton, MenuItem, Menu, Modal, ModalDialog, ModalClose } from "@mui/joy";
import { TableCell, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as StudentSvg } from "../../../../../Assets/student.svg";
import { fetchStudentsByBatchIdThunk } from "../../../../../Store/actions/batchAction";
import { addStudentsAndAssignToBatch } from "../../../../../Store/services/batchApiService";
import { setFormFilled, setUnsavedChanges } from "../../../../../Store/slices/batchSlice";
import { batchType } from "../../../../../Constants/batch-tabs";
import { studentStatusColor } from "../../../../../helpers/styleConfig";
import Loader from "../../../../utils/Loader";
import useDebounce from "../../../../../hooks/use-debounce";
import PaginationComponent from "../../../../utils/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { getFirstLetterCapitalized } from "../../../../../helpers/text-formatter";
import axios from "axios";
import { API_URL } from "../../../../../Config";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { UserRole } from "../../../../../Constants/user-role";
import EditStudentDetails from "./edit-student";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import AddStudentDetails from "./add-student";
import { ToastType } from "../../../../../Constants/toast-type";
import { showToast } from "../../../../../Store/slices/commonSlice";
import { deleteStudentThunk } from "../../../../../Store/actions/studentAction";
import { removeStudentData } from "../../../../../Store/slices/batchSlice";

const VerifyStudents = ({ status, batchId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [sortField, setSortField] = useState("created_at");
	const [filter, setFilter] = useState([]);
	const [inviteLoader, setInviteLoader] = useState(false);
	const [studentInviteLoader, setStudentInviteLoader] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState("");
	const [isInviteModalOpen, setIsInviteModalOpen] = useState("");
	const [isAddModalOpen, setIsAddModalOpen] = useState(false); // New state for Add Student modal
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState("");

	const navigate = useNavigate();

	const debouncedValue = useDebounce(search, 500);

	const { batchDetails, students, loading } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const csvUploadClick = async (data) => {
		setUploading(true);
		const response = await addStudentsAndAssignToBatch(data, batchDetails?.data?.batch_id);
		if (response?.success) {
			if (batchId) {
				dispatch(fetchStudentsByBatchIdThunk({ batchId }));
				setUploading(false);
				setIsOpen(false);
			} else {
				dispatch(fetchStudentsByBatchIdThunk({ batchId: batchDetails?.data?.batch_id }));
				dispatch(setFormFilled({ tab: batchType.verifyStudents, filled: true }));
				setUploading(false);
				setIsOpen(false);
			}
			dispatch(setUnsavedChanges(batchDetails?.data?.batch_id));
		}
	};

	const handleClearSearch = () => {
		setSearch("");
	};

	const getStatus = (student) => {
		if (student?.invite_status === "PENDING" && student?.status === "ACTIVE") {
			return "Active";
		} else if (student?.invite_status === "COMPLETED" && student?.status === "ACTIVE") {
			return "Active";
		} else if (student?.invite_status === "COMPLETED" && student?.status === "PENDING") {
			return "Invited";
		} else if (student?.invite_status === "PENDING" && student?.status === "PENDING") {
			return "To be invited";
		}
	};

	useEffect(() => {
		if (debouncedValue) {
			if (currentPage !== 1) setCurrentPage(1);
			else {
				dispatch(
					fetchStudentsByBatchIdThunk({
						batchId: batchId || batchDetails?.data?.batch_id,
						query: {
							search: debouncedValue,
							page: currentPage,
							sortBy: sortField,
							sortOrder,
						},
					})
				);
			}
		} else {
			setCurrentPage(1);
			dispatch(
				fetchStudentsByBatchIdThunk({
					batchId: batchId || batchDetails?.data?.batch_id,
					query: {
						page: currentPage,
						sortBy: sortField,
						sortOrder,
					},
				})
			);
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (batchId || batchDetails?.data?.batch_id) {
			if (debouncedValue) {
				dispatch(
					fetchStudentsByBatchIdThunk({
						batchId: batchId || batchDetails?.data?.batch_id,
						query: {
							search: debouncedValue,
							page: currentPage,
							sortBy: sortField,
							sortOrder,
						},
					})
				);
			} else {
				dispatch(
					fetchStudentsByBatchIdThunk({
						batchId: batchId || batchDetails?.data?.batch_id,
						query: {
							page: currentPage,
							sortBy: sortField,
							sortOrder,
						},
					})
				);
			}
		}
	}, [currentPage, sortField, sortOrder]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSearchChange = (e) => {
		const value = e.target.value.trim(); // Trim the value here

		// Only set search if the value is non-empty or if it's completely cleared
		if (value !== "" || e.target.value === "") {
			setSearch(e.target.value);
		}
	};

	const handleSortByChange = (e, newValue) => {
		setSortField(newValue);
	};

	const handleSortOrderChange = (e, newValue) => {
		setSortOrder(newValue);
	};

	const stasuses = {
		PENDING: "To be invited",
		COMPLETED: "Invited",
	};

	const renderAddStudentModal = () => {
		return (
			<Modal
				open={isAddModalOpen}
				onClose={() => setIsAddModalOpen(false)}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose />
					<Typography level='h5'>Add Student</Typography>
					<AddStudentDetails
						setIsAddModalOpen={setIsAddModalOpen}
						batchId={batchId}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderStudentEditModal = (student) => {
		return (
			<Modal
				open={isEditModalOpen === student.student_id}
				onClose={() => setIsEditModalOpen("")}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose />
					<Typography level='h5'>Edit Student</Typography>
					<EditStudentDetails
						student={student}
						setIsEditModalOpen={setIsEditModalOpen}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderStudentResendInviteModal = (student) => {
		const handleSendStudentInvite = async () => {
			try {
				setStudentInviteLoader(true);
				const response = await axios.post(`${API_URL}/student/send-invite`, { student_id: student.student_id, batch_id: batchId || batchDetails?.data?.batch_id });
				if (response?.data?.success) {
					dispatch(fetchStudentsByBatchIdThunk({ batchId: batchId || batchDetails?.data?.batch_id }));
				}
				setStudentInviteLoader(false);
				setIsInviteModalOpen("");
			} catch (error) {
				dispatch(showToast({ toastMessage: error?.message, toastType: ToastType.ERROR }));
				setInviteLoader(false);
				setIsInviteModalOpen("");
			}
		};
		return (
			<Modal
				open={isInviteModalOpen === student.student_id}
				onClose={() => setIsInviteModalOpen("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h5'>Resend Invite</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 4,
						}}
					>
						<Typography level='h4'>Are you sure you want to resend the invite to {student.name}?</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-evenly",
							}}
						>
							<Button
								variant='outlined'
								sx={{ bgcolor: "white", color: "#3838f1", border: "1px solid #f4f4f4" }}
								onClick={() => setIsInviteModalOpen("")}
							>
								Cancel
							</Button>
							<Button
								sx={{ bgcolor: "#3838f1", color: "white" }}
								color='primary'
								size='sm'
								onClick={handleSendStudentInvite}
								loading={studentInviteLoader}
							>
								Send Invite
							</Button>
						</Box>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const handleDelete = async (student_id) => {
		try {

			const response = await dispatch(deleteStudentThunk({ student_id, batchId }));
			if (response?.payload.success) {
				dispatch(removeStudentData({ student_id }));
				dispatch(showToast({ toastMessage: "Student deleted succesfully", toastType: ToastType.SUCCESS }));
				setIsDeleteModalOpen("");
			}
		} catch (error) {
			dispatch(showToast({ toastMessage: error?.message, toastType: ToastType.ERROR }));
		}
	};

	const renderStudentDeleteModal = (student) => {
		return (
			<Modal
				open={isDeleteModalOpen === student.student_id}
				onClose={() => setIsDeleteModalOpen("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to delete this student?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							color='primary'
							onClick={() => setIsDeleteModalOpen("")}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							onClick={() => handleDelete(student.student_id)}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderStudentCard = (student) => {
		dispatch(setFormFilled({ tab: batchType.verifyStudents, filled: true }));
		if (student?.student === null) return;
		return (
			<Box
				sx={{
					backgroundColor: "#f4f4f4",
					borderRadius: "sm",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: ".8rem 1rem",
					my: 2,
					cursor: "pointer",
				}}
				onClick={status !== "ADD" ? () => navigate(`student/${student.student.student_id}`) : undefined}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					<StudentSvg
						style={{
							width: "20px",
							height: "20px",
						}}
					/>
					<Typography
						sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
						noWrap
					>
						{getFirstLetterCapitalized(student.student.name)}
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Box>
							<Typography
								maxWidth={200}
								noWrap
								sx={{
									mx: 2,
									fontSize: "14px",
								}}
							>
								{student?.student?.college_details?.college_name}
							</Typography>
						</Box>
						<Box
							sx={{
								width: "200px",
							}}
						>
							<Typography
								maxWidth={400}
								noWrap
								sx={{
									fontSize: "14px",
								}}
							>
								{student.student.email}
							</Typography>
						</Box>
						<Box>
							<Typography
								sx={{
									borderRadius: "5px",
									fontWeight: 600,
									color: studentStatusColor[getStatus(student)],
									textAlign: "right",
									px: 2,
									fontSize: "14px",
								}}
							>
								{getStatus(student)}
							</Typography>
						</Box>
					</Box>

					{user?.role !== UserRole.TEACHER && (
						<Dropdown>
							<MenuButton
								sx={{
									backgroundColor: "transparent",
									border: "none",
									cursor: "pointer",
									padding: 0.5,
								}}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<MoreHorizIcon />
							</MenuButton>
							<Menu
								modifiers={[
									{
										name: "offset",
										options: {
											offset: ({ placement }) => {
												if (placement.includes("end")) {
													return [8, 20];
												}
												return [50, -20];
											},
										},
									},
								]}
							>
								<MenuItem
									onClick={(e) => {
										e.stopPropagation();
										setIsEditModalOpen(student.student.student_id);
									}}
								>
									Edit
								</MenuItem>

								<MenuItem
									onClick={(e) => {
										e.stopPropagation();
										setIsInviteModalOpen(student.student.student_id);
									}}
								>
									Send Invite
								</MenuItem>
								{getStatus(student) !== "Active" && (
									<MenuItem
										color='danger'
										onClick={(e) => {
											e.stopPropagation();
											setIsDeleteModalOpen(student.student.student_id);
										}}
									>
										Delete
									</MenuItem>
								)}
							</Menu>
						</Dropdown>
					)}
				</Box>
			</Box>
		);
	};

	const renderStudents = () => {
		if (loading) {
			return (
				<Box>
					<Loader />
				</Box>
			);
		} else if (students?.data?.data?.length > 0) {
			return (
				<>
					<Box
						sx={{
							maxHeight: "40vh",
							overflow: "auto",
							padding: " 0 1rem",
						}}
					>
						{students?.data?.data?.map((student) => {
							return (
								<>
									{renderStudentCard(student)}
									{isEditModalOpen === student.student.student_id && renderStudentEditModal(student.student)}
									{isInviteModalOpen === student.student.student_id && renderStudentResendInviteModal(student.student)}
									{isDeleteModalOpen === student.student.student_id && renderStudentDeleteModal(student.student)}
								</>
							);
						})}
					</Box>
					{renderPagination()}
				</>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						No Students Found
					</Typography>
				</Box>
			);
		}
	};

	const renderTableBody = () => {
		return students?.data?.data?.map((student) => {
			dispatch(setFormFilled({ tab: batchType.verifyStudents, filled: true }));
			if (student?.student === null) return;
			return (
				<TableRow
					key={student.student_id}
					sx={{}}
				>
					<TableCell sx={{ px: 3, py: 1, fontSize: "1rem" }}>
						{student?.student?.mobile_number || (
							<Typography
								level='body-xs'
								sx={{ ml: 1 }}
							>
								No number
							</Typography>
						)}
					</TableCell>
				</TableRow>
			);
		});
	};

	const renderPagination = () => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "1rem",
				}}
			>
				<Box
					sx={{
						display: "flex",
						bgcolor: "white",
						justifyContent: "center",
						alignItems: "center",
						padding: " .5rem",
						borderRadius: "8px",
						gap: 5,
					}}
				>
					<PaginationComponent
						totalPage={students?.data?.totalPages}
						onPageChange={handlePageChange}
						currentPage={currentPage}
					/>
				</Box>
			</Box>
		);
	};

	const handleSendInvite = async () => {
		try {
			setInviteLoader(true);
			const batch_id = batchId || batchDetails?.data?.batch_id;
			const response = await axios.post(`${API_URL}/student/send-invites`, { batch_id });
			if (response?.data?.success) {
				dispatch(fetchStudentsByBatchIdThunk({ batchId: batch_id }));
			}
			setInviteLoader(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Box sx={{ display: "grid" }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography level='h5'>Students</Typography>
				{user?.role !== UserRole.TEACHER && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						{students?.data?.data?.length > 0 && (
							<Button
								variant='outlined'
								sx={{ bgcolor: "white", color: "#3838f1", border: "1px solid #3838f1" }}
								onClick={handleSendInvite}
								loading={inviteLoader}
							>
								Send Invites
							</Button>
						)}
						<Button
							onClick={() => setIsAddModalOpen(true)}
							variant='outlined'
							sx={{ bgcolor: "white", color: "#3838f1", border: "1px solid #3838f1" }}
						>
							Add a Student
						</Button>
						<Button
							onClick={() => setIsOpen(true)}
							variant='primary'
							sx={{ display: "flex", justifySelf: "flex-end", alignItems: "center", gap: 1, backgroundColor: "#3838F1" }}
						>
							<Typography sx={{ width: "fit-content", color: "white" }}>Upload Spreadsheet</Typography>
						</Button>
					</Box>
				)}
			</Box>
			<Box sx={{ borderBottom: "1px solid black", my: 1 }}></Box>
			<CsvFileUpload
				openUploadPopup={isOpen}
				closePopup={() => setIsOpen(false)}
				uploadClick={csvUploadClick}
				uploading={uploading}
				setUploading={setUploading}
			/>
			<Box sx={{ display: "flex", my: 1, alignItems: "center", justifyContent: "space-between", gap: "1rem" }}>
				{/* search bar */}
				<Box sx={{ padding: "0 .5rem", display: "flex", alignItems: "center", border: "1px solid #d7d7d7", bgcolor: "white", borderRadius: "10px", width: "70%" }}>
					<SearchIcon
						sx={{
							fontWeight: "200",
						}}
					/>
					<Input
						placeholder='Search Students by name or email '
						value={search}
						sx={{
							width: "100%",
							border: "none",
							outline: "none",
							bgcolor: "white",
							"&::before": {
								display: "none",
							},
							"&:focus-within": {
								outline: "none",
								outlineOffset: "none",
							},
						}}
						onChange={handleSearchChange}
					/>
				</Box>

				{search !== "" && (
					<Box sx={{ display: "flex", gap: 10 }}>
						<Button
							variant='primary'
							sx={{
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								padding: ".1rem .5rem",
							}}
							onClick={handleClearSearch}
						>
							<ClearIcon sx={{ color: "red" }} />
						</Button>
					</Box>
				)}

				{/* sort bar */}
				<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
					<Box>
						<Select
							placeholder='Sort By'
							sx={{ bgcolor: "white" }}
							onChange={handleSortByChange}
						>
							<Option value='name'>Name</Option>
							<Option value='created_at'>Created at</Option>
						</Select>
					</Box>
					<Box>
						<Select
							placeholder='Sort Order'
							onChange={handleSortOrderChange}
							sx={{ bgcolor: "white" }}
						>
							<Option value='asc'>Ascending</Option>
							<Option value='desc'>Descending</Option>
						</Select>
					</Box>
				</Box>
			</Box>
			<Box sx={{ mt: 2 }}>{renderStudents()}</Box>
			{renderAddStudentModal()}
		</Box>
	);
};

export default VerifyStudents;
