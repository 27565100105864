import { Box } from "@mui/joy";
import React, { useState } from "react";
import Select, { components } from "react-select";
import { ReactComponent as SearchSelectIcon } from "../../Assets/search-select.svg";
import { ValueContainer } from "react-select/animated";

const ReactSelect = ({ isSearchable, options, onChange, isLoading = false, ...props }) => {
	const [selectedOption, setSelectedOption] = useState(props?.selectedOption || null);

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<SearchSelectIcon />
			</components.DropdownIndicator>
		);
	};

	const handleChange = (option) => {
		setSelectedOption(option?.value === null ? null : option);
		onChange(option?.value); // Call the parent's onChange
	};
	const computedOptions = selectedOption?.value !== undefined ? [{ value: null, label: `Clear ${selectedOption?.label}` }, ...options] : options;

	// Custom styles for react-select
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			color: state.data.value === null ? "red" : provided.color,
			backgroundColor: state.isFocused
				? state.data.value === "null"
					? "rgba(255, 0, 0, 0.1)" // Light red background on hover
					: provided.backgroundColor
				: provided.backgroundColor,
		}),
		singleValue: (provided) => ({
			...provided,
			color: selectedOption?.value === null ? "red" : provided.color,
		}),
	};

	return (
		<Box>
			<Select
				className='basic-single'
				classNamePrefix='select'
				isLoading={isLoading}
				name='color'
				options={computedOptions}
				components={{ DropdownIndicator, IndicatorSeparator: () => null }}
				onChange={handleChange}
				value={selectedOption}
				isSearchable={isSearchable}
				styles={customStyles}
				maxMenuHeight={150}
				{...props}
			/>
		</Box>
	);
};

export default ReactSelect;
