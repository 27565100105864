import { Box, Button, Input, Option, Select, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import { languages } from "../../../../Constants/languages";
import { useDispatch } from "react-redux";
import { updateTaskThunk } from "../../../../Store/actions/taskAction";
import { updateTaskData } from "../../../../Store/slices/taskDetailsSlice";
import { useLocation } from "react-router-dom";

const CodeEditForm = ({ setOpen, task }) => {
	const [question, setQuestion] = useState(task?.question);
	const [constraints, setConstraints] = useState(task?.constraints);
	const [testCases, setTestCases] = useState(task?.test_cases);
	const [lang, setLang] = useState(task?.language);
	const [taskTitle, setTaskTitle] = useState(task?.task_title);
	const [loading, setLoading] = useState(false);
	const [selectedPoint, setSelectedPoint] = useState(task?.points);

	const dispatch = useDispatch();
	const location = useLocation();
	const node_id = location.pathname.split("/")[4];

	const handleSubmit = async () => {
		if (question?.trim() === "" || constraints?.trim() === "" || testCases?.trim() === "" || lang?.trim() === "") {
			return;
		}

		// Create taskData object
		const taskData = {
			question,
			constraints,
			test_cases: testCases,
			language: lang,
			points: selectedPoint,
			task_id: task?.task_id,
			task_title: taskTitle,
			node_id,
		};

		setLoading(true);
		// Simulate dispatch of task update
		// Assume updateTaskThunk is a Redux thunk action
		const response = await dispatch(updateTaskThunk(taskData));
		if (response?.payload?.data) {
			dispatch(updateTaskData(response.payload.data));
			setLoading(false);
			setOpen(false);
		}
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Box
					sx={{
						maxHeight: "40vh",
						overflowY: "auto",
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Enter the language :
						</Typography>
						<Select
							value={lang}
							onChange={(e) => setLang(e?.target?.value)}
							sx={{ width: "100%" }}
						>
							{languages.map((language) => (
								<Option
									key={language}
									value={language?.toLowerCase()}
									defaultValue={lang}
									onClick={() => setLang(language?.toLowerCase())}
								>
									{language}
								</Option>
							))}
						</Select>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Task Title
						</Typography>
						<Input
							value={taskTitle}
							label='Task title'
							type='text'
							required
							slotProps={{
								input: {
									maxLength: 64,
								},
							}}
							error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
							sx={{ width: "100%" }}
							onChange={(e) => setTaskTitle(e.target.value)}
							placeholder='e.g. Java collections?'
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mt: 2, mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Question
						</Typography>
						<Textarea
							value={question}
							label='Question'
							type='text'
							required
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							error={question.trim() === "" ? "Please enter the question" : ""}
							minRows={5}
							maxRows={10}
							sx={{ width: "100%" }}
							onChange={(e) => setQuestion(e.target.value)}
							placeholder='e.g. Write a function to add two numbers'
						/>
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Constraints (for AI)
						</Typography>
						<Textarea
							value={constraints}
							onChange={(e) => setConstraints(e.target.value)}
							minRows={5}
							maxRows={10}
							required
							error={constraints.trim() === "" ? "Please enter the question" : ""}
							placeholder='Enter the constraints'
							label='Constraints'
							sx={{ width: "100%" }}
						/>
					</Box>

					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Test Cases (for AI)
						</Typography>
						<Textarea
							value={testCases}
							onChange={(e) => setTestCases(e.target.value)}
							minRows={5}
							maxRows={10}
							required
							error={testCases.trim() === "" ? "Please enter the question" : ""}
							placeholder='Enter test cases'
							label='Test cases'
							sx={{ width: "100%" }}
						/>
					</Box>
				</Box>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Points
					</Typography>
					<Box sx={{ display: "flex", gap: 1 }}>
						{[1, 5, 10, 15, 20, 25].map((point) => (
							<Button
								key={point}
								variant='outlined'
								color='neutral'
								sx={{
									bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
									color: `${selectedPoint === point ? "white" : "black"}`,
									"&:hover": {
										bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
										color: `${selectedPoint === point ? "white" : "black"}`,
									},
								}}
								onClick={() => setSelectedPoint(point)}
							>
								{point}
							</Button>
						))}
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						mt: 2,
						alignItems: "center",
						gap: 2,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => setOpen(false)}
					>
						Cancel
					</Button>
					<Button
						onClick={handleSubmit}
						loading={loading}
						disabled={
							loading ||
							question.trim() === "" ||
							constraints.trim() === "" ||
							testCases.trim() === "" ||
							lang?.trim() === "" ||
							taskTitle?.trim() === "" ||
							(question?.trim() === task?.question?.trim() &&
								taskTitle?.trim() === task?.task_title?.trim() &&
								constraints.trim() === task?.constraints.trim() &&
								testCases.trim() === task?.test_cases.trim() &&
								lang === task?.language &&
								selectedPoint === task?.points)
						}
					>
						Edit Task
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default CodeEditForm;
