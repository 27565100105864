import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Button, Checkbox, Typography } from "@mui/joy";
import ReactSelect from "../../utils/search-select";
import { clearFilters, setFilters } from "../../../Store/slices/batchSlice";
import { UserRole } from "../../../Constants/user-role";

const FilterOptions = ({ handleFilter }) => {
	const dispatch = useDispatch();
	const { tenants, filters } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);
	const { allCoursesNames } = useSelector((state) => state.course);

	const [selectedTenantId, setSelectedTenantId] = useState(filters?.tenant_id || null);
	const [selectedCourseId, setSelectedCourseId] = useState(filters?.course_id || null);
	const [selectedStatus, setSelectedStatus] = useState(filters?.publish_status || null);
	const [triggerFilter, setTriggerFilter] = useState(false);

	// Synchronize local state with Redux
	useEffect(() => {
		setSelectedTenantId(filters?.tenant_id || null);
		setSelectedCourseId(filters?.course_id || null);
		setSelectedStatus(filters?.publish_status || null);
	}, [filters]);

	useEffect(() => {
		if (triggerFilter) {
			handleFilter();
			setTriggerFilter(false);
		}
	}, [triggerFilter]);

	// Generate dropdown options
	const generateOptions = (data, value, label) => data?.map((item) => ({ value: item[value], label: item[label] })) || [];

	const generateCourseOptions = (data, value, label) => {
		if (user?.tenant_id === null) return data?.filter((item) => selectedTenantId === null || item.tenant_id === selectedTenantId).map((item) => ({ value: item[value], label: item[label] })) || [];
		else return data?.filter((item) => item.tenant_id === user?.tenant_id).map((item) => ({ value: item[value], label: item[label] })) || [];
	};

	// Update filters and state when tenant changes
	const handleTenantChange = (tenantId) => {
		setSelectedTenantId(tenantId);
		if (tenantId !== null) {
			dispatch(setFilters({ tenant_id: tenantId, course_id: null }));
			setSelectedCourseId(null);
		} else if (selectedCourseId) {
			dispatch(setFilters({ tenant_id: null, course_id: selectedCourseId }));
		} else {
			dispatch(setFilters({ tenant_id: null, course_id: null }));
		}
	};

	// Update filters and state when course changes
	const handleCourseChange = (courseId) => {
		setSelectedCourseId(courseId);
		dispatch(setFilters({ course_id: courseId }));
	};

	// Update filters and state when status changes
	const handleCheckboxChange = (status) => {
		const newStatus = selectedStatus === status ? null : status;
		setSelectedStatus(newStatus);
		dispatch(setFilters({ publish_status: newStatus }));
	};

	// Reset all filters
	const handleResetFilters = () => {
		setSelectedTenantId(null);
		setSelectedCourseId(null);
		setSelectedStatus(null);
		dispatch(clearFilters());
		setTimeout(() => {
			setTriggerFilter(true); // Trigger the filter call immediately after reset
		}, 0);
	};

	// Add filters
	const handleAddFilter = () => {
		handleFilter(); // Trigger the filter call
	};

	return (
		<Box sx={{ height: "450px", width: "300px", display: "flex", flexDirection: "column" }}>
			<Box sx={{ flex: 1, overflowY: "auto", gap: 2, p: ".5rem 1rem" }}>
				{/* Tenants Filter */}
				{user?.role === UserRole.ADMIN && (
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
						>
							TENANTS
						</Typography>
						<ReactSelect
							options={generateOptions(tenants?.data, "tenant_id", "name")}
							isSearchable
							onChange={handleTenantChange}
							placeholder='All Tenants'
							selectedOption={selectedTenantId ? generateOptions(tenants?.data, "tenant_id", "name").find((option) => option.value === selectedTenantId) : null}
						/>
					</Box>
				)}
				{/* Courses Filter */}
				<Box>
					<Typography
						level='h6'
						sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
					>
						COURSES
					</Typography>
					<ReactSelect
						options={generateCourseOptions(allCoursesNames?.data, "course_id", "name")}
						isSearchable
						onChange={handleCourseChange}
						placeholder='All Courses'
						selectedOption={selectedCourseId ? generateOptions(allCoursesNames?.data, "course_id", "name").find((option) => option.value === selectedCourseId) : null}
					/>
				</Box>

				{/* Status Filter */}
				<Box>
					<Typography
						level='h6'
						sx={{ my: 1, color: "#6e6e6f", opacity: 0.7 }}
					>
						STATUS
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Typography sx={{ fontSize: "14px", fontWeight: 600 }}>Active</Typography>
							<Checkbox
								checked={selectedStatus === "PUBLISHED"}
								onChange={() => handleCheckboxChange("PUBLISHED")}
							/>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Typography sx={{ fontSize: "14px", fontWeight: 600 }}>Draft</Typography>
							<Checkbox
								checked={selectedStatus === "PENDING"}
								onChange={() => handleCheckboxChange("PENDING")}
							/>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Actions */}
			<Box sx={{ p: 1, borderTop: "1px solid rgba(0, 0, 0, 0.30)" }}>
				<Button
					sx={{ width: "100%", mb: 2, bgcolor: "#fff", color: "#d55442", ":hover": { bgcolor: "#f4f4f4" } }}
					onClick={handleResetFilters}
					disabled={!selectedTenantId && !selectedCourseId && !selectedStatus}
				>
					Reset All Filters
				</Button>
				<Button
					sx={{ width: "100%", bgcolor: "#3838f1", color: "#fff" }}
					onClick={handleAddFilter}
				>
					Filter
				</Button>
			</Box>
		</Box>
	);
};

export default FilterOptions;
