import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const AddTask = async (tasks, nodeId) => {
	const response = await axios.post(`${API_URL}/task`, {
		tasks,
		node_id: nodeId,
	});
	return;
};

export const getTasksByNodeId = async (nodeId) => {
	const response = await axios.get(`${API_URL}/task/task-by-node?node_id=${nodeId}`);
	return response;
};

export const addManualTask = async (task) => {
	const response = await axios.post(`${API_URL}/task/add-task`, task, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response;
};

export const deleteTask = async (taskId, nodeId) => {
	try {
		const response = await axios.delete(`${API_URL}/task?task_id=${taskId}&node_id=${nodeId}`, {
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const updateTask = async (task) => {
	const response = await axios.patch(`${API_URL}/task/`, task, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response;
};

export const importTasks = async (tasks, nodeId) => {
	const response = await axios.post(
		`${API_URL}/task/import-tasks`,
		{
			task_ids: tasks,
			node_id: nodeId,
		},
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response;
};
