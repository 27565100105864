import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

const PreviewTable = ({ jsonData }) => {
	if (!jsonData || jsonData.length === 0) return null;

	const headers = Object.keys(jsonData[0]);

	return (
		<TableContainer
			component={Paper}
			sx={{ maxHeight: 300, maxWidth: "80vw", overflow: "auto" }}
		>
			<Table
				stickyHeader
				aria-label='sticky table'
			>
				<TableHead>
					<TableRow>
						{headers.map((header) => (
							<TableCell
								key={header}
								sx={{ px: 3, py: 1 }}
							>
								{header}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{jsonData.map((row, index) => (
						<TableRow
							key={index}
							sx={{ "&:nth-of-type(even)": { backgroundColor: "grey.100" } }}
						>
							{headers.map((header) => (
								<TableCell
									key={header}
									sx={{ px: 3, py: 1 }}
								>
									{row[header]}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default PreviewTable;
