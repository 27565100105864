import { Textarea, Button, Input, Card } from "@mui/joy";
import { Box, IconButton, Typography, Chip } from "@mui/material";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { addManualTaskThunk } from "../../../../Store/actions/taskAction";
import { addTask } from "../../../../Store/slices/taskDetailsSlice";
import { ReadingTaskTypes } from "../../../../Constants/task-types";

const DocForm = ({ setOpen, setShowTaskType }) => {
	const [taskTitle, setTaskTitle] = useState("");
	const [objective, setObjective] = useState("");
	const [selectedPoint, setSelectedPoint] = useState(20);
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [readingTaskType, setReadingTaskType] = useState(ReadingTaskTypes.DOC_UPLOAD);
	const [webUrl, setWebUrl] = useState("");
	const [urlError, setUrlError] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();

	const node_id = location.pathname.split("/")[4];

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		if (selectedFiles.length + files.length > 4) {
			return; // Max 4 files
		}
		let totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
		if (totalSize > 50000000) {
			return; // Max 50MB total
		}
		setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
	};

	const handleSubmit = async () => {
		if (taskTitle === "" || (files.length === 0 && webUrl === "")) return;
		// Add task to the list
		const task = new FormData();
		task.append("task_title", taskTitle);
		task.append("question", objective);
		task.append("points", selectedPoint);
		task.append("node_id", node_id);
		task.append("type", "DOC");
		files.forEach((file) => {
			task.append("docs", file);
		});
		// Append web URL if present
		if (webUrl) {
			task.append("web_url", webUrl);
		}

		setLoading(true);
		const response = await dispatch(addManualTaskThunk(task));

		if (response?.payload?.data) {
			setLoading(false);
			dispatch(addTask(response?.payload?.data));
			setOpen(false);
		}
	};

	const handleFileRemove = (index) => {
		const newFiles = files.filter((file, i) => i !== index);
		setFiles(newFiles);
	};

	const renderReadingTaskOptions = () => {
		return (
			<>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Type
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 2,
						}}
					>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setReadingTaskType(ReadingTaskTypes.DOC_UPLOAD)}
						>
							<Typography
								sx={{
									color: `${readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								File Upload
							</Typography>
						</Card>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${readingTaskType === ReadingTaskTypes.DOC_LINK ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${readingTaskType === ReadingTaskTypes.DOC_LINK ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setReadingTaskType(ReadingTaskTypes.DOC_LINK)}
						>
							<Typography
								sx={{
									color: `${readingTaskType === ReadingTaskTypes.DOC_LINK ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								Web URL
							</Typography>
						</Card>
					</Box>
				</Box>
				{readingTaskType === ReadingTaskTypes.DOC_UPLOAD ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 0.5, mt: 2, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Documents
						</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								border: "1px solid rgba(0,0,0,0.25)",
								borderRadius: "8px",
								p: 0.5,
							}}
						>
							<IconButton
								component='label'
								color='primary'
								sx={{
									borderRadius: "8px",
									width: "100%",
								}}
							>
								<Input
									type='file'
									slotProps={{
										input: {
											accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
											multiple: true,
										},
									}}
									sx={{ display: "none" }}
									onChange={handleFileChange}
								/>
								<UploadIcon />
								<Typography sx={{ marginX: 2 }}>{"Upload PDFs (max 4 files, max 50MB total)"}</Typography>
							</IconButton>
						</Box>
						{files.length > 0 && (
							<Box sx={{ display: "flex", gap: 1, my: 1, flexWrap: "wrap" }}>
								{files.map((file, index) => {
									return (
										<Chip
											label={file.name}
											onDelete={() => handleFileRemove(index)}
											sx={{
												maxWidth: "200px",
											}}
											color='info'
										/>
									);
								})}
							</Box>
						)}
					</Box>
				) : readingTaskType === ReadingTaskTypes.DOC_LINK && (
					<Box
						sx={{
							my: 2,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Web URL
						</Typography>
						<Input
							label='Web URL'
							type='url'
							value={webUrl}
							onChange={(e) => setWebUrl(e.target.value)}
							onBlur={() => {
								// Validate URL on blur
								if (!/^https?:\/\/.+\..+/.test(webUrl)) {
									setWebUrl(""); // Set webUrl to null on error
									setUrlError(true); // Set error state
								} else {
									setUrlError(false); // Clear error state if valid
								}
							}}
							sx={{ width: "100%" }}
							placeholder='e.g. https://example.com'
							required
						/>
						{/* Optional: Add validation message */}
						{urlError && (
							<Typography sx={{ color: "red", fontSize: "12px" }}>
								Please enter a valid URL.
							</Typography>
						)}
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Button
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						variant='outlined'
						onClick={() => {
							setStep(1);
							setShowTaskType(true);
						}}
					>
						Previous: Task Details
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							disabled={taskTitle === "" || objective === "" || (files.length === 0 && (urlError || webUrl === ""))}
							onClick={() => {
								setShowTaskType(false);
								handleSubmit();
							}}
							loading={loading}
						>
							Add Task
						</Button>
					</Box>
				</Box >
			</>
		);
	};

	const renderReadingTask = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
					<Box
						sx={{
							maxHeight: "35vh",
							overflowY: "auto",
							px: 2,
						}}
					>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Title
							</Typography>
							<Input
								label='Task title'
								type='text'
								slotProps={{
									input: {
										maxLength: 64,
									},
								}}
								value={taskTitle}
								onChange={(e) => setTaskTitle(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. Binary Search'
								required
							// error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
							/>
						</Box>
						<Box
							sx={{
								my: 2,
							}}
						>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Description
							</Typography>
							<Textarea
								label='Objective'
								value={objective}
								onChange={(e) => setObjective(e.target.value)}
								minRows={5}
								maxRows={10}
								type='text'
								slotProps={{
									textarea: {
										maxLength: 1024,
									},
								}}
								sx={{ width: "100%" }}
								placeholder='e.g. Compiler - Assembler Differences'
								required
							// error={objective?.trim() === "" ? "Please enter the objective" : ""}
							/>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ my: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Points
							</Typography>
							<Box sx={{ display: "flex", gap: 1 }}>
								{[1, 5, 10, 15, 20, 25].map((point) => (
									<Button
										key={point}
										variant='outlined'
										color='neutral'
										sx={{
											bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
											color: `${selectedPoint === point ? "white" : "black"}`,
											"&:hover": {
												bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
												color: `${selectedPoint === point ? "white" : "black"}`,
											},
										}}
										onClick={() => setSelectedPoint(point)}
									>
										{point}
									</Button>
								))}
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: 2,
							}}
						>
							<Button
								variant='outlined'
								onClick={() => {
									setOpen(false);
								}}
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
							>
								Cancel
							</Button>
							<Button
								sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
								color='primary'
								// disabled={taskTitle === "" || files.length === 0}
								onClick={() => {
									setStep(2);
									setShowTaskType(false);
								}}
							>
								Next:Additional Info
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		);

	};

	const renderSteps = () => {
		switch (step) {
			case 1:
				return renderReadingTask();
			case 2:
				return renderReadingTaskOptions();

			default:
				return renderReadingTask();
		}
	};

	return renderSteps();
};

export default DocForm;
