import { createAsyncThunk } from "@reduxjs/toolkit";
import { addManualTask, AddTask, deleteTask, getTasksByNodeId, importTasks, updateTask } from "../services/taskApiServices";

export const AddTaskThunk = createAsyncThunk("task/AddTask", async ({ tasks, nodeId, navigate, courseId }, { rejectWithValue }) => {
	try {
		const response = await AddTask(tasks, nodeId);
		alert("Task Added successfully");
		//detect the current path and navigate to the next path
		navigate(`/course/${courseId}/topic/${nodeId}`);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message || "Error in adding task");
	}
});

export const getTasksByNodeIdThunk = createAsyncThunk("task/getTasksByNodeId", async (nodeId, { rejectWithValue }) => {
	try {
		const response = await getTasksByNodeId(nodeId);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getAllTasksByNodeIdThunk = createAsyncThunk("task/getAllTasksByNodeId", async (nodeId, { rejectWithValue }) => {
	try {
		const response = await getTasksByNodeId(nodeId);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const addManualTaskThunk = createAsyncThunk("task/addManualTask", async (task, { rejectWithValue }) => {
	try {
		const response = await addManualTask(task);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const deleteTaskThunk = createAsyncThunk("task/deleteTask", async ({ task_id, node_id }, { rejectWithValue }) => {
	try {
		const response = await deleteTask(task_id, node_id);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const updateTaskThunk = createAsyncThunk("task/updateTask", async (task, { rejectWithValue }) => {
	try {
		const response = await updateTask(task);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const importTasksThunk = createAsyncThunk("task/importTasks", async ({ tasks, nodeId }, { rejectWithValue }) => {
	try {
		const response = await importTasks(tasks, nodeId);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});
