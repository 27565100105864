import { Textarea, Button, Input } from "@mui/joy";
import { Box, IconButton, Typography, Chip } from "@mui/material";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateTaskThunk } from "../../../../Store/actions/taskAction";
import { updateTaskData } from "../../../../Store/slices/taskDetailsSlice";
import ReactPlayer from "react-player";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";

const VideoEditForm = ({ setOpen, task }) => {
	const [taskTitle, setTaskTitle] = useState(task?.task_title);
	const [videoUrl, setVideoUrl] = useState(task?.url);
	const [selectedPoint, setSelectedPoint] = useState(task?.points);
	const [loading, setLoading] = useState(false);
	const [duration_minutes, setDuration] = useState(task?.duration_minutes);
	const [task_id, setTaskID] = useState(task?.task_id);
	const [video_id, setVideoID] = useState(task?.video_id);
	const [urlError, setUrlError] = useState(""); // State for URL error message
	const [step, setStep] = useState(1);
	const [summary, setSummary] = useState(task?.summary);
	const [initialVideoUrl, setInitialVideoUrl] = useState(task?.url);
	const summaryData = task?.summary ? JSON.parse(task.summary) : null; // Parse the summary JSON

	const location = useLocation();
	const dispatch = useDispatch();

	const node_id = location.pathname.split("/")[4];

	const handleSubmit = async () => {
		if (taskTitle === "" || videoUrl === "") return;
		// Add task to the list
		const task = new FormData();
		task.append("task_title", taskTitle);
		task.append("videoUrl", videoUrl);
		task.append("points", selectedPoint);
		task.append("node_id", node_id);
		task.append("type", "VID");
		task.append("task_id", task_id);
		task.append("video_id", video_id);
		task.append("duration_minutes", duration_minutes);
		task.append("summary", summary);

		// Check if the video URL has changed
		if (videoUrl !== initialVideoUrl) { // Compare with the initial URL
			task.append("url_changed", "true"); // Attach a key to indicate URL change
			dispatch(showToast({ toastMessage: "Summary generation in progress. The summary will be updated.", toastType: ToastType.PRIMARY }));
		}

		// Dispatch an action to update the task
		setLoading(true);
		const response = await dispatch(updateTaskThunk(task));
		if (response?.payload?.data) {
			dispatch(updateTaskData(response.payload.data));
			setLoading(false);
			setOpen(false);
		}
	};

	const renderSummaryContent = () => {
		if (!summaryData) {
			return <Typography>Summary is not generated yet.</Typography>;
		}

		const { language, topics, points_to_remember } = summaryData.summary;

		return (
			<Box sx={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #f4f4f4', borderRadius: '4px', padding: '8px', backgroundColor: '#f9f9f9' }}>
				{/* <Typography variant="h6" sx={{ mb: 2 }}>Summary in {language}</Typography> */}
				{topics.map((topic, index) => (
					<Box key={index} sx={{ mb: 2 ,pl:2}}>
						<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{topic.topic}</Typography>
						<Typography variant="body2" sx={{ fontStyle: 'italic',pl: 2  }}>{topic.starting_sentence}</Typography>
						<Typography variant="body2" sx={{pl: 2 }}>{topic.explanation}</Typography>
						<Typography variant="body2" sx={{ fontWeight: 'bold',pl: 2  }}>Examples:</Typography>
						<Typography variant="body2" sx={{ mb: 1,pl: 2  }}>{topic.examples}</Typography>
					</Box>
				))}
				<Typography variant="h6" sx={{ mt: 2,pl: 2  }}>Points to Remember</Typography>
				<Box sx={{ pl: 4 }}>
					{points_to_remember.map((point, index) => (
						<Typography key={index} variant="body2">- {point}</Typography>
					))}
				</Box>
			</Box>
		);
	};

	const renderWatchingTaskOptions = () => {
		return (
			<Box>
				<Box>
					<Typography
						level="h6"
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Summary
					</Typography>
					{renderSummaryContent()} 
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Button
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						variant='outlined'
						onClick={() => {
							setStep(1);
						}}
					>
						Previous: Task Details
					</Button>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							loading={loading}
							disabled={
								loading ||
								!taskTitle ||
								!videoUrl ||
								!duration_minutes ||
								!selectedPoint
							}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};
	const renderWatchingTask = () => {
		return (
			<Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Box>
							<Typography
								sx={{ fontSize: "12px" }}
								level='body-md'
							>
								{videoUrl}
							</Typography>
						</Box>
						<Box>
							<Typography
								sx={{ fontSize: "12px" }}
								level='body-md'
							>
								{duration_minutes}mins
							</Typography>
						</Box>
						<Box>
							<Typography
								sx={{ fontSize: "12px" }}
								level='body-md'
							>
								{selectedPoint || 5}pts
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							borderRadius: "8px",
							padding: " 1rem",
							backgroundColor: "#f4f4f4",
							height: "200px",
						}}
					>
						{videoUrl ? (
							<ReactPlayer
								url={videoUrl}
								controls
								width='100%'
								height='100%'
								style={{ borderRadius: "8px", height: "100%", backgroundColor: "black" }}
							/>
						) : (
							<Box>No Video Found</Box>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
								maxHeight: "20vh",
								overflowY: "auto",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: 2,
								}}
							>
								<Box
									sx={{
										width: "100%",
									}}
								>
									<Typography
										level='h6'
										sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
									>
										Video Title
									</Typography>
									<Input
										value={taskTitle}
										label='Video Name'
										type='text'
										sx={{ width: "100%" }}
										onChange={(e) => setTaskTitle(e.target.value)}
									/>
								</Box>
							</Box>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Video Url
								</Typography>
								<Input
									value={videoUrl}
									label='Video Name'
									type='text'
									sx={{ width: "100%" }}
									onChange={(e) => {
										const url = e.target.value;
										setVideoUrl(url); // Store the URL without validation
									}}
									onBlur={() => {
										// Validate the URL when the input loses focus
										try {
											new URL(videoUrl);
											setUrlError(""); // Clear error if valid
										} catch {
											// Set error message for invalid URL
											setVideoUrl("");
											setUrlError("Please enter a valid URL.");
										}
									}}
								/>
								{urlError && (
									<Typography sx={{ color: "red", fontSize: "12px" }}>
										{urlError}
									</Typography>
								)}
							</Box>
						</Box>

						<Box>

							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Duration (in minutes)
							</Typography>
							<Input
								type="number"
								placeholder='e.g. 30'
								value={duration_minutes}
								onChange={(e) => {
									// Only allow positive numbers
									const value = Math.max(0, parseInt(e.target.value) || 0);
									setDuration(value);
								}}
								slotProps={{
									input: {
										min: 0,
									}
								}}
								sx={{
									width: "100px",  // Make the box smaller
									'& input': {
										textAlign: 'center'  // Center the number
									}
								}}
							/>
						</Box>

						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Points
							</Typography>
							<Box sx={{ display: "flex", gap: 1 }}>
								{[1, 5, 10, 15, 20, 25].map((point) => (
									<Button
										key={point}
										variant='outlined'
										color='neutral'
										sx={{
											bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
											color: `${selectedPoint === point ? "white" : "black"}`,
											"&:hover": {
												bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
												color: `${selectedPoint === point ? "white" : "black"}`,
											},
										}}
										onClick={() => setSelectedPoint(point)}
									>
										{point}
									</Button>
								))}
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: 2,
							}}
						>
							<Button
								variant='outlined'
								onClick={() => {
									setOpen(false);
								}}
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
							>
								Cancel
							</Button>
							<Button
								sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
								color='primary'
								// disabled={taskTitle === "" || files.length === 0}
								onClick={() => {
									setStep(2);
								}}
							>
								Next:Additional Info
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderSteps = () => {
		switch (step) {
			case 1:
				return renderWatchingTask();
			case 2:
				return renderWatchingTaskOptions();

			default:
				return renderWatchingTask();
		}
	};

	return renderSteps();
};

export default VideoEditForm;
