import {
	Button,
	Dropdown,
	Menu,
	MenuButton,
	MenuItem,
	Modal,
	ModalClose,
	ModalDialog,
	Input,
} from "@mui/joy";
import { Chip, Box, IconButton, Popper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddNewEvent from "../../event/create-event";
import AddIcon from "@mui/icons-material/Add";
import { fetchEventsByBatchIdThunk, deleteEventForBatchThunk, AddEventURLForBatchThunk } from "../../../../Store/actions/batchEventAction";
import { useDispatch } from "react-redux";
import { LocalizationProvider, StaticDateTimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Loader from "../../../utils/Loader";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";
import { UserRole } from "../../../../Constants/user-role";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EventEditForm from "../../event/edit-event";
import { deleteEvent } from "../../../../Store/slices/batchEventSlice";
import AddEventUrlModal from "../../event/event-url";

const BatchEvents = () => {
	const [open, setOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const [openNodeId, setOpenNodeId] = useState(null);
	const [showEventDeleteModal, setShowEventDeleteModal] = useState(null);
	const [showEventEditModal, setShowEventEditModal] = useState(null);
	const [showEventRecAddModal, setShowEventRecAddModal] = useState(null);

	const { batchDetails } = useSelector((state) => state.batch);
	const { events, loading: eventLoader, error } = useSelector((state) => state.batchEvent);
	const { user } = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (batchDetails?.data?.batch_id) dispatch(fetchEventsByBatchIdThunk(batchDetails?.data?.batch_id));
	}, []);

	const handleDateChange = (date) => {
		// setDate(formattedDate);
	};

	const handleClick = (event, nodeId) => {
		setOpenNodeId((prev) => (prev === nodeId ? null : nodeId));
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleAccept = (event) => {};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenNodeId(null);
	};

	const meetingTypes = {
		GOOGLE_MEET: "G-meet",
		ZOOM: "Zoom",
		// Add other meeting types if necessary
	};

	const getMeetingTypeLabel = (type) => meetingTypes[type] || type;

	const renderNewEventModal = () => {
		return (
			<Modal
				aria-labelledby='modal-title'
				aria-describedby='modal-desc'
				open={open}
				onClose={() => setOpen(false)}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose
						variant='plain'
						sx={{ m: 1 }}
					/>
					<Typography
						component='h2'
						id='modal-title'
						level='h4'
						textColor='inherit'
						fontWeight='lg'
						my={1}
					>
						Add New Event
					</Typography>
					<AddNewEvent
						batch_id={batchDetails?.data?.batch_id}
						setOpen={setOpen}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderDateTimePicker = (event) => {
		const dateValue = event?.start_time ? DateTime.fromISO(event?.start_time) : null;
		const isValidDate = dateValue && dateValue.isValid;
		return (
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<Box sx={{ width: "10%", mr: 2 }}>
					{/* <IconButton
						component='label'
						variant='contained'
						color='primary'
						aria-describedby={event?.event_id}
						sx={{
							borderRadius: "md",
						}}
						onClick={(event) => handleClick(event, event?.event_id)}
					> */}
					<Typography
						sx={{
							borderBottom: !isValidDate ? "2px solid black" : "none",
							width: "100%",
							height: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{isValidDate ? dateValue.toFormat("dd MMM") : ""}
					</Typography>
					{/* </IconButton> */}

					<Popper
						id={event.event_id}
						open={openNodeId === event?.event_id}
						anchorEl={anchorEl}
						placement='bottom'
						sx={{ zIndex: 1000, border: "1px solid black" }}
					>
						<StaticDateTimePicker
							onChange={handleDateChange}
							onAccept={() => handleAccept(event)}
							onClose={handleClose}
							value={isValidDate ? dateValue : null}
							minDate={DateTime.fromISO(batchDetails?.data?.start_date)}
							maxDate={DateTime.fromISO(batchDetails?.data?.end_date)}
							sx={{ height: "20rem", overflowY: "auto", backgroundColor: "white" }}
						/>
					</Popper>
				</Box>
			</LocalizationProvider>
		);
	};

	const handleDelete = async (eventId) => {
		try {
			setLoading(true);
			const response = await dispatch(deleteEventForBatchThunk(eventId));
			if (response?.payload.success) {
				dispatch(
					deleteEvent(eventId)  // Create this action in your slice
				);
				setLoading(false);
				setShowEventDeleteModal("");
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const renderDeleteModal = (event) => {
		return (
			<Modal
				open={showEventDeleteModal === event.event_id}
				onClose={() => setShowEventDeleteModal("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to delete this event?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							color='primary'
							onClick={() => setShowEventDeleteModal("")}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							onClick={() => handleDelete(event.event_id)}
							loading={loading}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderEventUrlModal = (event) => {
		return (
			<Modal
				open={showEventRecAddModal === event.event_id}
				onClose={() => setShowEventRecAddModal("")}
			>
				<ModalDialog
					sx={{ width: 600 }}
					onClick={(e) => e.stopPropagation()}
				>
					<ModalClose onClick={(e) => e.stopPropagation()} />
					<Typography level='h4'>Add Recording URL in {event?.name}</Typography>
					<AddEventUrlModal
						event={event}
						setShowEventRecAddModal={setShowEventRecAddModal}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderEditModal = (event) => {
		return (
			<Modal
				open={showEventEditModal === event.event_id}
				onClose={() => setShowEventEditModal("")}
			>
				<ModalDialog
					sx={{
						width: 800,
					}}
				>
					<ModalClose />
					<Typography level='h4'>Edit Event</Typography>
					<EventEditForm
						event={event}
						setShowEventEditModal={setShowEventEditModal}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderEventCard = (event) => {
		const getDuration = () => {
			const startTime = DateTime.fromISO(event?.start_time).setZone("Asia/Calcutta");
			const endTime = DateTime.fromISO(event?.end_time).setZone("Asia/Calcutta");

			const duration = endTime.diff(startTime, ["hours", "minutes"]).toObject();

			return `${duration.hours < 0 ? `${-duration?.hours}` : duration?.hours}hrs ${duration?.minutes ? `${duration.minutes.toFixed()}mins` : ""}`;
		};

		const isEventCompleted = () => {
			const endTime = DateTime.fromISO(event?.end_time);
			return DateTime.now() > endTime; // Check if current time is greater than event end time
		};

		return (
			<Box sx={{ display: "flex", alignItems: "center", width: "100%", overflowX: "hidden", my: 2 }}>
				{renderDateTimePicker(event)}
				<Box sx={{ backgroundColor: "#f4f4f4", width: "95%", display: "flex", alignItems: "center", p: 1, borderRadius: "10px" }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", px: 3 }}>
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>{event?.name}</Typography>

							{event?.recording_url && ( // Check if recording_url exists
								<Tooltip title='Open Recording link'>
									<Link
										to={event.recording_url} // Use event.recording_url directly
										target='_blank'
									>
										<Chip
											label='Recording'
											icon={<OpenInNewIcon sx={{ fontSize: "1rem" }} />}
											clickable
											variant='outlined'
											color='info'
											style={{ marginRight: 8, px: 5, width: "100px" }}
										/>
									</Link>
								</Tooltip>
							)}
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{event?.instructor_name}
							</Typography>
							<Tooltip title='Open meeting link'>
								<Link
									to={event?.meeting_url}
									target='_blank'
								>
									<Chip
										label={getMeetingTypeLabel(event?.meeting_type)}
										icon={<OpenInNewIcon sx={{ fontSize: "1rem" }} />}
										clickable
										variant='outlined'
										color='info'
										style={{ marginRight: 8, px: 5, width: "100px" }}
									/>
								</Link>
							</Tooltip>
							{event?.event_image && (
								<img
									src={event?.event_image}
									alt='event_img'
									style={{ width: "40px", height: "40px" }}
								/>
							)}

							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{getDuration()}
							</Typography>
							<Dropdown>
								<MenuButton
									sx={{
										backgroundColor: "transparent",
										border: "none",
										cursor: "pointer",
										padding: 0,
									}}
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									<MoreHorizIcon />
								</MenuButton>
								<Menu
									modifiers={[
										{
											name: "offset",
											options: {
												offset: ({ placement }) => {
													if (placement.includes("end")) {
														return [8, 20];
													}
													return [50, -20];
												},
											},
										},
									]}
								>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation();
											setShowEventEditModal(event.event_id);
										}}
									>
										Edit
									</MenuItem>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation();
											setShowEventDeleteModal(event.event_id);
										}}
									>
										<Typography color='danger'>Delete</Typography>
									</MenuItem>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation();
											if (isEventCompleted()) {
												setShowEventRecAddModal(event.event_id);
											}
										}}
									>
										<Typography color='danger' sx={{ opacity: isEventCompleted() ? 1 : 0.5 }}>
											Add Recording URL
										</Typography>
									</MenuItem>
								</Menu>
							</Dropdown>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderEvents = () => {
		if (eventLoader) return <Loader />;
		else if (events?.length > 0) {
			return (
				<Box sx={{ maxHeight: "40vh", overflowY: "auto", borderRadius: "8px", my: 2, px: 2 }}>
					{events?.map((event, index) => {
						return (
							<>
								{renderEventCard(event)}
								{showEventDeleteModal === event.event_id && renderDeleteModal(event)}
								{showEventEditModal === event.event_id && renderEditModal(event)}
								{showEventRecAddModal === event.event_id && renderEventUrlModal(event)}
							</>
						);
					})}
				</Box>
			);
		} else if (error) {
			dispatch(showToast({ toastMessage: error, toastType: ToastType.ERROR }));
			setTimeout(() => navigate("/batches"), 2000);
			return <div>{error}</div>;
		} else {
			return <div>No events found</div>;
		}
	};

	return (
		<Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #888", px: 1 }}>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Batch Events</Typography>
				<Button
					variant='primary'
					disabled={user?.role === UserRole.TEACHER}
					sx={{ display: "flex", alignItems: "center", color: "white", gap: 0.3, backgroundColor: "#3838F1", fontSize: "1rem", fontWeight: 600, cursor: "pointer", mb: 1 }}
					onClick={() => setOpen(true)}
				>
					<AddIcon />
					Add Event
				</Button>
			</Box>
			{renderEvents()}
			{renderNewEventModal()}
		</Box>
	);
};

export default BatchEvents;
