import { createSlice, current } from "@reduxjs/toolkit";

import { addBatchThunk, fetchBatchByIdThunk, fetchBatchesThunk, fetchStudentsByBatchIdThunk } from "../actions/batchAction";
import { batchType } from "../../Constants/batch-tabs";
import { fetchAllTenantsThunk, getCoursesByTenantIdThunk } from "../actions/tenantAction";
import { fetchTeacherByBatchId } from "../actions/userAction";

const initialState = {
	batches: [],
	tenants: [],
	courses: [],
	teacherInfos: [],
	students: [],
	batchDetails: null,
	selectedTenant: null,
	selectedCourse: null,
	activeTab: batchType.batchDetails,
	isFormFilled: {
		batchDetails: false,
		verifyStudents: false,
		scheduleCourse: false,
		teacherDetails: false,
	},
	updatedBatchInfo: null,
	loading: false,
	teacherLoading: false,
	batchLoading: false,
	error: null,
	unsavedChanges: null,
	filters: {},
};

const batchSlice = createSlice({
	name: "batch",
	initialState,
	reducers: {
		clearAll: (state) => {
			state.batches = [];
			state.tenants = [];
			state.courses = [];
			state.students = [];
			state.batchDetails = null;
			state.selectedTenant = null;
			state.selectedCourse = null;
			state.activeTab = batchType.batchDetails;
			state.isFormFilled = {
				batchDetails: false,
				verifyStudents: false,
				scheduleCourse: false,
				teacherDetails: false,
			};
			state.loading = false;
			state.error = null;
		},
		deleteBatchFromBatches: (state, action) => {
			let currentBatches = current(state).batches;
			state.batches = {
				...state?.batches,
				data: { data: state.batches?.data?.data?.filter((batch) => batch?.batch_id !== action.payload) },
			};
		},
		selectTenant(state, action) {
			state.selectedTenant = action.payload;
			state.courses = [];
		},
		setFilters(state, action) {
			state.filters = { ...state.filters, ...action.payload };
		},
		clearFilters(state) {
			state.filters = {};
		},

		selectCourse(state, action) {
			state.selectedCourse = action.payload;
		},
		setFormFilled(state, action) {
			const { tab, filled } = action.payload;
			state.isFormFilled[tab] = filled;
		},
		setActiveTab: (state, action) => {
			state.activeTab = action.payload;
		},
		setEmptyFormFilled: (state) => {
			state.isFormFilled = {
				batchDetails: false,
				verifyStudents: false,
				scheduleCourse: false,
				teacherDetails: false,
			};
		},
		addTeacherInfo: (state, action) => {
			let currentState = current(state).teacherInfos;
			if (currentState === null) {
				state.teacherInfos = {
					...currentState,
					data: [{ instructors: action.payload }],
				};
			} else {
				state.teacherInfos = {
					...currentState,
					data: [...currentState?.data, { instructors: action.payload }],
				};
			}
		},
		addStudentData: (state, action) => {
			const currentState = current(state).students;

			// Check if the email already exists in the array
			const isEmailExists = currentState?.data?.data?.some((student) => student?.student?.email === action.payload.email);

			if (isEmailExists) {
				return;
			}

			// Add the new student if email doesn't exist
			state.students = {
				...state?.students,
				data: {
					data: [
						{
							invite_status: "PENDING",
							status: "PENDING",
							student: action.payload,
							student_id: action.payload.student_id,
						},
						...(currentState?.data?.data || []),
					],
				},
			};
		},
		removeStudentData: (state, action) => {
			const currentState = current(state).students;
		
			// Filter out the student with the given student_id
			const updatedStudents = currentState?.data?.data?.filter(
				(student) => student.student_id !== action.payload.student_id
			);
		
			// Update the state with the new list of students
			state.students = {
				...state.students,
				data: {
					data: updatedStudents || [],
				},
			};
		},
		removeTeacherInfo: (state, action) => {
			let currentState = current(state).teacherInfos;
			state.teacherInfos = {
				...currentState,
				data: currentState?.data?.filter((teacher) => teacher.instructors.instructor_id !== action.payload),
			};
		},
		setUnsavedChanges: (state, action) => {
			state.unsavedChanges = action.payload;
		},
		setUpdatedBatchInfo: (state, action) => {
			state.updatedBatchInfo = action.payload;
		},
		updateBatch: (state, action) => {
			let currentBatch = current(state).batchDetails;
			state.batchDetails = {
				...currentBatch,
				data: {
					...currentBatch.data,
					end_date: action.payload.end_date,
					start_date: action.payload.start_date,
					name: action.payload.name,
					external_batch_id: action.payload.external_batch_id,
				},
			};
		},
		updateStudentData: (state, action) => {
			state.students = {
				...state.students,
				data: {
					data: state.students?.data?.data?.map((student) => {
						if (student.student_id === action.payload.student_id) {
							return {
								...student,
								student: { ...action.payload },
							};
						}
						return student;
					}),
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBatchesThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchBatchesThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.batches = action.payload;
				state.error = null;
			})
			.addCase(fetchBatchesThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(addBatchThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(addBatchThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.batchDetails = action.payload;
			})
			.addCase(addBatchThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchAllTenantsThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchAllTenantsThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.tenants = action.payload;
			})
			.addCase(fetchAllTenantsThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(getCoursesByTenantIdThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCoursesByTenantIdThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.courses = action.payload;
			})
			.addCase(getCoursesByTenantIdThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchStudentsByBatchIdThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchStudentsByBatchIdThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.students = action.payload;
			})
			.addCase(fetchStudentsByBatchIdThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchBatchByIdThunk.pending, (state) => {
				state.batchLoading = true;
				state.error = null;
			})
			.addCase(fetchBatchByIdThunk.fulfilled, (state, action) => {
				state.batchLoading = false;
				state.batchDetails = action.payload;
			})
			.addCase(fetchBatchByIdThunk.rejected, (state, action) => {
				state.batchLoading = false;
				state.error = action.payload;
			});
		builder
			.addCase(fetchTeacherByBatchId.pending, (state) => {
				state.teacherLoading = true;
				state.error = null;
			})
			.addCase(fetchTeacherByBatchId.fulfilled, (state, action) => {
				state.teacherLoading = false;
				state.teacherInfos = action.payload;
			})
			.addCase(fetchTeacherByBatchId.rejected, (state, action) => {
				state.teacherLoading = false;
				state.error = action.payload;
			});
	},
});

export const {
	setActiveTab,
	selectCourse,
	selectTenant,
	setFormFilled,
	clearAll,
	deleteBatchFromBatches,
	setEmptyFormFilled,
	addTeacherInfo,
	setUnsavedChanges,
	setUpdatedBatchInfo,
	updateBatch,
	removeTeacherInfo,
	updateStudentData,
	setFilters,
	clearFilters,
	addStudentData,
	removeStudentData,
} = batchSlice.actions;
export default batchSlice.reducer;
