import React, { useState, useEffect } from "react";
import { Box, Button, Input, Typography } from "@mui/joy";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../../Constants/toast-type";
import { updateStudentThunk } from "../../../../../Store/actions/studentAction";
import { updateStudentData } from "../../../../../Store/slices/batchSlice";
import ReactSelect from "../../../../utils/search-select";
import { fetchCollegeNames } from "../../../../../Store/services/studentApiService";

const EditStudentDetails = ({ student, setIsEditModalOpen }) => {
	const [studentEmail, setStudentEmail] = useState(student.email);
	const [studentName, setStudentName] = useState(student.name);
	const [studentPhone, setStudentPhone] = useState(student.mobile_number);
	const [editLoader, setEditLoader] = useState(false);

	const [studentCollegeName, setStudentCollegeName] = useState(student?.college_details?.college_name);
	const [collegeOptions, setCollegeOptions] = useState();
	const [newCollegeName, setNewCollegeName] = useState(""); // State for new college name

	const getColleges = async () => {
		try {
			const response = await fetchCollegeNames();
			if (response.success) {
				const collegeNames = response.data.map(college => college.college_name);
				setCollegeOptions(collegeNames);
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		getColleges();
	}, []);

	const handleAddCollege = () => {
		if (newCollegeName && !collegeOptions.includes(newCollegeName)) {
			setCollegeOptions([...collegeOptions, newCollegeName]);
			setStudentCollegeName(newCollegeName);
			setNewCollegeName(""); // Clear the input after adding
		}
	};

	const handleCollegeChange = (selectedOption) => {
		if (selectedOption === "add_new") {
			setStudentCollegeName("add_new");
		} else {
			setStudentCollegeName(selectedOption);
		}
	};

	// Generate dropdown options
	const generateOptions = (data) => [
		...data?.map((item) => ({ value: item, label: item })) || [],
		{ value: "add_new", label: "Add New College" }, // Add new college option
	];


	const dispatch = useDispatch();

	const handleEditStudent = async () => {
		if (!studentName || !studentEmail || studentEmail.trim() === "" || studentName.trim() === "") {
			dispatch(showToast({ toastMessage: "Please fill all the fields", toastType: ToastType.ERROR }));
			return;
		}

		if (studentEmail === student.email && studentName === student.name && studentCollegeName===student?.college_details?.college_name) {
			dispatch(showToast({ toastMessage: "No changes to update", toastType: ToastType.ERROR }));
			return;
		}

		setEditLoader(true);

		try {
			const response = await dispatch(
				updateStudentThunk({
					studentId: student.student_id,
					studentData: { name: studentName.trim(), email: studentEmail.trim(), mobile_number: studentPhone, college_details: { college_name: studentCollegeName } },
				})
			);

			if (response?.payload?.data) {
				dispatch(
					updateStudentData({
						student_id: student.student_id,
						name: studentName,
						email: studentEmail,
						mobile_number: studentPhone,
						college_details: { college_name: studentCollegeName },
					})
				);
				dispatch(showToast({ toastMessage: "Student details updated successfully", toastType: ToastType.SUCCESS }));
				setIsEditModalOpen("");
			} else if (response?.payload) {
				// Check for specific error
				const errorMessage = response.payload?.message || response.payload; // Adjust based on the response structure
				if (errorMessage.includes("duplicate key value") && errorMessage.includes("student_email_unique")) {
					dispatch(
						showToast({
							toastMessage: "Student with this email Id already exists. Please check the email id or contact your administrator.",
							toastType: ToastType.ERROR,
							toastDuration: 5000,
						})
					);
				} else {
					dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
				}
			}
		} catch (error) {
			console.error(error);
			dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
		} finally {
			setEditLoader(false);
		}
	};

	const renderStudentDetails = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							justifyContent: "space-between",
						}}
					>
						<Box
							sx={{
								width: "100%",
							}}
						>
							<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Name</Typography>
							<Input
								value={studentName}
								placeholder='Enter Student Name'
								onChange={(e) => setStudentName(e.target.value)}
							/>
						</Box>
						{/* {student?.college_details?.college_name && ( */}
							<Box
								sx={{
									width: "100%",
								}}
							>
								<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>College Name</Typography>
								{/* <Input
									value={student?.college_details?.college_name}
									placeholder='Enter College Name'
									readOnly={true}
								/> */}
								{collegeOptions ? (
								<ReactSelect
									options={generateOptions(collegeOptions, "name", "name")}
									isSearchable
									onChange={handleCollegeChange}
									placeholder='All colleges'
									value={
										studentCollegeName
											? generateOptions(collegeOptions).find(
												(option) => option.value === studentCollegeName
											)
											: null
									}
								/>
							) : (
								<p>Loading options...</p>
							)}
							{studentCollegeName === "add_new" && (
								<Box sx={{ display: "flex", mt: 1 }}>
									<Input
										value={newCollegeName}
										placeholder="Enter New College Name"
										onChange={(e) => setNewCollegeName(e.target.value)}
										sx={{
											flex: 1,
											marginRight: "8px",
										}}
									/>
									<Button
										onClick={handleAddCollege}
										variant="contained"
									>
										Add
									</Button>
								</Box>
							)}
							</Box>
						{/* )} */}
					</Box>
				</Box>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Email</Typography>
					<Input
						value={studentEmail}
						placeholder='Enter Student Email'
						onChange={(e) => setStudentEmail(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Phone</Typography>
					<Input
						value={studentPhone}
						placeholder='Enter Student Phone'
						onChange={(e) => setStudentPhone(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<Button
						sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
						color='primary'
						onClick={handleEditStudent}
						loading={editLoader}
					>
						Save
					</Button>
				</Box>
			</Box>
		);
	};

	return <Box>{renderStudentDetails()}</Box>;
};

export default EditStudentDetails;
