import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, IconButton, Input, Modal, ModalClose, ModalDialog, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addModuleThunk, addTopicThunk, updateTopicThunk } from "../../../Store/actions/courseAction";
import { useDispatch } from "react-redux";
import { addModuleData, addTopicNode, updateModuleNodeData, updateNodeData } from "../../../Store/slices/courseSlice";
import { Link, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ToastType } from "../../../Constants/toast-type";
import { showToast } from "../../../Store/slices/commonSlice";

const CourseContent = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [showAddModule, setShowAddModule] = useState(false);
	const [showAddTopic, setShowAddTopic] = useState(null);
	const [addModuleLoader, setAddModuleLoader] = useState(false);
	const [topicName, setTopicName] = useState("");
	const [topicDescription, setTopicDescription] = useState("");
	const [topicLoader, setTopicLoader] = useState(false);
	const [moduleName, setModuleName] = useState("");
	const [editingModuleId, setEditingModuleId] = useState(null);
	const [editedModuleName, setEditedModuleName] = useState("");

	const { courseData } = useSelector((state) => state.course);

	const courseId = location?.pathname?.split("/")[2];

	const handleAddModule = async () => {
		if (!moduleName) {
			return;
		}
		setAddModuleLoader(true);
		const response = await dispatch(addModuleThunk({ rootNodeId: courseData?.data?.rootNodeId || courseData?.data?.children[0]?.parent_node_id, moduleData: { moduleName, courseId } }));
		if (response?.payload?.data) {
			setAddModuleLoader(false);
			dispatch(addModuleData({ moduleData: response?.payload }));
			setShowAddModule(false);
			setModuleName("");
		}
	};

	const handleAddTopic = async () => {
		if (!topicName) return;
		setTopicLoader(true);
		const response = await dispatch(addTopicThunk({ moduleNodeId: showAddTopic, topicData: { courseId, topicName, topicDescription } }));
		if (response?.payload?.data) {
			dispatch(addTopicNode({ topicData: response?.payload, moduleNodeId: showAddTopic }));
			setShowAddTopic(null);
			setTopicName("");
			setTopicDescription("");
			setTopicLoader(false);
		}
	};

	const handleEditModule = (module) => {
		setEditingModuleId(module.node_id);
		setEditedModuleName(module.node_name);
	};

	const handleSaveModuleName = async (e) => {
		e.stopPropagation();
		if (editedModuleName.trim() === "") return;
		const moduleData = {
			node_id: editingModuleId,
			node_name: editedModuleName,
		};
		const response = await dispatch(updateTopicThunk(moduleData));
		setEditingModuleId(null);
		if (response?.payload?.data) {
			dispatch(updateModuleNodeData(response?.payload?.data));
			dispatch(showToast({ toastMessage: "Module updated successfully", toastType: ToastType.SUCCESS }));
		}
	};

	const renderAddModule = () => {
		return (
			<Modal
				open={showAddModule}
				onClose={() => {
					setShowAddModule(false);
				}}
			>
				<ModalDialog sx={{ width: 600 }}>
					<ModalClose />
					<Typography level='h4'>Add New Module</Typography>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Name of the module
						</Typography>
						<Input
							slotProps={{
								input: {
									maxLength: 64,
								},
							}}
							value={moduleName}
							onChange={(e) => {
								setModuleName(e.target.value);
							}}
							placeholder='e.g. Introduction to Python'
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							mt: 2,
						}}
					>
						<Button
							variant='primary'
							sx={{ backgroundColor: "#3838F1", fontWeight: "600", fontSize: "14px", borderRadius: "4px", color: "white" }}
							onClick={handleAddModule}
							loading={addModuleLoader}
						>
							Add Module
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderAddTopic = (moduleId, moduleName) => {
		return (
			<Modal
				open={showAddTopic === moduleId}
				onClose={(e) => {
					e.stopPropagation();
					setShowAddTopic(null);
				}}
			>
				<ModalDialog
					sx={{ width: 600 }}
					onClick={(e) => e.stopPropagation()}
				>
					<ModalClose onClick={(e) => e.stopPropagation()} />
					<Typography level='h4'>Add New Topic in {moduleName}</Typography>
					<Box>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Name</Typography>
						<Input
							placeholder='e.g. Compilers & Assemblers'
							label='Topic Name'
							sx={{ mb: 2 }}
							slotProps={{
								input: {
									autoFocus: true,
									maxLength: 64,
								},
							}}
							value={topicName}
							onChange={(e) => setTopicName(e.target.value)}
						/>
					</Box>
					<Box>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Description</Typography>
						<Textarea
							minRows={3}
							placeholder='Describe in a few lines what the topic is about.'
							label='Topic Description'
							value={topicDescription}
							slotProps={{
								textarea: {
									maxLength: 128,
								},
							}}
							onChange={(e) => setTopicDescription(e.target.value)}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							gap: 2,
							mt: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={(e) => {
								e.stopPropagation();
								setShowAddTopic(null);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							onClick={(e) => {
								e.stopPropagation();
								handleAddTopic();
							}}
							loading={topicLoader}
						>
							Create
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderAllTopics = (topics) => {
		if (topics?.length > 0) {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						bgcolor: "#f4f4f4",
						p: 2,
						maxHeight: "20vh",
						overflowY: "auto",
					}}
				>
					{topics?.map((topic) => (
						<Link
							to={`topic/${topic?.node_id}`}
							key={topic?.node_id}
							style={{
								textDecoration: "none",
								color: "#000",
							}}
						>
							<Box
								sx={{
									bgcolor: "#fff",
									p: 2,
									borderRadius: "8px",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 2,
									}}
								>
									<Typography
										sx={{
											fontWeight: 600,
											maxWidth: 500,
										}}
										noWrap
									>
										{topic?.node_name}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 2,
									}}
								>
									<Typography
										sx={{ color: "GrayText" }}
										level='title-sm'
									>
										{topic?.tasks?.length || 0} Task
									</Typography>
									<Typography
										sx={{ color: "GrayText" }}
										level='title-sm'
									>
										{topic?.points || 0} pts
									</Typography>
								</Box>
							</Box>
						</Link>
					))}
				</Box>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh", borderRadius: "8px", bgcolor: "#fff", p: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500, maxWidth: "65%", textAlign: "center", lineHeight: "1.5" }}
					>
						Currently there are no Topics to display. Create a New Topic to add tasks ,references.
					</Typography>
				</Box>
			);
		}
	};

	const renderCourseContent = () => {
		if (courseData?.data?.children?.length > 0) {
			return (
				<AccordionGroup
					sx={{
						p: 2,
						maxHeight: "48vh",
						overflowY: "auto",
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					{courseData?.data?.children?.map((module) => (
						<Accordion
							key={module?.node_id}
							sx={{
								bgcolor: "#f4f4f4",
								borderRadius: "8px",
								borderBottom: "none",
							}}
						>
							<AccordionSummary
								indicator={null} // We'll handle the indicator placement manually
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									width: "100%", // Fill parent width
									padding: 2, // Consistent padding
									"& .MuiAccordionSummary-content": {
										margin: 0, // Remove default margin
										width: "100%", // Ensure content spans full width
									},
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 2,
									}}
								>
									{editingModuleId === module?.node_id ? (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 2,
												width: "100%",
											}}
										>
											<Input
												value={editedModuleName}
												onChange={(e) => {
													e.stopPropagation();
													setEditedModuleName(e.target.value);
												}}
												sx={{ flex: 1 }}
												slotProps={{
													input: {
														maxLength: 64,
													},
												}}
												onClick={(e) => e.stopPropagation()}
											/>
											<Box sx={{ display: "flex", gap: 1 }}>
												<Button
													variant='soft'
													color='success'
													onClick={handleSaveModuleName}
												>
													<CheckIcon />
												</Button>
												<Button
													variant='soft'
													color='danger'
													onClick={(e) => {
														e.stopPropagation();
														setEditingModuleId(null);
													}}
												>
													<CloseIcon />
												</Button>
											</Box>
										</Box>
									) : (
										<>
											<Typography
												sx={{
													maxWidth: 500,
													minWidth: 50,
													flex: 1,
												}}
												noWrap
											>
												{module?.node_name}
											</Typography>
											<EditIcon
												sx={{
													height: "20px",
													width: "20px",
													cursor: "pointer",
												}}
												onClick={(e) => {
													e.stopPropagation();
													handleEditModule(module);
												}}
											/>
										</>
									)}
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 2,
									}}
								>
									<Typography>{module?.children?.length > 0 ? `${module?.children?.length} Topic${module?.children?.length > 1 ? "s" : ""}` : "No Topics"}</Typography>
									<Typography>{module?.points ? module?.points : 0} pts</Typography>
									{/* Custom Expand Icon */}
									<Button
										variant='outlined'
										sx={{ bgcolor: "white", color: "#3838f1", border: "1px solid rgba(0,0,0,0.1)", mx: 2 }}
										onClick={(e) => {
											e.stopPropagation();
											setShowAddTopic(module?.node_id);
										}}
									>
										Add Topic
									</Button>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											sx={{ padding: 0 }}
										/>
									</Box>
								</Box>
								{showAddTopic === module?.node_id && renderAddTopic(module?.node_id, module?.node_name)}
							</AccordionSummary>
							<AccordionDetails>{renderAllTopics(module?.children)}</AccordionDetails>
						</Accordion>
					))}
				</AccordionGroup>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500, maxWidth: "65%", textAlign: "center", lineHeight: "1.5" }}
					>
						Currently there are no Modules to display. Create a New Module to add Topics. You can group your Topics into Modules later.
					</Typography>
				</Box>
			);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "0 1rem",
				}}
			>
				<Box>
					<Typography
						level='h5'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Modules {courseData?.data?.children && courseData?.data?.children?.length > 0 ? `${courseData?.data?.children?.length}` : 0}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Button
						variant='primary'
						sx={{ backgroundColor: "#3838F1", fontWeight: "600", fontSize: "14px", borderRadius: "4px", color: "white" }}
						onClick={() => setShowAddModule(true)}
					>
						Add Module
					</Button>
				</Box>
			</Box>
			<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.30)", my: 1 }}></Box>
			<Box
				sx={{
					borderRadius: "sm",
				}}
			>
				{renderCourseContent()}
				{renderAddModule()}
			</Box>
		</Box>
	);
};

export default CourseContent;
