import { AddEventURLForBatchThunk } from "../../../Store/actions/batchEventAction";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input} from "@mui/joy";
import { updateEvent } from "../../../Store/slices/batchEventSlice";

const AddEventUrlModal = ({event, setShowEventRecAddModal}) => {
    const [recordingUrl, setRecordingUrl] = useState(event?.recording_url);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
    const [batchID, setBatchID]= useState(event?.batch_id)

	const handleAddEventURL = async (eventId,recordingUrl) => {
		try {
			setIsLoading(true);
			const response = await dispatch(AddEventURLForBatchThunk({ eventId, recordingUrl, batchID }));
			if (response?.payload.success) {
				dispatch(
					updateEvent({
						event_id:response?.payload?.data[0]?.event_id,
						name: response?.payload?.data[0]?.name,
						meeting_url: response?.payload?.data[0]?.meeting_url,
						instructor_name: response?.payload?.data[0]?.instructor_name,
						start_time: response?.payload?.data[0]?.start_time,
						end_time: response?.payload?.data[0]?.end_time,
						meeting_type: response?.payload?.data[0]?.meeting_type,
						batch_event_type: response?.payload?.data[0]?.batch_event_type,
						batch_id:response?.payload?.data[0]?.batch_id,
						event_image: response?.payload?.data[0]?.event_image,
						recording_url: response?.payload?.data[0]?.recording_url,
					})
				);
				setIsLoading(false);
				setShowEventRecAddModal("");
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

    return (
        <>
            <Box>
                <Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>URL</Typography>
                <Input
                    label='Topic Name'
                    sx={{ mb: 2 }}
                    slotProps={{
                        input: {
                            autoFocus: true,
                            maxLength: 200,
                        },
                    }}
                    type='url'
                    value={recordingUrl}
                    onChange={(e) => setRecordingUrl(e.target.value)}
                    placeholder='https://example.com/recording'
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 2,
                    mt: 1,
                }}
            >
                <Button
                    variant='outlined'
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowEventRecAddModal("");
                    }}
                    sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
                >
                    Cancel
                </Button>
                <Button
                    sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
                    color='primary'
                    onClick={() => {
                        handleAddEventURL(event.event_id, recordingUrl)
                    }}
                >
                    Save
                </Button>
            </Box>
        </>
    );
};

export default AddEventUrlModal;