import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dropdown, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import "../../../Common/Common.css";
import "../Topic.css";
import { Link } from "@mui/material";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import TaskModal from "./create-task/task-modal";
import CodeIcon from "@mui/icons-material/Code";
import Loader from "../../utils/Loader";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useLocation } from "react-router-dom";
import { deleteTaskThunk } from "../../../Store/actions/taskAction";
import TaskEditModal from "./edit-task/edit-task-modal";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { clearImportTasks, reorderTasks } from "../../../Store/slices/taskDetailsSlice";
import { getTopicInfoFromNode } from "../../../helpers/node";
import { fetchCourseDetailsThunk, updateTopicThunk } from "../../../Store/actions/courseAction";
import { ExternalTaskTypes } from "../../../Constants/task-types";
import { Coding_Task_Icon, Event_Task_Icon, MCQ_Task_Icon, Reading_Task_Icon, Videos_Task_Icon } from "../IconsPath";
import { UserRole } from "../../../Constants/user-role";
import ImportTask from "./import/import-task";
import { ReactComponent as TaskNote } from '../../../Assets/exclamatorymark.svg'

const Task = () => {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.user);
	const { taskByNodeId, taskNodeLoader, taskError } = useSelector((state) => state.task);
	const { courseData, allCourses } = useSelector((state) => state.course);

	const [open, setOpen] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [showDeleteModalForTask, setShowDeleteModalForTask] = useState("");
	const [showTaskEditModal, setShowTaskEditModal] = useState("");

	const location = useLocation();

	const node_id = location.pathname.split("/")[4];

	const handleDelete = async (task_id, node_id) => {
		const response = await dispatch(deleteTaskThunk({ task_id, node_id }));
		if (response?.payload?.data) {
			setShowDeleteModalForTask("");
			return;
		}
	};

	const renderDeleteModal = (task) => {
		return (
			<Modal
				open={showDeleteModalForTask === task?.task_id}
				onClose={() => setShowDeleteModalForTask("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to delete this task?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							color='primary'
							onClick={() => setShowDeleteModalForTask("")}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							loading={taskNodeLoader}
							onClick={() => {
								handleDelete(task?.task_id, node_id);
							}}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderImportModal = () => {
		return (
			<Modal
				open={dropdownOpen}
				onClose={() => {
					setDropdownOpen(false);
					dispatch(clearImportTasks());
				}}
			>
				<ModalDialog sx={{ width: 600 }}>
					<ModalClose />
					<Typography level='h4'>Import</Typography>
					<ImportTask
						node_id={node_id}
						currentCourseData={courseData}
						setDropdownOpen={setDropdownOpen}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderTaskEditModal = (task) => {
		return (
			<Modal
				open={showTaskEditModal === task?.task_id}
				onClose={() => setShowTaskEditModal("")}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose />
					<Typography level='h4'>Edit Task</Typography>
					<TaskEditModal
						setOpen={setShowTaskEditModal}
						task={task}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const handleSaveOrder = (items) => {
		const taskIds = items.map((task) => task.task_id);
		const topicNode = getTopicInfoFromNode(courseData.data, node_id);

		const taskIdOrder = topicNode.tasks;

		//check if the order is changed
		if (JSON.stringify(taskIds) === JSON.stringify(taskIdOrder)) {
			dispatch(showToast({ toastMessage: "Task order has not changed", toastType: ToastType.INFO }));
			return;
		}
		const topicData = {
			node_id: node_id,
			tasks: taskIds,
		};

		dispatch(updateTopicThunk(topicData));
		dispatch(fetchCourseDetailsThunk({ courseId: courseData.data.course_id }));
		dispatch(showToast({ toastMessage: "Task order saved successfully", toastType: ToastType.SUCCESS }));
		// if changed then dispatch the action
		// if not then show toast message
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = Array.from(taskByNodeId?.data);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		dispatch(reorderTasks(items));
		handleSaveOrder(items);
	};

	const taskTypeIcons = {
		VID: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='15'
				height='14'
				viewBox='0 0 14 14'
				fill='none'
			>
				<path
					d={Videos_Task_Icon}
					fill='#6E6E6F'
				/>
			</svg>
		),
		CODE: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='15'
				height='15'
				viewBox='0 0 13 13'
				fill='none'
			>
				<path
					d={Coding_Task_Icon}
					fill='#6E6E6F'
				/>
			</svg>
		),
		DOC: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='15'
				height='12'
				viewBox='0 0 12 12'
				fill='none'
			>
				<path
					d={Reading_Task_Icon}
					fill='#6E6E6F'
				/>
			</svg>
		),
		MCQ: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='15'
				height='14'
				viewBox='0 0 14 15'
				fill='none'
				className='mb-[2px]'
			>
				<path
					d={MCQ_Task_Icon}
					fill='#6E6E6F'
				/>
			</svg>
		),
		DEFAULT: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='12'
				viewBox='0 0 14 12'
				fill='none'
			>
				<path
					d={Event_Task_Icon}
					fill='#6E6E6F'
				/>
			</svg>
		),
	};

	const renderTaskCard = (task, index) => {
		return (
			task?.question && (
				<Draggable
					draggableId={`task-${index}-${task.task_id}`}
					index={index}
				>
					{(provided, snapshot) => (
						<Box
							key={task.task_id}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							ref={provided.innerRef}
							sx={{
								backgroundColor: snapshot.isDragging ? "#e0e0e0" : "#f4f4f4",
								borderRadius: "sm",
								display: "flex",
								alignItems: "center",
								p: ".8rem 1rem",
								cursor: "pointer",
								my: 2,
							}}
							onClick={() => {
								setShowTaskEditModal(task?.task_id);
							}}
						>
							<Box
								sx={{
									mr: 2,
									color: "green",
									fontWeight: "600",
								}}
							>
								{index + 1}.
							</Box>
							<MenuIcon />
							<Box
								sx={{
									display: "flex",
									width: "100%",
									mx: 2,
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 1,
									}}
								>
									{taskTypeIcons[task.task_type] || taskTypeIcons["DEFAULT"]}

									<Typography
										sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
										noWrap
									>
										{task?.task_title || task?.question}
									</Typography>
								</Box>

								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 2,
									}}
								>
									<Typography
										sx={{ fontSize: "12px" }}
										level='body-md'
									>
										{task.task_type ? "Manual" : "AI Generated"}
									</Typography>
									<Typography
										sx={{ fontSize: "12px" }}
										level='body-md'
									>
										{task.task_type ? (task.task_type === ExternalTaskTypes.DOC_UPLOAD || task.task_type === ExternalTaskTypes.DOC_LINK ? "EXTERNAL" : task.task_type) : "CODE"}
									</Typography>
									<Typography
										sx={{ fontSize: "12px" }}
										level='body-md'
									>
										{task?.points}pts
									</Typography>
									<Dropdown>
										<MenuButton
											sx={{
												backgroundColor: "transparent",
												border: "none",
												cursor: "pointer",
												padding: 0,
											}}
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											<MoreHorizIcon />
										</MenuButton>
										<Menu
											modifiers={[
												{
													name: "offset",
													options: {
														offset: ({ placement }) => {
															if (placement.includes("end")) {
																return [8, 20];
															}
															return [50, -20];
														},
													},
												},
											]}
										>
											<MenuItem
												onClick={(e) => {
													e.stopPropagation();
													setShowTaskEditModal(task?.task_id);
												}}
											>
												Edit
											</MenuItem>
											<MenuItem
												onClick={(e) => {
													e.stopPropagation();
													setShowDeleteModalForTask(task?.task_id);
												}}
											>
												<Typography color='danger'>Delete</Typography>
											</MenuItem>
										</Menu>
									</Dropdown>
								</Box>
							</Box>
						</Box>
					)}
				</Draggable>
			)
		);
	};

	const renderTaskList = () => {
		if (taskNodeLoader) {
			return (
				<Box>
					<Loader />
				</Box>
			);
		} else if (taskError) {
			dispatch(showToast({ message: taskError.message, type: ToastType.ERROR }));
		} else if (taskByNodeId?.data && taskByNodeId?.data.length > 0) {
			return (
				<Box sx={{ maxHeight: "30vh", overflowY: "auto", my: 2 }}>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId={`task-${node_id}`}>
							{(provided, snapshot) => (
								<Box
									sx={{
										px: 2,
									}}
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{taskByNodeId?.data.map((task, index) => {
										return (
											<>
												{renderTaskCard(task, index)}
												{showDeleteModalForTask === task?.task_id && renderDeleteModal(task)}
												{showTaskEditModal === task?.task_id && renderTaskEditModal(task)}
											</>
										);
									})}
									{provided.placeholder}
								</Box>
							)}
						</Droppable>
					</DragDropContext>
				</Box>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Add Tasks to the Topic for the students to do.
					</Typography>
				</Box>
			);
		}
	};

	const renderTaskModal = () => {
		return (
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose
						variant='plain'
						sx={{ m: 1 }}
					/>
					<Typography
						component='h2'
						id='modal-title'
						level='h4'
						textColor='inherit'
						fontWeight='lg'
						my={1}
					>
						Add Task manually
					</Typography>
					<TaskModal setOpen={setOpen} />
				</ModalDialog>
			</Modal>
		);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "0 1rem",
				}}
			>
				<Box>
					<Typography
						level='h5'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Tasks {taskByNodeId?.data && taskByNodeId?.data?.length > 0 ? `${taskByNodeId?.data?.length}` : 0}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					{user?.role !== UserRole.TEACHER && (
						<Button
							variant='outlined'
							sx={{ bgcolor: "#f5f5ff", color: "#3838f1", border: "none", fontSize: "14px", fontWeight: 600 }}
							onClick={() => setDropdownOpen(true)}
						>
							Import
						</Button>
					)}

					<Button
						variant='primary'
						sx={{ backgroundColor: "#3838F1", fontWeight: "600", fontSize: "14px", borderRadius: "4px", color: "white" }}
						onClick={() => setOpen(true)}
					>
						Add Task Manually
					</Button>
				</Box>
			</Box>
			<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.30)", my: 1 }}></Box>
			<Box
				sx={{
					backgroundColor: "#f4f4f4",
					borderRadius: "sm",
					p: ".8rem 1rem",
					m: 2,
					display: "flex",
					alignItems: "center",
					justifyContent: "center", // Center horizontally
					gap: 1,
				}}
			>
				<TaskNote />
				<Typography
					sx={{
						fontSize: "14px",
						color: "rgba(0, 0, 0, 0.7)",
						fontStyle: "italic",
						textAlign: "center", // Ensure text is centered
					}}
				>
					Order tasks in the desired sequence for students. Reordering won't impact progress or scores for completed tasks.
				</Typography>
			</Box>

			<Box
				sx={{
					borderRadius: "sm",
				}}
			>
				{renderTaskList()}
				{renderTaskModal()}
				{renderImportModal()}
			</Box>
		</Box>
	);
};

export default Task;
